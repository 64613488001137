import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import qs from "qs";

import http from "../http";

export default ({ match, location }) => {
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);
  const { activation_hash } = match.params;
  const { email } = qs.parse(location.search.substring(1));

  useEffect(() => {
    if (activation_hash) {
      http.post("/api/activate", { activation_hash }).then((res) => {
        setLoading(false);
        if (res.error) return setVerified(false);
        setVerified(true);
      });
    } else {
      setLoading(false);
    }
  }, [activation_hash]);

  const resend = () => {
    if (!email) return alert("Invalid email");

    http.post("/api/email/resend", { email }).then((res) => {
      setLoading(false);
      if (res.error) return setVerified(false);
      setVerified(true);
    });
  };

  if (loading) return null;
  return (
    <div className="login">
      <h2>
        {verified ? (
          <div>
            Thank you for verifying your email. You can now proceed to{" "}
            <Link to={`/login?email=${email}`}>log in</Link>.
          </div>
        ) : (
          <div>
            We were unable to verify your email.{" "}
            {email && (
              <>
                You can try sending a new email by{" "}
                <Link to="/login/success" onClick={resend}>
                  clicking here
                </Link>
              </>
            )}
          </div>
        )}
      </h2>
    </div>
  );
};
