import React, { useState } from "react";
import Button from "@material-ui/core/Button";

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default ({ label, onChange }) => {
  const [url, setUrl] = useState("");
  return url ? (
    <img src={url} stlye={{ width: "100%" }} alt="upload" />
  ) : (
    <Button variant="contained" component="label">
      <div>{label || "Upload Logo"}</div>
      <input
        type="file"
        hidden
        onChange={async (e) => {
          const file = e.target.files[0];
          const url = await toBase64(file).catch((e) => Error(e));
          setUrl(url);
          onChange(file, url);
        }}
      />
    </Button>
  );
};
