import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { FaEdit, FaTrash } from "react-icons/fa";
import Button from "@material-ui/core/Button";

import http, { getUser } from "../../components/http";
import EditOrg from "./EditOrg";
import AddMember from "./AddMember";

import "./style.css";
import FileUpload from "../../components/FileUpload";

class OrgPage extends React.Component {
  state = {
    loading: true,
    org: {},
    edit: false,
    members: [],
    invite: false,
    logo: "",
  };
  componentDidMount = () => {
    this.getOrg();
  };

  getOrg = () => {
    const { match } = this.props;
    http.get("/api/organizations", { id: match.params.id }).then((res) => {
      if (res.error) return alert(res.error);
      http
        .get("/api/organization/members", { id: match.params.id })
        .then((res1) => {
          if (res.error) return alert(res.error);
          this.setState({
            org: res.organizations[0],
            members: res1.members,
            loading: false,
          });
        });
    });
  };

  updateLogo = (file, url) => {
    if (!file) return;
    const { match } = this.props;
    const { org } = this.state;
    this.setState({ logo: file, org: { ...org, logo: url } });
    const fd = new FormData();
    fd.append("files", file, file.name);
    fd.append("body", JSON.stringify({ organization: match.params.id }));
    http.blob("/api/organizations/logo", "POST", fd).then((res) => {
      if (res.error) alert(res.error);
    });
  };

  render = () => {
    const { org, edit, members, invite, logo } = this.state;
    const user = getUser() || {};
    return (
      <div className="orgpage">
        <div className="container">
          {!edit && (
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1 style={{ marginRight: 10 }}>{org.name}</h1>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.setState({ edit: true });
                    }}
                  >
                    <FaEdit />
                    &nbsp;Edit
                  </Button>
                </div>
              </div>
              <div style={{ marginBottom: 10 }}>{org.description}</div>
              <div style={{ marginBottom: 5 }}>
                <b>Phone:</b> {org.phone}
              </div>
              <div>
                {org.street_address} {org.street_address_2}, {org.city},{" "}
                {org.state}, {org.zip}
              </div>
            </div>
          )}
          {edit && (
            <EditOrg
              org={org}
              onEdit={(org) => {
                this.setState({ edit: false, org });
              }}
              onCancel={() => {
                this.setState({ edit: false });
              }}
            />
          )}
          <div>
            <h2>Settings</h2>
            <div style={{ maxWidth: 200 }}>
              {!logo && org.logo && (
                <img
                  src={org.logo}
                  alt={org.name}
                  style={{ marginBottom: 10 }}
                />
              )}
              <FileUpload
                label={org.logo ? "Change logo" : "Upload logo"}
                onChange={this.updateLogo}
              />
            </div>
            {/* <Roles roles={organization.roles}/> */}
          </div>
          <h2>Members</h2>
          {!invite && (
            <div>
              {members.map((member) => {
                return (
                  <div
                    className="org-member"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 10,
                    }}
                  >
                    <Avatar alt={member.email}>
                      {member.email.substring(0, 1).toUpperCase()}
                    </Avatar>
                    <div style={{ marginLeft: 10 }}>
                      {member.email}
                      {member.pending ? " (Pending)" : ""}
                    </div>
                    {member.id !== user.id && (
                      <button
                        className="rm-member"
                        onClick={() => {
                          if (
                            window.confirm(
                              `Are you sure you want to remove ${member.email} from your organization?`
                            )
                          ) {
                            http
                              .del(`/api/organizations/members`, {
                                id: member.id,
                                organization: org.id,
                              })
                              .then((res) => {
                                if (res.error) return alert(res.error);
                                this.setState({
                                  members: members.filter(
                                    (m) => m.id !== member.id
                                  ),
                                });
                              });
                          }
                        }}
                      >
                        <FaTrash />
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          <AddMember
            org={org}
            invite={invite}
            setInvite={(invite) => {
              this.setState({ invite });
            }}
            receiveMembers={(members) => {
              this.setState({ members, invite: false });
            }}
          />
        </div>
      </div>
    );
  };
}

export default OrgPage;
