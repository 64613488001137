import React from "react";
import { Switch, Route, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

import http, { getUser } from "../http";
import Organizations from "./index";
import CreateOrg from "./CreateOrg";

import "./style.css";

class MyOrganizations extends React.Component {
  state = {
    loading: true,
    orgs: [],
    level: 1,
  };

  componentDidMount = () => {
    this.getOrgs();
  };

  getOrgs = () => {
    let user = getUser();
    if (user) {
      http.get("/api/organizations", { user: user.id }).then((res) => {
        if (res.error) return alert(res.error);
        this.setState({ orgs: res.organizations, loading: false });
      });
    }
  };

  render = () => {
    const { match, history } = this.props;
    const { loading, orgs } = this.state;
    if (loading) return null;

    return (
      <div
        style={{
          padding: 15,
          display: "flex",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <div className="container">
          <Switch>
            <Route
              exact
              path={match.path}
              render={() => {
                return orgs.length === 0 ? (
                  <div className="page-content">
                    <CreateOrg
                      onCreate={() => {
                        history.push("/packages");
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <div style={{ marginBottom: 20 }}>
                      <Link to={`${match.url}/create`}>
                        <Button variant="contained" color="primary">
                          Create new organization
                        </Button>
                      </Link>
                    </div>
                    <Organizations orgs={orgs} my />
                  </div>
                );
              }}
            />
            <Route
              path={`${match.path}/create`}
              render={() => {
                return (
                  <div className="page-content">
                    <CreateOrg
                      onCreate={() => {
                        history.push("/packages");
                      }}
                    />
                  </div>
                );
              }}
            />
          </Switch>
        </div>
      </div>
    );
  };
}

export default MyOrganizations;
