import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import qs from "qs";

import http from "../../components/http";
import { TextField } from "@material-ui/core";

const PackageFilter = ({ location, type, updateSearch }) => {
  const search = qs.parse(location.search.slice(1));
  const [currName, setCurrName] = useState(search.org_name || "");
  const [currLevel, setCurrLevel] = useState(search.level || "");
  const [currID, setCurrID] = useState(search.fID || "");

  const clearFilter = () => {
    setCurrName("");
    setCurrLevel("");
    setCurrID("");
  };

  return (
    <div
      className="input-row"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <FormControl style={{ minWidth: 200, padding: "1px" }}>
        <InputLabel>Level</InputLabel>
        <Select
          value={currLevel}
          onChange={(e) => {
            setCurrLevel(e.target.value);
          }}
        >
          {["1", "2", "3", "4", "5"].map((rType) => {
            return (
              <MenuItem value={rType} key={rType}>
                {rType}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <TextField
        onChange={(e) => {
          setCurrName(e.target.value);
        }}
        label="Name"
        value={currName}
      ></TextField>
      <TextField
        onChange={(e) => {
          setCurrID(e.target.value);
        }}
        label="Package ID"
        value={currID}
      ></TextField>
      <Button
        onClick={() => {
          updateSearch({
            level: "",
            org_name: "",
            fID: "",
          });
          clearFilter();
        }}
        variant="contained"
        color="primary"
      >
        CLEAR
      </Button>
      <Button
        onClick={() => {
          updateSearch({
            level: currLevel,
            org_name: currName,
            fID: currID,
          });
        }}
        variant="contained"
        color="primary"
      >
        SEARCH
      </Button>
    </div>
  );
};

export default PackageFilter;
