import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import can from "../../rbac";

export default ({ objective, submit, evaluate, canEdit }) => {
  let { evidence } = objective;
  const [observation, setObservation] = useState(evidence.observation || "");

  return (
    <div className="practice-controls">
      {can("EVIDENCE:CREATE") && (
        <div className="assessee-section">
          <TextField
            label="Description"
            value={observation}
            onChange={(e) => {
              setObservation(e.target.value);
            }}
            disabled={!canEdit}
            fullWidth
          />
          <div style={{ marginTop: 10, textAlign: "right" }}>
            {canEdit && (
              <Button
                onClick={() => {
                  submit({ observation });
                }}
                variant="contained"
                color="primary"
                disabled={observation === evidence.observation || !canEdit}
                style={{ marginRight: 10 }}
              >
                SAVE
              </Button>
            )}
          </div>
        </div>
      )}
      {can("EVALUATIONS:CREATE") && (
        <div className="assessor-section">
          <div>
            <h3>Description</h3>
            <div>{evidence.observation}</div>
          </div>
        </div>
      )}
    </div>
  );
};
