import React from "react";
import { Switch, Route } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import http from "../../http";
import can from "../../rbac";

import GapPie from "./GapPie";
import GapTable from "./GapTable";
import Capabilities from "./Capabilities";

import "./style.css";

class Dashboard extends React.Component {
  state = {
    practices: [],
  };

  componentDidMount = () => {
    const { assessment } = this.props;
    http
      .get("/api/practices", {
        assessment: assessment.id,
        levelTo: assessment.level,
        info: true,
        evidence: can("EVIDENCE:CREATE", assessment.roles),
        evaluations: can("EVALUATIONS:CREATE", assessment.roles),
      })
      .then((res) => {
        if (res.error) return alert(res.error);
        this.setState({ practices: res.practices });
      });
  };

  render = () => {
    let { match, assessment } = this.props;
    let { practices } = this.state;

    return (
      <div style={{ flex: 1, padding: 15, overflow: "auto" }}>
        <div
          style={{
            borderBottom: "1px solid lightgray",
            marginBottom: 15,
          }}
        >
          <h2>Dashboard</h2>
        </div>
        <Card>
          <CardContent>
            <div style={{ padding: 15 }}>
              <Switch>
                <Route
                  path={`${match.path}/domains`}
                  render={(routeProps) => {
                    return (
                      <GapPie
                        {...routeProps}
                        assessment={assessment}
                        practices={practices}
                      />
                    );
                  }}
                />
                <Route
                  path={`${match.path}/practices`}
                  render={(routeProps) => {
                    return (
                      <GapTable
                        {...routeProps}
                        assessment={assessment}
                        practices={practices}
                      />
                    );
                  }}
                />
                <Route
                  path={`${match.path}/capabilities`}
                  render={(routeProps) => {
                    return (
                      <Capabilities
                        {...routeProps}
                        assessment={assessment}
                        practices={practices}
                      />
                    );
                  }}
                />
              </Switch>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  };
}

export default Dashboard;
