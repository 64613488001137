import React from "react";
import { Switch, Route } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import http from "../http";
import Objective from "./Objective";
import Vendors from "./Vendors";

class Practice extends React.Component {
  state = {
    practice: {},
    tab: 0,
    notes: "",
  };

  componentDidMount = () => {
    this.getPractice();
  };

  componentDidUpdate = (prev) => {
    const { practice } = this.props.match.params;
    if (practice !== prev.match.params.practice) {
      this.getPractice();
    }
  };

  getPractice = () => {
    const { practice, assessment } = this.props.match.params;
    http
      .get("/api/practices", { id: practice, assessment, info: true })
      .then((res) => {
        if (res.error) return alert(res.error);
        this.setState({
          tab: 0,
          practice: res.practices[0],
          notes: res.practices[0].notes || "",
        });
      });
  };

  updatePractice = (practice) => {
    this.setState({ practice: { ...this.state.practice, ...practice } });
  };

  render = () => {
    const { match, assessment } = this.props;
    const { practice } = this.state;
    const { id, domain, statement, capability } = practice;

    if (!id) return null;

    return (
      <div style={{ flex: 1, padding: 15, overflow: "auto" }}>
        <Card style={{ marginBottom: 15 }}>
          <CardContent>
            <div
              style={{
                fontSize: 20,
                fontWeight: "bold",
                marginBottom: 10,
              }}
            >
              {domain} {id.slice(id.length - 5, id.length)}
            </div>
            <div style={{ marginBottom: 15 }}>{statement}</div>
            <div>
              <div className="practice-header">Capability</div>
              {capability}
            </div>
          </CardContent>
        </Card>
        <Switch>
          <Route
            path={`${match.path}/:objective`}
            render={(routeProps) => {
              return (
                <Objective
                  practice={practice}
                  assessment={assessment}
                  {...routeProps}
                />
              );
            }}
          />
          <Route
            exact
            path={`${match.path}`}
            render={() => {
              return <Vendors vendors={practice.vendors} />;
            }}
          />
        </Switch>
      </div>
    );
  };
}

export default Practice;
