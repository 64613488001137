import React, { useState, useEffect } from "react";
import qs from "qs";

import http from "../../components/http";
import Assessments from "../../components/Assessments/index";
import PackageFilter from "./PackageFilter";

const OpenPackages = ({ location, history }) => {
  const [loading, setLoading] = useState(true);
  const [packages, setPackages] = useState(false);

  const getPackages = () => {
    const search = qs.parse(location.search.slice(1));
    setLoading(true);
    http
      .get("/api/assessments", {
        open: true,
        id: search.fID,
        level: search.level,
        orgName: search.org_name,
      })
      .then((res) => {
        setLoading(false);
        if (res.error) return alert(res.error);
        setPackages(res.assessments);
      });
  };

  useEffect(() => {
    getPackages();
  }, [location.search]);

  const updateSearch = (newSearch) => {
    const oldSearch = qs.parse(location.search.slice(1));
    history.push({ search: qs.stringify({ ...oldSearch, ...newSearch }) });
  };

  const onUpdate = () => {
    this.getPackages();
  };

  if (loading) return null;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div className="container">
        <PackageFilter
          location={location}
          type="user"
          updateSearch={updateSearch}
        ></PackageFilter>
        {loading && <div>Loading...</div>}
        {!loading && (
          <Assessments
            assessments={packages}
            emptyState={{
              title: "No open packages",
              body: "There are currently no submitted packages for evaluation",
            }}
            history={history}
            open={true}
            onUpdate={onUpdate}
            page="packages"
          />
        )}
      </div>
    </div>
  );
};

export default OpenPackages;
