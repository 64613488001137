import React from "react";
import { Link } from "react-router-dom";

export default () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        background: "#272931",
        fontSize: 12,
      }}
    >
      <div
        className="container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "15px 0",
        }}
      >
        <div>Copyright© CyberDI LLC.</div>
        <div>
          <Link
            to="/terms"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            Terms of Service
          </Link>
          &nbsp;
          <Link
            to="/policy"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            Privacy Policy
          </Link>
        </div>
      </div>
    </div>
  );
};
