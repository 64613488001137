import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { FaChevronLeft } from "react-icons/fa";

import http from "../http";
import Pass from "./Pass";

export default ({ match, location, history, assessment: qAssessment }) => {
  const { assessment, level, domain, practice } = match.params;
  const [objectives, setObjectives] = useState([]);

  const getObjectives = () => {
    http.get("/api/objectives", { practice, assessment }).then((res) => {
      if (res.error) return alert(res.error);
      setObjectives(res.objectives);
    });
  };

  useEffect(() => {
    getObjectives();
  }, [location.pathname, practice, assessment]);

  return (
    <List component="nav" aria-label="main mailbox folders">
      <ListItem
        button
        onClick={() => {
          history.push(`/assessment/${assessment}/${level}/${domain}`);
        }}
      >
        <div className="list-text menu-header">
          <FaChevronLeft />
          &nbsp;&nbsp;
          <div>{practice}</div>
        </div>
      </ListItem>
      {objectives.map((objective) => {
        return (
          <NavLink
            key={objective.id}
            to={`${match.url}/${objective.id}`}
            className="practice-link"
            activeClassName="practice-active"
            onClick={getObjectives}
          >
            <ListItem button>
              <ListItemIcon>
                <Pass objective={objective} assessment={qAssessment} />
              </ListItemIcon>
              <div className="list-text">{objective.description}</div>
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );
};
