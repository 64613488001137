import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";

import http from "../../components/http";
import RoleSelector from "../../components/RoleSelector";

import Role from "./Role";

const Roles = ({ roles, type, setUpdate }) => {
  const [adding, setAdding] = useState(false);

  return (
    <div>
      <h2>Positions</h2>

      <div>
        {roles &&
          roles.map((role) => {
            return <Role role={role} key={role.id} setUpdate={setUpdate} />;
          })}
        {(!roles || roles.length === 0) && (
          <div>You currently have no roles</div>
        )}
      </div>
      {!adding && (
        <Button
          variant="contained"
          color="primary"
          style={{ marginBottom: 10 }}
          onClick={() => {
            setAdding(true);
          }}
        >
          Add position
        </Button>
      )}
      {adding && (
        <RoleSelector
          type={type}
          setAdding={setAdding}
          onChange={(value) => {
            http.put("/api/roles/assign", { role: value }).then((res) => {
              if (res.error) return alert(res.error);
              setAdding(false);
              setUpdate(true);
            });
          }}
        />
      )}
    </div>
  );
};

export default Roles;
