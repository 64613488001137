import jwt_decode from "jwt-decode";
import qs from "qs";
import http from "./http-common";

export const getJWT = () => {
  let token = localStorage.getItem("jwt");
  if (token) return `Bearer ${token}`;
  return null;
};

export const setJWT = (jwt) => {
  localStorage.setItem("jwt", jwt);
};

export const getUser = () => {
  try {
    return jwt_decode(localStorage.getItem("jwt"));
  } catch {
    return;
  }
};

export const logOut = () => {
  try {
    return localStorage.removeItem("jwt");
  } catch {
    return;
  }
};

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

export default {
  get: (url, params, options) => {
    let currHeaders = { ...headers };
    let jwt = getJWT();
    if (jwt) currHeaders.Authorization = jwt;

    return fetch(`${url}?${qs.stringify(params)}`, {
      headers: currHeaders,
      credentials: "include",
      ...(options || {}),
    }).then((response) => {
      switch (response.status) {
        case 403:
          return response;
        default:
          return response.json();
      }
    });
  },
  getBlob: (url, params, options) => {
    let currHeaders = { ...headers };
    let jwt = getJWT();
    if (jwt) currHeaders.Authorization = jwt;

    return fetch(`${url}?${qs.stringify(params)}`, {
      headers: currHeaders,
      credentials: "include",
      ...(options || {}),
    }).then((response) => {
      switch (response.status) {
        case 403:
          return response;
        default:
          return response.blob();
      }
    });
  },
  post: (url, data) => {
    let currHeaders = { ...headers };
    let jwt = getJWT();
    if (jwt) currHeaders.Authorization = jwt;

    return fetch(`${url}`, {
      headers: currHeaders,
      method: "POST",
      credentials: "include",
      body: JSON.stringify(data),
    }).then((response) => {
      switch (response.status) {
        case 403:
          return response;
        default:
          return response.json();
      }
    });
  },
  postProgress: (url, data, onUploadProgress) => {
    let jwt = getJWT();
    if (jwt) http.defaults.headers.post["Authorization"] = jwt;

    // assume that it's always form data for the purposes of this particular post method

    return http.post(`${url}`, data, {
      onUploadProgress,
    });
  },
  put: (url, data) => {
    let currHeaders = { ...headers };
    let jwt = getJWT();
    if (jwt) currHeaders.Authorization = jwt;

    return fetch(`${url}`, {
      headers: currHeaders,
      method: "PUT",
      credentials: "include",
      body: JSON.stringify(data),
    }).then((response) => {
      switch (response.status) {
        case 403:
          return response;
        default:
          return response.json();
      }
    });
  },
  del: (url, params) => {
    let currHeaders = { ...headers };
    let jwt = getJWT();
    if (jwt) currHeaders.Authorization = jwt;

    return fetch(`${url}?${qs.stringify(params)}`, {
      headers: currHeaders,
      method: "DELETE",
      credentials: "include",
    }).then((response) => {
      switch (response.status) {
        case 403:
          return response;
        default:
          return response.json();
      }
    });
  },
  blob: (url, method, data) => {
    let currHeaders = {};
    let jwt = getJWT();
    if (jwt) currHeaders.Authorization = jwt;

    return fetch(`${url}`, {
      headers: currHeaders,
      method: method || "POST",
      body: data,
    }).then((response) => {
      switch (response.status) {
        case 403:
          return response;
        default:
          return response.json();
      }
    });
  },
};
