import React from "react";

import Organization from "./Organization";

import "./style.css";

export default ({ orgs, my }) => {
  return (
    <div>
      {orgs.length === 0 && (
        <div className="page-content">
          <div>No open organizations</div>
        </div>
      )}
      {orgs.map((org) => {
        return <Organization org={org} my={my} />;
      })}
    </div>
  );
};
