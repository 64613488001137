import axios from "axios";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

export default axios.create({
  baseURL: "http://localhost:9000",
  headers: headers,
  withCredentials: true,
});
