import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import http from "../../components/http";

class AddMember extends React.Component {
  state = {
    email: "",
  };

  inviteMember = () => {
    const { org, receiveMembers } = this.props;
    http
      .post("/api/organization/members", {
        org: org.id,
        email: this.state.email,
      })
      .then((res) => {
        if (res.error) return alert(res.error);
        receiveMembers(res.members);
        alert("Your invite has been sent.");
      });
  };

  render = () => {
    const { invite, setInvite } = this.props;
    const { email } = this.state;

    return (
      <div>
        {!invite && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setInvite(true);
            }}
          >
            INVITE MEMBER
          </Button>
        )}
        {invite && (
          <div>
            <TextField
              value={email}
              onChange={(e) => {
                this.setState({ email: e.target.value });
              }}
              label="Email"
              style={{ width: 350 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={this.inviteMember}
              style={{ marginRight: 10 }}
            >
              INVITE
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setInvite(false);
              }}
            >
              CANCEL
            </Button>
          </div>
        )}
      </div>
    );
  };
}

export default AddMember;
