import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import http, { setJWT } from "../../components/http";

import "./style.css";

const validatePassword = (str) => {
  return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/.test(
    str
  );
};

export default ({ match }) => {
  const { activation_hash } = match.params;
  return (
    <div className="reset">
      <div className="container">
        <Formik
          initialValues={{
            password: "",
            password2: "",
          }}
          validate={(values) => {
            const errors = {};
            Object.keys(values).forEach((key) => {
              if (!values[key]) errors[key] = "Required";
            });
            if (!validatePassword(values.password))
              errors.password =
                "Password must be at least 6 characters and contain at least 1 lowercase, uppercase, and special character.";
            if (values.password !== values.password2)
              errors.password2 = "Password must match";
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            http
              .put(`/api/reset`, { ...values, activation_hash })
              .then((res) => {
                setSubmitting(false);
                if (res.error) return alert(res.error);
                if (res.jwt) {
                  setJWT(res.jwt);
                  window.location = "/packages";
                }
              });
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="input-row">
                <Field
                  type="password"
                  name="password"
                  label="New Password"
                  as={TextField}
                  className="login-input"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error"
                />
              </div>
              <div className="input-row">
                <Field
                  type="password"
                  name="password2"
                  label="Confirm New Password"
                  as={TextField}
                  className="login-input"
                />
                <ErrorMessage
                  name="password2"
                  component="div"
                  className="error"
                />
              </div>
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                fullWidth
              >
                Reset Password
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
