import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import qs from "qs";

import http from "../../components/http";
import { TextField } from "@material-ui/core";

const AssessorFilter = ({ location, type, updateSearch }) => {
  const search = qs.parse(location.search.slice(1));
  const [roles, setRoles] = useState([]);
  const [currRole, setCurrRole] = useState(search.role || "");
  const [currRateType, setCurrRateType] = useState(search.rate_type || "");
  const [currMinRate, setCurrMinRate] = useState(search.min_rate || "");
  const [currMaxRate, setCurrMaxRate] = useState(search.max_rate || "");

  useEffect(() => {
    http.get("/api/roles", { type }).then((res) => {
      if (res.error) return alert(res.error);
      setRoles(res.roles);
    });
  }, [type]);

  const clearFilter = () => {
    setCurrRateType("");
    setCurrMaxRate("");
    setCurrMinRate("");
    setCurrRole("");
  };

  return (
    <div
      className="input-row"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <FormControl style={{ minWidth: 200, padding: "1px" }}>
        <InputLabel>Role</InputLabel>
        <Select
          value={currRole}
          onChange={(e) => {
            setCurrRole(e.target.value);
          }}
        >
          {roles.map((cRole) => {
            return (
              <MenuItem key={cRole.id} value={cRole.id}>
                {cRole.title}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl style={{ minWidth: 200, padding: "1px" }}>
        <InputLabel>Rate Type</InputLabel>
        <Select
          value={currRateType}
          onChange={(e) => {
            setCurrRateType(e.target.value);
          }}
        >
          {["Fixed", "Hourly"].map((rType) => {
            return (
              <MenuItem value={rType} key={rType}>
                {rType}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <TextField
        onChange={(e) => {
          setCurrMinRate(e.target.value);
        }}
        label="Min Rate"
        value={currMinRate}
      ></TextField>
      <TextField
        onChange={(e) => {
          setCurrMaxRate(e.target.value);
        }}
        label="Max Rate"
        value={currMaxRate}
      ></TextField>
      <Button
        onClick={() => {
          updateSearch({
            min_rate: "",
            max_rate: "",
            rate_type: "",
            role: "",
          });
          clearFilter();
        }}
        variant="contained"
        color="primary"
      >
        CLEAR
      </Button>
      <Button
        onClick={() => {
          updateSearch({
            min_rate: currMinRate,
            max_rate: currMaxRate,
            rate_type: currRateType,
            role: currRole,
          });
        }}
        variant="contained"
        color="primary"
      >
        SEARCH
      </Button>
    </div>
  );
};

export default AssessorFilter;
