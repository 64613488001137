import React from "react";
import "./style.css";

export default () => {
  return (
    <div className="terms">
      <div className="container">
        <div className="WordSection1">
          <p className="MsoTitleCxSpLast" style={{ fontWeight: "bold" }}>
            Terms of Service
          </p>
          <p className="BlockTextJ">
            These Terms of Service (these “<b>Terms of Service</b>” or “
            <b>Terms</b>”) are entered into by and between Data Intelligence
            Technologies, Inc. (“<b>Data Intelligence</b>”), a Virginia
            corporation with an address of 8000 Towers Crescent Drive, Suite
            1350, Vienna, VA 22182, and the customer (the “<b>Customer</b>”)
            identified on our online registration form (each a “<b>Party</b>”
            and together the “<b>Parties</b>”).&nbsp; These Terms of Service
            govern the use of and access to the Assessment &amp; Accreditation
            App (a3) (the “<b>App</b>”) and the services and information that
            Data Intelligence may make available through the App (the “
            <b>Services</b>”, and together with the App, the “<b>Platform</b>”).{" "}
          </p>
          <p className="BlockTextJ">
            THE PLATFORM MAY ONLY BE USED IN THE UNITED STATES, BY U.S. CITIZENS
            AND ENTITIES DOMICILED IN THE U.S.{" "}
          </p>
          <p className="BlockTextJ">
            By clicking “I Accept” or otherwise indicating your acceptance of
            these Terms, you are agreeing that: (i) you have read and understand
            these Terms; (ii) you are 18 years or older and a U.S. citizen;
            (iii) if you are acting (or indicate you are acting) in a
            representative capacity, you have full authority to legally bind the
            person or entity on whose behalf you are acting, and, as applicable,
            such person is also a U.S. citizen or such entity is domiciled in
            the U.S.; and (iv) you, and such person or entity, will be bound by
            the terms and conditions set out in these Terms.&nbsp;{" "}
          </p>
          <p className="BlockTextJ">
            DO NOT USE THE PLATFORM UNTIL YOU HAVE READ THESE TERMS.&nbsp; IF
            YOU DO NOT AGREE TO THESE TERMS, DO NOT USE THE PLATFORM.
          </p>
          <h1 style={{ marginLeft: "0in", textIndent: "0in" }}>
            <b>
              1.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </b>
            <b>Defined Terms</b>.&nbsp; Capitalized terms have the meanings set
            out below.&nbsp; Other capitalized terms are defined in the context
            in which they are used:
          </h1>
          <h2 style={{ textIndent: "0in" }}>
            <b>1.1. </b>“<b>a3 Reports</b>”<b> </b>has the meaning set forth in
            Section 2.1 (Assessments; Reports).
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <b>1.2. </b>
            <b>&nbsp;“Anonymized Customer Data” </b>means Customer Data that has
            been filtered to remove data elements that would identify the
            Customer to a third party.{" "}
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <b>1.3. </b>“<b>Customer User</b>” means a user whom Customer
            authorizes to use the Platform on Customer’s behalf.&nbsp;{" "}
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <b>1.4. </b>“<b>Customer Data</b>”<b> </b>means<b> </b>data,
            information, and other materials that Customer uploads to or
            otherwise makes available on the Platform.&nbsp;{" "}
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <b>1.5. </b>“<b>Effective Date</b>” means the date on which Customer
            accepts these Terms in accordance with the introductory paragraphs,
            above.
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <b>1.6. </b>“<b>Feedback</b>” has the meaning set forth in Section
            3.6 (Feedback).
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <b>1.7. </b>“<b>Intellectual Property Rights</b>” means any rights
            (whether owned or licensed) existing now or in the future under
            patent law, copyright law, trademark law, data and database
            protection law, trade secret law, and any and all similar
            proprietary rights.&nbsp; The term “Intellectual Property Rights”
            means those rights as they exist as of the Effective Date, and all
            such rights subsequently acquired.
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <b>1.8. </b>“<b>Platform</b>” has the meaning set out in the
            introductory paragraphs, above.&nbsp; The term Platform expressly
            includes applicable User Documentation (if any).
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <b>1.9. </b>
            <b>“Term</b>” has the meaning set out in Section 10.1 (Term).
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <b>1.10. </b>“<b>Third Party CMMC Services</b>” has the meaning set
            out in Section 2.2 (Third Party CMMC Services).
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <b>1.11. </b>“<b>Third Party Service Providers</b>” has the meaning
            set out in Section 2.2 (Third Party CMMC Services).
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <b>1.12. </b>“<b>Usage Data</b>” has the meaning set forth in
            Section 3.5 (Usage Data).
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <b>1.13. </b>“<b>User</b> <b>Documentation</b>” means user
            instructions, help information, service descriptions, system
            requirements information, data sheets, technical support
            information, features information, and other similar materials that
            Data Intelligence may provide to Customer for use with respect to
            the Platform.
          </h2>
          <h1 style={{ marginLeft: "0in", textIndent: "0in" }}>
            <b>
              2.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </b>
            <b>Platform; Services</b>.{" "}
          </h1>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Ref68080210">
              <b>2.1. </b>
              <b>Assessment; Reports</b>.&nbsp; The Platform provides
              cloud-based collaborative environment and tools that are designed
              to assist Organizations Seeking Certification (OSC) in connection
              with obtaining Cybersecurity Maturity Model Certification (CMMC)
              accreditation.&nbsp; For example, using the Platform, Customer may
              perform cybersecurity gap analyses relevant to CMMC requirements,
              obtain assessment scores, and prepare risk mitigation and
              remediation plans (“<b>Cybersecurity</b> <b>Assessments</b>”), as
              well as generate reports based on the results of the Cybersecurity
              Assessments (“<b>a3</b> <b>Reports</b>”).
            </div>{" "}
            &nbsp;
            <div name="_Ref68091558">
              The Services are provided for informational purposes only, and
              nothing on the Platform is intended to constitute advice or
              information on which Customer should rely, or to substitute for
              professional advice.&nbsp; Customer should seek independent
              professional advice from organizations and persons accredited by
              the CMMC Accreditation Body (CMMC-AB).&nbsp;{" "}
            </div>
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Ref74135795">
              <b>2.2. </b>
              <b>Third Party CMMC Services</b>.&nbsp; Data Intelligence
              maintains a network of third party Registered Provider
              Organizations (RPO) and CMMC Third-Party Assessor Organizations
              (C3PAO), and provides Customer with access to this network.&nbsp;
              The Platform allows Customer to match with an RPO and/or a C3PAO
              to obtain the following third party services: (i) obtain
              consulting services from an RPO via its Registered Practitioner
              (RP) in connection with preparing a CMMC accreditation package;
              and (ii) submit the completed CMMC accreditation package to a
              C3PAO for a Certified CMMC Professional (CCP) or a Certified CMMC
              Assessors (CCA) to perform a CMMC accreditation assessment
              (collectively, the “<b>Third Party CMMC Services</b>”).&nbsp;
              Customer may also leave reviews of RPOs, C3PAOs, RPs, CCPs, and
              CCAs (collectively, “<b>Third Party Service Providers</b>”), and
              read reviews provided other users of the Platform. &nbsp;To the
              extent Customer elects to participate in the Third Party CMMC
              <b> </b>Services, Customer acknowledges that: (i) Third Party CMMC
              <b> </b>Services are paid services subject to fees assessed and
              charged by the applicable Third Party Service Providers; (ii)
              Customer will be required to enter into additional terms and
              conditions that govern the Third Party CMMC Services; and (iii) in
              order to facilitate Third Party CMMC<b> </b>Services, Data
              Intelligence will share Customer’s Cybersecurity Assessments, a3
              Reports, and other relevant Customer Data with the applicable
              Third Party Service Providers, as further set forth in Section{" "}
            </div>
            3.4 (Customer Data) of these Terms of Service. &nbsp;
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Ref72763004">
              <b>2.3. </b>
              <b>Relationships with Third Party Service Providers</b>.&nbsp;
              Third Party CMMC Services are provided solely by Third Party
              Service Providers, and not by Data Intelligence.&nbsp; Third Party
              Service Providers are independent third parties, and not agents or
              employees of, or otherwise related to, Data Intelligence in any
              manner.&nbsp; Data Intelligence does not endorse or recommend any
              particular Third Party Service Provider, nor guarantee the
              reliability or accuracy of any advice, statement, or opinion
              provided by a Third Party Service Provider. &nbsp;Customer is
              solely responsible for its relationship or interaction with Third
              Party Service Providers.&nbsp; Data Intelligence has no obligation
              to become involved in disputes between Customer and a Third Party
              Service Provider. &nbsp;If Customer has a dispute with a Third
              Party Service Provider, then Customer hereby releases Data
              Intelligence (and its subsidiaries, affiliates, officers,
              directors, agents, and employees) from claims, demands, and
              damages (actual and consequential) of every kind and nature, known
              and unknown, arising out of, or in any way connected with, such
              dispute.
            </div>
          </h2>
          <h1 style={{ marginLeft: "0in", textIndent: "0in" }}>
            <b>
              3.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </b>
            <b>Grants of Rights</b>.
          </h1>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Ref41408048">
              <b>3.1. </b>
              <b>Subscription License to the Platform</b>.&nbsp; Subject to the
              terms and conditions of these Terms
            </div>
            , Data Intelligence hereby grants to Customer, under Data
            Intelligence’s Intellectual Property Rights and during the Term, a
            nonexclusive, non-sublicensable, non-transferable, limited
            subscription license to access and use the Platform solely for the
            purposes permitted under these Terms.&nbsp;{" "}
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <b>3.2. </b>
            <b>Customer Users</b>.&nbsp; Subject to the terms and conditions of
            these Terms, Customer shall be entitled to extend the rights set out
            in Section 3.1 (Subscription License) to its Customer Users,
            provided that Customer shall (i) permit Customer Users to access and
            use the Platform solely for the purpose of Customer’s internal
            business operations, and (ii) ensure that all Customer Users comply
            with these Terms, including the limitations on the Platform use set
            forth in Section 5 (Limitations on Customer Use) and the
            confidentiality obligation set forth in Section 8 (Confidential
            Information).&nbsp; Customer shall be solely responsible for the
            acts and omissions of a Customer User that constitute a breach or
            other violation of these Terms as if such acts or omissions were
            performed directly by Customer.
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Ref70001201">
              <b>3.3. </b>
              <b>License to a3 Reports</b>.&nbsp; Subject to the terms and
              conditions of these Terms
            </div>
            , Data Intelligence hereby grants to Customer, under Data
            Intelligence’s Intellectual Property Rights and during and after the
            Term, a non-exclusive, perpetual, paid-up license to copy,
            distribute, modify, and otherwise use a3 Reports for purposes of
            obtaining Customer’s CMMC accreditation, and for no other
            purpose.&nbsp;
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Ref68091598" />
            <div name="_Ref74133409">
              <b>3.4.</b>
              <b>
                <span lang="NL">Customer Data</span>
              </b>
            </div>
            <span lang="NL">.</span>&nbsp;{" "}
          </h2>
          <h3 style={{ textIndent: "0in" }}>
            <b>
              <span>3.4.1 </span>
            </b>
            <b>License to Customer Data</b>. Subject to the terms and conditions
            of these Terms, Customer hereby grants to Data Intelligence, under
            Customer’s Intellectual Property Rights and during and after the
            Term, a nonexclusive, perpetual license to copy, modify, display,
            and use Customer Data to provide the Platform and to otherwise
            perform its obligations and exercise its rights under this
            Agreement, including to share Customer’s Cybersecurity Assessments,
            a3 Reports, and other relevant Customer Data with applicable Third
            Party Service Providers to enable the Third Party CMMC Services, as
            further set forth in Section 2.2 (Third Party CMMC Services).&nbsp;{" "}
          </h3>
          <h3 style={{ textIndent: "0in" }}>
            <b>
              <span>3.4.2 </span>
            </b>
            <b>Additional Services; Features</b>.&nbsp; Under certain
            circumstances, Data Intelligence may determine that notifying
            Customer of additional services or other features of its offerings
            or those of its Third Party Service Providers or others may be
            useful, and Customer agrees that Data Intelligence may use and share
            Customer Data for such purposes. &nbsp;
          </h3>
          <h3 style={{ textIndent: "0in" }}>
            <b>
              <span>3.4.3 </span>
            </b>
            <b>Privacy; Confidentiality</b>. To the extent that Customer Data
            used or shared by Data Intelligence for the purposes set out in this
            Section 3.4 (Customer Data) includes information that identifies an
            individual (“<b>Personal Information</b>”), then Data Intelligence’s
            collection, use, and disclosure of such Personal Information shall
            be governed by Data Intelligence’s Privacy Policy (currently
            available at: https://a3.cyberdataintelligence.com/privacy), as it
            may be amended from time to time.&nbsp; Similarly, to the extent
            that Customer Data used or shared by Data Intelligence for the
            purposes set out in this Section 3.4 (Customer Data) includes
            Confidential Information, then Data Intelligence’s collection, use,
            and disclosure of such information shall be governed by Section 8
            (Confidential Information).{" "}
          </h3>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Ref41413432">
              <b>3.5. </b>
              <b>
                <span lang="NL">Anonymized Customer Data;</span>
                <span lang="NL"> </span>Usage Data.&nbsp;{" "}
              </b>
              Subject to the terms and conditions of these Terms, Customer
              hereby grants to Data Intelligence, under Customer’s Intellectual
              Property Rights and during and after the Term, a nonexclusive,
              perpetual license to generate and use Anonymized Customer
              Data.&nbsp; Additionally, Data Intelligence will collect and
              analyze statistical data and other data related to Customer’s use
              of the Platform and its features (collectively, the “<b>Usage</b>{" "}
              <b>Data</b>”).&nbsp; Provided that the Anonymized Customer Data or
              Usage Data does not identify Customer or a Customer User, Customer
              agrees that, during and after the Term, Data Intelligence may use,
              share, and modify such Anonymized Customer Data or Usage Data for
              Data Intelligence’s business purposes, including the development
              and improvement of products and services and to notify Customer of
              additional services or other features of its offerings or those of
              its Third Party Service Providers or others.&nbsp; No compensation
              will be paid by Data Intelligence with respect to such use.
            </div>
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Ref41413450">
              <b>3.6. </b>
              <b>
                <span lang="NL">Feedback. &nbsp;</span>
              </b>
              The term “<b>Feedback</b>” means comments, criticisms, reports, or
              other feedback, whether in oral or written form, that Customer
              provides to Data Intelligence regarding the functions, features,
              and other characteristics of the Platform or Data Intelligence’s
              other products and services, including, without limitation, any
              errors, problems, or defects in, or suggestions for changes or
              improvements. &nbsp;Data Intelligence neither seeks nor requests
              Feedback, and these Terms place no obligations on Customer to
              provide Data Intelligence with Feedback.&nbsp; To the extent
              Customer chooses to provide Feedback to Data Intelligence,
              Customer hereby grants to Data Intelligence a nonexclusive,
              perpetual, paid-up license to copy, modify, and otherwise use such
              Feedback without restriction and without compensation.
            </div>
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Ref41413452">
              <b>3.7. </b>
              <b>No Implied Rights</b>. &nbsp;Any license granted under these
              Terms must be expressly provided herein, and there shall be no
              licenses or rights implied pursuant to these Terms, based on any
              course of conduct, or other construction or interpretation
              thereof.&nbsp; All rights and licenses not expressly granted
              herein are reserved.
            </div>
          </h2>
          <h1 style={{ marginLeft: "0in", textIndent: "0in" }}>
            <div name="_Ref41413387">
              <b>
                <span lang="PT">
                  4.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
              </b>
              <b>
                <span lang="NL">Ownership</span>
              </b>
            </div>
            <span lang="NL">.&nbsp; </span>Except as expressly provided in these
            Terms, as between the Parties, Data Intelligence is and shall remain
            the sole owner of all right, title, and interest (including all
            Intellectual Property Rights) in and to (i) the Platform and its
            components, (ii) Usage Data; (iii) Data Intelligence’s Confidential
            Information; and (iv) any improvements or modifications to any of
            the foregoing.&nbsp; Similarly, except as expressly provided in
            these Terms, as between the Parties, Customer is and shall remain
            the sole owner of all right, title, and interest in and to Customer
            Data and Customer’s Confidential Information.&nbsp; Customer shall
            have access to its Customer Data during the Term, and shall be
            entitled to export the Customer Data for its own purposes and
            uses.&nbsp; Upon expiration of these Terms (or earlier termination
            in accordance with its terms), as reasonably requested by Customer,
            Data Intelligence shall provide a copy of Customer Data available on
            the Platform in an industry-standard format.
          </h1>
          <h1 style={{ marginLeft: "0in", textIndent: "0in" }}>
            <div name="_Ref14107335">
              <b>
                5.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </b>
              <b>Limitations on Customer Use</b>.&nbsp; In using the Platform,
              Customer shall comply with applicable federal, state, local and
              foreign laws and regulations pertaining to the use of the
              Platform, including without limitation laws concerning privacy and
              Intellectual Property Rights, and shall not upload, transmit, or
              otherwise make available any classified or illegal
              information.&nbsp; Without limiting the generality of the
              foregoing, Customer shall not: (i) reverse engineer or otherwise
              attempt to gain unauthorized access to any portion of the Platform
              or any software in connection therewith; (ii) take actions that
              interfere with or disrupt the availability or operation of the
              Platform or any networks or servers on which it resides or
              operates; (iii) allow, by action or omission, anyone other than
              Customer to access or use the Platform by way of Customer’s access
              credentials; (iv) publish or upload to the Platform, or cause to
              be incorporated into or be available on the Platform, any content,
              information or data that (1) infringes upon the rights of any
              third party, (2) is unlawful, harassing, libelous, defamatory,
              abusive, threatening, harmful, vulgar, obscene or objectionable
              (as determined by Data Intelligence), or (3) is false, untrue,
              libelous or slanderous; (v) sell, license, or resell, or otherwise
              permit any third-party, access to or use of the Platform; (vi) use
              the Platform in any service bureau or time sharing arrangement for
              the benefit of a third-party or otherwise make the Platform
              available as an application service provider for a third-party’s
              use; (vii) use the Platform to facilitate, directly or indirectly,
              the purchase, sale, or distribution of any good or service; or
              (viii) take, or permit any person other than Data Intelligence to
              take, any action to cause the Platform to interact with any
              software program or database, or to otherwise make use of any
              application program interfaces (APIs) within the Platform other
              than as expressly authorized by Data Intelligence.
            </div>
          </h1>
          <h1 style={{ marginLeft: "0in", textIndent: "0in" }}>
            <b>
              6.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </b>
            <b>Data Intelligence Support Services</b>.&nbsp;{" "}
          </h1>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Ref41407591">
              <b>6.1. </b>
              <b>Troubleshooting Services</b>.&nbsp; Data Intelligence will use
              commercially reasonable efforts to provide Customer with basic
              troubleshooting support for the Platform in accordance with its
              standard practices (“<b>Troubleshooting Services</b>”).
            </div>
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <b>6.2. </b>
            <b>Additional Support and Training</b>.&nbsp; Other than the
            Troubleshooting Services, Data Intelligence shall not provide any
            other technical support services, such as customization, technical
            support, or product enhancements.&nbsp; Data Intelligence shall be
            available to offer reasonable additional support and training under
            separate, mutually agreeable terms and conditions.&nbsp; In
            addition, Data Intelligence is entitled to charge Customer, in
            accordance with Data Intelligence’s (or its service providers’)
            then-current pricing and other applicable policies, for provision of
            Troubleshooting Services required due to (i) problems, errors, or
            inquiries caused by Customer’s or a Customer User’s actions or
            omissions, Customer Data, or Customer systems, and (ii) excessive
            requests for Troubleshooting Services.
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Ref68102360">
              <b>6.3. </b>
              <b>Maintenance Services; Availability</b>.&nbsp; Data Intelligence
              shall, using commercially reasonable efforts and commercially
              reasonable response and resolution times, during business hours:
              (i) correct material errors reported by Customer; and (ii) make
              the Platform available to Customer without material interruption,
              subject to reasonable downtime for maintenance, error corrections,
              and matters beyond Data Intelligence’s control.
            </div>{" "}
          </h2>
          <h1 style={{ marginLeft: "0in", textIndent: "0in" }}>
            <div name="_Ref68095986">
              <b>
                7.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </b>
              <b>Fees and Payment</b>.
            </div>
          </h1>
          <h2 style={{ textIndent: "0in" }}>
            <b>7.1. </b>
            <b>
              <span lang="NL">Fees</span>
            </b>
            .&nbsp; The Third Party Service Provider selected by Customer in
            accordance with Section 2.3 (Relationships with Third Party Service
            Providers)<b> </b>will set the fees for the services such Third
            Party Service Provider provides to Customer (“<b>Fees</b>”).&nbsp;
            Data Intelligence will invoice Customer for all Fees. Data
            Intelligence is compensated for its Services by the applicable Third
            Party Service Provider, and Customer has no responsibility or
            obligation to pay Data Intelligence directly.&nbsp; All Fees are
            stated and payable in U.S. dollars, are nonrefundable, and shall be
            paid without right of setoff. &nbsp;
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <b>7.2. </b>
            <b>Payment; Invoices</b>.&nbsp; Customer agrees to maintain a valid
            credit card on file with Data Intelligence, using which Data
            Intelligence may charge for Fees.&nbsp; Data Intelligence may choose
            to bill the Customer through invoices, in which case, Data
            Intelligence will invoice Customer for Fees payable by
            Customer.&nbsp; Invoiced amounts not disputed in good faith by
            Customer in a writing delivered to Data Intelligence prior to the
            applicable due date shall be due and paid by Customer within thirty
            (30) days after the date on which Data Intelligence sends the
            invoice to Customer.&nbsp; Undisputed invoiced amounts not paid by
            the applicable due date shall thereafter accrue interest at a rate
            equal to the lesser of: (i) one and one-half percent (1.5%) per
            month; or (ii) the highest rate allowed by law.
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <b>7.3. </b>
            <b>
              <span lang="PT">Taxes</span>
            </b>
            <span lang="PT">.&nbsp; </span>All Fees payable hereunder are
            exclusive of, and, as between Customer, Data Intelligence, and the
            applicable Third Party Service Provider, Customer is responsible for
            paying, any taxes assessed or imposed in connection with these Terms
            or the provision of the Platform subscription to Customer (excluding
            taxes on Data Intelligence’s income or the income of the applicable
            Third Party Service Provider).&nbsp; Customer shall indemnify and
            hold Data Intelligence and the applicable Third Party Service
            Provider harmless from and against all claims and liabilities
            arising in connection with Customer’s failure to report or pay any
            such taxes.
          </h2>
          <h1 style={{ marginLeft: "0in", textIndent: "0in" }}>
            <div name="_Ref41413514">
              <b>
                8.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </b>
              <b>Confidential Information</b>.
            </div>
            &nbsp; This Section governs the protections for Confidential
            Information (as defined below) that one Party (the “
            <b>Disclosing Party</b>”) provides to the other Party (the “
            <b>Receiving Party</b>”) under these Terms.&nbsp;{" "}
          </h1>
          <h2 style={{ textIndent: "0in" }}>
            <b>8.1. </b>
            <b>Scope</b>.&nbsp; Subject to Section 8.2 (Exceptions), the term “
            <b>Confidential Information</b>” means information the Disclosing
            Party provides to, or that the Receiving Party accesses from, the
            Disclosing Party that meets one of the following two criteria: the
            information either (i) is identified by a “CONFIDENTIAL” legend or
            similar legend of the Disclosing Party, or (ii) is obtained under
            circumstances or is of a nature such that the Receiving Party knew
            or reasonably should have known that the information should be
            treated as confidential to the Disclosing Party.&nbsp; Subject to
            Section 8.2 (Exceptions), Confidential Information includes
            inventions, specifications, drawings, models, samples, reports,
            plans, financial information, work-in-progress, forecasts, computer
            programs or documentation, and all other technical, financial,
            intellectual or business information or data.&nbsp; The Receiving
            Party’s obligations of confidentiality and non-use shall not apply
            where the Receiving Party shows that the information (that would
            otherwise qualify as Confidential Information): (a) is, or after the
            Effective Date becomes, publicly available or part of the public
            domain through no wrongful act, fault or negligence on the part of
            the Receiving Party; (b) was in the possession of the Receiving
            Party at the time of the Receiving Party’s receipt of the
            Confidential Information, and was not otherwise subject to an
            existing agreement of confidentiality; (c) is received from a
            third-party without restriction and without breach of any obligation
            of confidentiality to the Disclosing Party; or (d) was independently
            developed by the Receiving Party without reliance on or reference to
            the Disclosing Party’s Confidential Information.
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Ref41411156">
              <b>8.2. </b>
              <b>Exceptions</b>.&nbsp; The Receiving Party shall not access,
              use, or disclose any of the Disclosing Party’s Confidential
              Information except as expressly permitted under these Terms.&nbsp;
              The Receiving Party shall protect the Disclosing Party’s
              Confidential Information with the same level of care it uses for
              its own Confidential Information of like nature; provided, that
              the Receiving Party shall at a minimum use reasonable care to
              protect the Disclosing Party’s Confidential Information.&nbsp; A
              Receiving Party shall be entitled to disclose the Disclosing
              Party’s Confidential Information to its employees and the
              employees of its affiliates (and, in the case of Data
              Intelligence, to service providers engaged by Data Intelligence
              and to Third Party Service Providers in accordance with Section
            </div>
            3.4 (Customer Data)) (collectively, “<b>Authorized Individuals</b>
            ”); provided, that each such Authorized Individual (i) has a need to
            know the Confidential Information for the purposes of these Terms
            and (ii) has been apprised of and agrees to the restrictions in
            these Terms.&nbsp; Each Party shall be responsible for any breach of
            confidentiality by its employees and (where applicable) its service
            providers as if the action and breach were conducted by such Party.
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <b>8.3. </b>
            <b>Compelled Disclosure</b>.&nbsp; In the event that the Receiving
            Party or anyone to whom the Receiving Party transmits Confidential
            Information pursuant to these Terms becomes legally required to
            disclose any such Confidential Information, the Receiving Party
            shall provide the Disclosing Party with prompt notice so that the
            Disclosing Party may seek a protective order or other appropriate
            remedy.&nbsp; The Receiving Party shall cooperate with the
            Disclosing Party, at the Disclosing Party’s expense, in seeking such
            protection.&nbsp; In the event that such protective order or other
            remedy is not obtained, the Receiving Party shall furnish only the
            minimum amount of Confidential Information that is legally required
            to be furnished to comply with the legal obligations of the
            Receiving Party as determined in the opinion of the Receiving
            Party’s counsel.
          </h2>
          <h1 style={{ marginLeft: "0in", textIndent: "0in" }}>
            <b>
              9.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </b>
            <b>Data Security</b>.&nbsp; Data Intelligence shall use commercially
            reasonable efforts to safeguard the security of Customer Data, and
            shall employ for this purpose information security controls
            consistent with accepted practices in the industry.
          </h1>
          <h1 style={{ marginLeft: "0in", textIndent: "0in" }}>
            <b>
              10.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;
              </span>
            </b>
            <b>Term and Termination</b>.
          </h1>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Ref41411141" />
            <div name="_Ref41398312">
              <b>10.1.</b>
              <b>
                <span lang="IT">Term</span>
              </b>
              .
            </div>
            &nbsp; These Terms shall take effect on the Effective Date and
            continue in force until terminated by either Party in accordance
            with Section 10.2 (Termination) (the “<b>Term</b>”).
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Ref41409933">
              <b>
                <span lang="IT">10.2. </span>
              </b>
              <b>
                <span lang="IT">Termination</span>
              </b>
            </div>
            <span lang="IT">.</span>
          </h2>
          <h3 style={{ textIndent: "0in" }}>
            <b>
              <span>10.2.1 </span>
            </b>
            <b>Termination for Convenience</b>.&nbsp; Customer may terminate
            these Terms for convenience by deleting its account.&nbsp; Data
            Intelligence may similarly terminate these Terms, and disable
            Customer’s account, on sixty (30) days’ notice.&nbsp;{" "}
          </h3>
          <h3 style={{ textIndent: "0in" }}>
            <b>
              <span>10.2.2 </span>
            </b>
            <b>Termination for Cause</b>.&nbsp; Either Party shall be entitled
            to terminate these Terms for material breach by the other, upon
            providing written notice to the other Party reasonably identifying
            the breach and a thirty (30) day period to cure, commencing on such
            Party’s deemed receipt of this notice (the “<b>Cure Period</b>
            ”).&nbsp; In the event the Party in breach does not cure the breach
            within the Cure Period to the reasonable satisfaction of the
            non-breaching Party, these Terms shall automatically terminate as of
            the last date of the Cure Period.&nbsp; Notwithstanding the
            foregoing, Data Intelligence may terminate or suspend Customer’s
            right to access or use the Platform immediately and without notice
            upon a material breach of these Terms by Customer or if Data
            Intelligence believes that any actions conducted through Customer’s
            account may reasonably be expected to expose Data Intelligence to
            civil and/or criminal damages.
          </h3>
          <h3 style={{ textIndent: "0in" }}>
            <b>
              <span>10.2.3 </span>
            </b>
            <b>Insolvency</b>.&nbsp; Either Party shall be entitled to terminate
            these Terms immediately upon written notice, if the other Party
            makes any assignment for the benefit of creditors, or a receiver,
            trustee in bankruptcy or similar officer is appointed to take charge
            of any or all of the other Party’s property, or the other Party
            seeks protection under any bankruptcy, receivership, trust deed,
            creditors arrangement, composition or comparable proceeding or such
            a proceeding is instituted against the other Party and is not
            dismissed within ninety (90) days, or the other Party becomes
            insolvent or, without a successor, dissolves, liquidates, or
            otherwise fails to operate in the ordinary course.
          </h3>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Ref462220603">
              <b>10.3. </b>
              <b>Effect of Termination or Expiration</b>.
            </div>
            &nbsp;{" "}
          </h2>
          <h3 style={{ textIndent: "0in" }}>
            <b>
              <span>10.3.1 </span>
            </b>
            <b>Cessation of Use of Platform; Certain Licenses</b>.&nbsp; Upon
            termination or expiration of these Terms, Customer’s rights to
            access and use the Platform shall immediately end, and Data
            Intelligence shall have no further obligation to provide the
            Platform or any Services to Customer.&nbsp;{" "}
          </h3>
          <h3 style={{ textIndent: "0in" }}>
            <b>
              <span>10.3.2 </span>
            </b>
            <b>Destruction of Confidential Information</b>.&nbsp; Unless
            otherwise permitted under these Terms, promptly after termination or
            expiration of these Terms, each Party shall (i) either (a) return
            the other Party’s tangible Confidential Information, or (b)
            permanently destroy or irretrievably delete such Confidential
            Information from storage media; provided, that the foregoing shall
            not require any deletion where doing so would require an
            extraordinary effort and such information is (1) archived in the
            ordinary course or (2) otherwise not readily accessible; and (ii)
            destroy all information, records and materials developed from the
            other Party’s Confidential Information (“
            <b>Derived Information</b>”).&nbsp; Upon request, a Party shall
            certify in writing to the destruction of such Confidential
            Information and Derived Information. &nbsp;
          </h3>
          <h3 style={{ textIndent: "0in" }}>
            <b>
              <span>10.3.3 </span>
            </b>
            <b>No Effect on Prior Obligations</b>.&nbsp; Any termination of
            these Terms shall not affect any obligation which accrued prior to
            such termination, and Customer shall remit to Data Intelligence all
            amounts due and payable (including, if terminated for Customer’s
            breach, any court costs, attorneys’ fees, and out-of-pocket expenses
            incurred by Data Intelligence in connection with Customer’s breach)
            within fifteen (15) days after the effective date of
            termination.&nbsp;{" "}
          </h3>
          <h3 style={{ textIndent: "0in" }}>
            <b>
              <span>10.3.4 </span>
            </b>
            <b>Survival</b>.&nbsp; In addition to any other right or obligation
            that by its nature is intended to survive any termination or
            expiration, the following Sections shall survive any termination or
            expiration of these Terms: Section 3.3 (License to a3 Reports);
            Section 3.4 (Customer Data); Section 3.5 (Anonymized Customer Data;
            Usage Data); Section 3.6 (Feedback); Section 3.7 (No Implied
            Rights); Section 4 (Ownership); Section 8 (Confidential
            Information); Section 10.3 (Effect of Termination or Expiration);
            Section 11.3 (Customer Representations and Warranties); Section 11.4
            (Disclaimers); Section 12 (Indemnification); Section 13 (Limitations
            on Remedies); and Section 14 (General).
          </h3>
          <h1 style={{ marginLeft: "0in", textIndent: "0in" }}>
            <div name="_Ref41411029">
              <b>
                11.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </b>
              <b>Representations and Warranties</b>.
            </div>
            &nbsp;{" "}
          </h1>
          <h2 style={{ textIndent: "0in" }}>
            <b>11.1. </b>
            <b>Mutual Representations and Warranties</b>.&nbsp; Each Party
            represents and warrants that: (i) it is duly organized, validly
            existing, and in good standing, and is qualified and/or licensed to
            do business in all jurisdictions to the extent necessary to carry
            out its obligations under these Terms; (ii) its execution, delivery,
            and performance of these Terms will not violate or constitute a
            default under any agreement by which such Party is bound, or under
            such Party’s organizational documents; (iii) it has the full right,
            power, and authority to enter into and be bound by the terms and
            conditions of these Terms and to perform its obligations under these
            Terms; and (iv) these Terms has been duly executed and delivered by
            such Party and constitutes a valid and binding agreement,
            enforceable in accordance with its terms. &nbsp;
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <b>11.2. </b>
            <b>Data Intelligence Representations and Warranties</b>.&nbsp; Data
            Intelligence represents and warrants that: (i) the Platform shall
            be, to Data Intelligence’s knowledge, free of viruses, worms, Trojan
            horses, or other similar malicious code; and (ii) it shall provide
            the Troubleshooting Services in a professional and workman-like
            manner, using qualified personnel and in accordance with accepted
            industry practices.{" "}
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Ref70512125">
              <b>11.3. </b>
              <b>Customer Representations and Warranties</b>.&nbsp; Customer
              represents and warrants that: (i) it shall comply with all
              applicable laws and regulations; (ii) it owns all right, title and
              interest in and to Customer Data, or it has otherwise secured
              sufficient rights in Customer Data necessary to permit the access,
              use, and processing of such information in accordance with these
              Terms; (iii) Customer is domiciled in the United States and each
              Customer User is a U.S. citizen; (iv) Customer Data does not
              include any classified or illegal information; and (v) to the
              extent Customer Data includes Personal Information, it has the
              authority and any necessary consents, as required under applicable
              law, to provide Data Intelligence with such Personal Information
              and to permit Data Intelligence’s uses of such Personal
              Information for the purposes contemplated by these Terms.
            </div>
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Ref2765228" />
            <div name="_Ref468368393" />
            <div name="_Toc462047449" />
            <div name="_Toc462048333">
              <b>11.4. </b>
              <b>Disclaimers</b>
            </div>
            <b>.&nbsp;</b>EXCEPT AS SPECIFICALLY PROVIDED IN THIS SECTION 11
            (REPRESENTATIONS AND WARRANTIES) AND EXCEPT FOR AGREEING TO USE
            REASONABLE EFFORTS TO REMEDY ERRORS WITHIN ITS REASONABLE CONTROL TO
            KEEP THE PLATFORM AVAILABLE IN ACCORDANCE WITH SECTION 6.3
            (MAINTENANCE SERVICES; AVAILABILITY), THE PLATFORM, AND ALL DATA
            INTELLIGENCE PERFORMANCE OBLIGATIONS ARE MADE AVAILABLE ON AN
            “AS-IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTY OF ANY KIND.&nbsp;
            AS IS THE CASE GENERALLY WITH SOFTWARE AND SERVICES MADE AVAILABLE
            VIA THE INTERNET, DATA INTELLIGENCE MAKES NO WARRANTY THAT ACCESS TO
            OR USE OF THE PLATFORM SHALL BE UNINTERRUPTED, ERROR-FREE, OR
            WITHOUT INACCURACIES.&nbsp; TO THE MAXIMUM EXTENT PERMITTED BY
            APPLICABLE LAW, DATA INTELLIGENCE DISCLAIMS ALL WARRANTIES AND
            CONDITIONS, EITHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, ANY
            IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, FITNESS FOR
            A PARTICULAR PURPOSE OR TITLE.&nbsp;{" "}
          </h2>
          <h1 style={{ marginLeft: "0in", textIndent: "0in" }}>
            <div name="_Ref41413591">
              <b>
                12.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </b>
              <b>Indemnification</b>.
            </div>
          </h1>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Ref14174555" />
            <div name="_Toc462047451" />
            <div name="_Toc462048335">
              <b>12.1. </b>
              <b>Indemnification by Customer</b>
            </div>
            <b>.&nbsp; </b>Customer agrees to defend Data Intelligence from and
            against any demand, suit, action or other claim by a third-party
            that is related to or arises from Customer’s use of the Platform,
            Customer Data, and any negligence, willful conduct, or breach of
            these Terms (each a “<b>Customer-Related Claim</b>”), and to
            indemnify Data Intelligence for settlement amounts or damages,
            liabilities, costs, and expenses (including reasonable attorneys’
            fees) arising out of any such Customer-Related Claim.
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Toc462047453" />
            <div name="_Toc462048337">
              <b>12.2.</b>
              <b>Conditions</b>
            </div>
            <b>.&nbsp; </b>The indemnifying Party’s obligations hereunder are
            conditioned on (i) the Party seeking indemnification providing
            prompt written notice thereof and reasonable cooperation,
            information, and assistance in connection therewith (at the
            indemnifying Party’s expense) and (ii) the indemnifying Party having
            sole control and authority to defend, settle, or compromise such
            claim.&nbsp; The indemnified Party may participate in the defense at
            its sole cost and expense.&nbsp; The indemnifying Party will not
            enter into any settlement that adversely affects the indemnified
            Party’s rights or interest without its prior written approval, not
            to be unreasonably withheld.&nbsp; The indemnifying Party shall not
            be responsible for any settlement it does not approve in writing.
          </h2>
          <h1 style={{ marginLeft: "0in", textIndent: "0in" }}>
            <div name="_Ref14108486">
              <b>
                13.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </b>
              <b>Limitations on Remedies</b>.
            </div>
          </h1>
          <h2 style={{ textIndent: "0in" }}>
            <b>13.1. </b>
            <b>Consequential Damages Waiver</b>.&nbsp; EXCEPT IN CONNECTION WITH
            A CUSTOMER-RELATED CLAIM AS SET FORTH IN SECTION 12.1
            (INDEMNIFICATION BY CUSTOMER), IN NO EVENT SHALL EITHER PARTY BE
            LIABLE TO THE OTHER PARTY FOR ANY INDIRECT, PUNITIVE, SPECIAL, OR
            CONSEQUENTIAL DAMAGES OF ANY KIND OR NATURE WHATSOEVER, INCLUDING
            WITHOUT LIMITATION, LOSS OF PROFITS; LOSS OF DATA; LOSS OR
            INTERRUPTION OF USE; DIMINUTION OF VALUE; COST TO PROCURE SUBSTITUTE
            TECHNOLOGIES, GOODS, OR SERVICES; OR OTHER ECONOMIC LOSS, EVEN IF
            DATA INTELLIGENCE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES.
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Toc356736458">
              <b>13.2. </b>
              <b>Limitation of Liability</b>
            </div>
            .&nbsp; EXCEPT IN CONNECTION WITH A CUSTOMER-RELATED CLAIM AS SET
            FORTH IN SECTION 12.1 (INDEMNIFICATION BY CUSTOMER), IN NO EVENT
            SHALL THE LIABILITY OF EITHER PARTY, IN THE AGGREGATE, EXCEED THE
            TOTAL AMOUNT OF FEES ACTUALLY RECEIVED BY DATA INTELLIGENCE DURING
            THE TWELVE (12) MONTH PERIOD PRECEDING THE ALLEGED BREACH, LESS ANY
            AMOUNTS PREVIOUSLY PAID BY DATA INTELLIGENCE FOR PRIOR
            LIABILITY.&nbsp; THESE LIMITATIONS ARE INDEPENDENT FROM ALL OTHER
            PROVISIONS OF THESE TERMS AND SHALL APPLY NOTWITHSTANDING THE
            FAILURE OF ANY REMEDY PROVIDED HEREIN.
          </h2>
          <h1 style={{ marginLeft: "0in", textIndent: "0in" }}>
            <div name="_Ref41474514">
              <b>
                14.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </b>
              <b>General</b>.
            </div>
          </h1>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Toc40785042" />
            <div name="_Toc9751542">
              <b>14.1.</b>
              <b>Governing Law; Exclusive Jurisdiction</b>.
            </div>
            &nbsp; These Terms shall be governed and construed in all respects
            in accordance with the laws of the Commonwealth of Virginia without
            regard to any conflict of laws principles.&nbsp; The exclusive venue
            and jurisdiction for any action or proceeding arising out of these
            Terms shall be the state and federal courts located in the judicial
            district that includes Vienna, Virginia.&nbsp; The Parties accept
            the personal jurisdiction of such courts.{" "}
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <b>14.2. </b>
            <b>Attorneys’ Fees</b>.&nbsp; In the event that any action, suit, or
            other legal or administrative proceeding is instituted or commenced
            by either Party against the other Party arising out of or related to
            these Terms, the prevailing Party shall be entitled to recover its
            actual attorneys’ <div name="El2lrO" />
            <div name="El2mrrO">fees </div>and court costs from the
            non-prevailing Party.
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Ref205961306" />
            <div name="_Toc40785043">
              <b>14.3.</b>
              <b>Independent Contractors</b>.
            </div>
            &nbsp; The Parties are independent contractors,{" "}
            <span>
              and no agency, partnership, joint venture, or employer-employee
              relationship is intended or created by these Terms
            </span>
            .&nbsp; Neither Party shall have the power to obligate or bind the
            other Party.{" "}
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Toc40785045">
              <b>14.4. </b>
              <b>Publicity</b>
            </div>
            .&nbsp; Data Intelligence shall be entitled to use Customer’s name,
            logo, or other marks for use and reference in Data Intelligence’s
            promotional and marketing materials, including to identify Customer
            as a customer of Data Intelligence on its website.
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Toc40785046">
              <b>14.5. </b>
              <b>Further</b>
            </div>
            <b>Assurances</b>.&nbsp; Each Party shall cooperate with the other
            Party, both during and after the Term, in the procurement and
            maintenance of each Party’s Intellectual Property Rights and to
            execute, when requested, any other documents deemed necessary to
            carry out the purpose of these Terms.
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Toc40785047">
              <b>14.6. </b>
              <b>Force </b>
            </div>
            <b>Majeure</b>.&nbsp; Neither Party shall be liable hereunder by
            reason of any failure or delay in the performance of its obligations
            on account of strikes, shortages, riots, insurrection, fires, flood,
            storm, explosions, acts of God, terrorism, war, governmental action,
            earthquakes, pandemics or other public health emergencies, or any
            other cause that is beyond the reasonable control of such Party.
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Toc40785048">
              <b>14.7. </b>
              <b>Freedom</b>
            </div>
            <b>of Action</b>.&nbsp; Customer agrees that Data Intelligence is
            free and without restriction to engage third parties to provide any
            goods and services subject to Section 8 (Confidential Information).{" "}
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Toc9751549" />
            <div name="_Toc40785049">
              <b>14.8.</b>
              <b>Severability</b>
            </div>
            <b>; Waiver; Headings</b>.&nbsp; If any provision of these Terms is
            determined to be unenforceable or invalid by applicable law or court
            decision, such unenforceability or invalidity shall not render these
            Terms unenforceable or invalid as a whole and, in such event, such
            provision shall be changed and interpreted so as to best accomplish
            the objectives of such provision within the limits of applicable law
            or court decision.&nbsp; The failure of either Party to require the
            other Party’s performance of any obligation herein shall not affect
            the full right to require such performance at any time thereafter;
            nor shall a Party’s waiver of a breach of any obligation herein be
            taken or held to be a waiver of the obligation itself or of any past
            or subsequent breaches of the same obligation.&nbsp; Headings used
            in these Terms are for reference purposes only and in no way define,
            limit, construe, or describe the scope or extent of such section or
            in any way affect these Terms.
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Toc40785050" />
            <div name="_Ref205621609" />
            <div name="_Ref328989532" />
            <div name="_Ref146024127">
              <b>14.9. </b>
              <b>Assignment</b>
            </div>
            .&nbsp; These Terms of Service, and Customer’s rights, duties, and
            obligations hereunder may not be assigned or transferred without
            Data Intelligence’s prior written consent, not to be unreasonably
            withheld or delayed; provided, however, that Customer may assign
            without consent in connection with a merger, acquisition, or sale of
            all or substantially all of its assets or stock, and further
            provided that Customer gives the Data Intelligence at least sixty
            (60) days’ prior written notice of any such merger, acquisition, or
            sale.&nbsp; Data Intelligence may assign these Terms of Service or
            its rights, duties, and obligations hereunder without consent.&nbsp;
            The Parties’ rights, duties, and obligations shall bind and inure to
            the benefit of their respective successors and permitted assigns.
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Toc40785052">
              <b>14.10. </b>
              <b>Notice</b>
            </div>
            .&nbsp; Any notices required or permitted herein shall be given to
            Data Intelligence at the address specified{" "}
            <span>above, and to Customer </span>at such address the Customer
            specifies on the registration form.&nbsp; Such notice shall be
            deemed given: (i) upon personal delivery; (ii) if sent by facsimile
            or electronic mail, upon confirmation of receipt; or (iii) if sent
            by certified or registered mail, postage prepaid, five (5) days
            after the date of mailing.
          </h2>
          <h2 style={{ textIndent: "0in" }}>
            <div name="_Toc40785053">
              <b>14.11. </b>
              <b>Remedies</b>
            </div>
            .&nbsp; Except as expressly stated herein, no remedy conferred by
            any of the provisions of these Terms is intended to be exclusive of
            any other remedy, and each remedy is cumulative and in addition to
            every other remedy available to a Party hereunder or otherwise
            existing at law, in equity, by statute, or otherwise.&nbsp; The
            election of any one or more remedies by either Party shall not
            constitute a waiver of the right to pursue any other available
            remedies.
          </h2>
        </div>
      </div>
    </div>
  );
};
