import React from "react";
import { NavLink, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

import { getUser, logOut } from "../http";
import logo from "./logo.png";

import "./style.css";

export default () => {
  const user = getUser();

  return (
    <nav
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: 15,
        background: "#3b3e49",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Link
          to="/"
          style={{
            color: "white",
            fontWeight: "bold",
            textDecoration: "none",
            fontSize: 25,
            marginRight: 20,
          }}
        >
          <img src={logo} alt="logo" style={{ maxWidth: 40 }} />
        </Link>
        {user && (
          <>
            <NavLink
              to="/marketplace"
              className="nav-link"
              activeClassName="nav-link-active"
            >
              Marketplace
            </NavLink>
            <NavLink
              to="/assessments"
              className="nav-link"
              activeClassName="nav-link-active"
            >
              Assessments
            </NavLink>
            <NavLink
              to="/packages"
              className="nav-link"
              activeClassName="nav-link-active"
            >
              Packages
            </NavLink>
            <NavLink
              to="/organizations"
              className="nav-link"
              activeClassName="nav-link-active"
            >
              Organizations
            </NavLink>
          </>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {user ? (
          <>
            <Link
              to={`/profile/${user.id}`}
              className="nav-user"
            >{`${user.first_name} ${user.last_name}`}</Link>
            <Button
              onClick={() => {
                logOut();
                window.location = "/";
              }}
              style={{ color: "white", fontWeight: "bold" }}
            >
              Log Out
            </Button>
          </>
        ) : (
          <Link to="/" className="nav-link">
            Login
          </Link>
        )}
      </div>
    </nav>
  );
};
