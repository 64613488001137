import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

import http, { getUser } from "../http";

class StartAssessment extends React.Component {
  state = {
    orgs: [],
    org: "",
    level: 1,
    projectName: "",
    projectNumber: "",
  };

  componentDidMount = () => {
    const user = getUser();
    if (user) {
      http.get("/api/organizations", { user: user.id }).then((res) => {
        if (res.error) return alert(res.error);
        this.setState({ orgs: res.organizations, loading: false });
      });
    }
  };

  startAssessment = () => {
    const { onStart } = this.props;
    const { org, level, projectName, projectNumber } = this.state;
    http
      .post("/api/assessments", {
        organization: org,
        level,
        projectName,
        projectNumber,
      })
      .then((res) => {
        if (res.error) return alert(res.error);
        onStart(res.assessment.id);
      });
  };

  render = () => {
    const { orgs, org, level, projectName, projectNumber } = this.state;
    return (
      <div>
        <h1>New Package</h1>
        <div className="input-row">
          <FormControl style={{ minWidth: 200 }}>
            <InputLabel>Organization*</InputLabel>
            <Select
              value={org}
              onChange={(e) => {
                this.setState({ org: e.target.value });
              }}
            >
              {orgs.map((cOrg) => {
                return <MenuItem value={cOrg.id}>{cOrg.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </div>
        <div className="input-row">
          <FormControl style={{ minWidth: 200 }}>
            <InputLabel>Level*</InputLabel>
            <Select
              value={level}
              onChange={(e) => {
                this.setState({ level: e.target.value });
              }}
            >
              <MenuItem value={1}>Level 1</MenuItem>
              <MenuItem value={2}>Level 2</MenuItem>
              <MenuItem disabled value={3}>
                Level 3 - Coming Soon
              </MenuItem>
              {/* <MenuItem value={4}>Level 4</MenuItem> */}
              {/* <MenuItem value={5}>Level 5</MenuItem> */}
            </Select>
          </FormControl>
        </div>
        <div className="input-row">
          <TextField
            label="Project Name*"
            value={projectName}
            onChange={(e) => {
              this.setState({ projectName: e.target.value });
            }}
          />
        </div>
        <div className="input-row">
          <TextField
            label="Project Number"
            value={projectNumber}
            onChange={(e) => {
              this.setState({ projectNumber: e.target.value });
            }}
          />
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={this.startAssessment}
            style={{ marginRight: 15 }}
          >
            Start
          </Button>
          <Link to={`/packages`}>
            <Button variant="contained" color="secondary">
              Cancel
            </Button>
          </Link>
        </div>
      </div>
    );
  };
}

export default StartAssessment;
