import React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

import can from "../rbac";

const objectivePass = ({ evidence, evaluation }, roles) => {
  if (can("EVIDENCE:CREATE", roles)) {
    return evidence ? evidence.complete : false;
  }
  if (can("EVALUATIONS:CREATE", roles)) {
    if (evaluation.pass === "pass" || evaluation.pass === "inherited")
      return true;
    if (evaluation.pass === "fail") return false;
  }
  return null;
};

export const practicePass = ({ objectives }, roles) => {
  if (!objectives) return false;

  let pass = true;
  if (can("EVIDENCE:CREATE", roles)) {
    objectives.forEach((objective) => {
      if (!objectivePass({ evidence: objective }, roles)) pass = null;
    });
  }

  if (can("EVALUATIONS:CREATE", roles)) {
    objectives.forEach((objective) => {
      if (objectivePass({ evaluation: objective }, roles) === null) pass = null;
      if (objectivePass({ evaluation: objective }, roles) === false)
        pass = false;
    });
  }

  return pass;
};

const domainPass = (practices, roles) => {
  if (!practices) return null;
  let pass = true;
  if (can("EVIDENCE:CREATE", roles)) {
    practices.forEach((practice) => {
      if (!practicePass(practice, roles)) pass = null;
    });
  }

  if (can("EVALUATIONS:CREATE", roles)) {
    practices.forEach((practice) => {
      if (practicePass(practice, roles) === null) pass = null;
      if (practicePass(practice, roles) === false) pass = false;
    });
  }
  return pass;
};

export default ({ pass, objective, practice, domain, assessment }) => {
  if (assessment) {
    const roles = assessment.roles || [];
    if (objective) pass = objectivePass(objective, roles);
    if (practice) pass = practicePass(practice, roles);
    if (domain) pass = domainPass(domain, roles);
  }

  switch (pass) {
    case "pass":
    case true:
      return <CheckCircleIcon style={{ color: "#26d797" }} />;
    case "fail":
    case false:
      return <CancelIcon style={{ color: "#ff4444" }} />;
    case "inherited":
      return <CheckCircleIcon style={{ color: "#26d75c" }} />;
    default:
      return <RemoveCircleIcon style={{ color: "gray" }} />;
  }
};
