import React from "react";

export default () => {
  return (
    <div className="terms">
      <div className="container"></div>
      <div className="WordSection1">
        <p className="MsoTitleCxSpLast" style={{ fontWeight: "bold" }}>
          Privacy Policy
        </p>
        <p className="BodyTextJ">
          This Privacy Policy (this "<b>Privacy Policy</b>") governs how we
          collect, use, and treat personal information that you provide to
          <b></b>Data Intelligence Technologies, Inc. ("<b>Data Intelligence</b>
          ," "<b>we</b>," "<b>our</b>", or "<b>us</b>"): (i) through our
          Assessment &amp; Accreditation App (a3) (the “<b>App</b>”); (ii) while
          using the services and information available via the App or otherwise
          (collectively, the "<b>Services</b>") (the Services, together with our
          App, are referred to as the "<b>Platform</b>"); or (iii) other means,
          such as by phone. This Privacy Policy establishes our rights and
          obligations with respect to your personal information, and lets you
          know your associated rights.
        </p>
        <h1>
          <span name="_Toc517799123">
            <span>
              1.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Your Consent to this Privacy Policy
          </span>
          <span style={{ fontWeight: "normal" }}>.</span>
        </h1>
        <p className="BodyTextJ">
          Our Privacy Policy applies to anyone that uses or visits our Platform
          (collectively, "<b>you</b>"), including Customers (as defined in our
          Terms of Service). You indicate your consent to this Privacy Policy in
          different ways, depending on your relationship with us, as set forth
          below. Please read this Privacy Policy carefully. Once you consent to
          this Privacy Policy and its terms, it creates legal obligations on you
          and on us.{" "}
        </p>
        <h2>
          <b>
            <span>
              1.1
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Consent by Guest User</b>.{" "}
        </h2>
        <p className="BodyTextJ">
          We have structured our Platform so that you are able to view certain
          limited information about our Services without registering an account.
          By accessing and using our Platform as a visitor, you are
          acknowledging that you have read and understood this Privacy Policy
          and agreeing to be legally bound by it.
        </p>
        <h2>
          <span name="_Ref516157918">
            <b>
              <span>
                1.2
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>Consent by Registered Users</b>
          </span>
          .{" "}
        </h2>
        <p className="BodyTextJ">
          Certain of our Services are available only to users who have
          registered with us to create user accounts (each, a "
          <b>Registered User</b>"). By clicking “I Accept” or otherwise
          indicating your acceptance of this Privacy Policy, you are
          acknowledging that you have read and understood this Privacy Policy
          and agreeing to be legally bound by it.
        </p>
        <h2>
          <b>
            <span>
              1.3
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Consent by User Acting in a Representative Capacity</b>.{" "}
        </h2>
        <p className="BodyTextJ">
          If you are agreeing to our Privacy Policy on behalf of a company or
          other legal entity ("<b>Your Organization</b>"), then: (i) you
          represent and warrant that you have authority to act on behalf of, and
          to bind, Your Organization; and (ii) for all purposes in this Privacy
          Policy, the term "you" means Your Organization on whose behalf you are
          acting.
        </p>
        <h2>
          <b>
            <span>
              1.4
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Right to Withdraw Consent</b>.{" "}
        </h2>
        <p className="BodyTextJ">
          You have the right to withdraw your consent at any time, in accordance
          with Section 12 (Your Choices Regarding Your Personal Information;
          Opting Out).
        </p>
        <h1>
          <span name="_Ref29216256">
            <span>
              2.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            The Information We Collect
          </span>
          <span style={{ fontWeight: "normal" }}>.</span>
        </h1>
        <p className="BodyTextJ">
          The information that we collect about you falls into certain
          categories as further described in this Section 2 (The Information We
          Collect). This information consists of the following:{" "}
        </p>
        <h2>
          <span name="_Ref506313858">
            <b>
              <span>
                2.1
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>Personal Information</b>
          </span>
          .{" "}
        </h2>
        <p className="BodyTextJ" style={{ marginBottom: "12.0pt" }}>
          We collect certain information that identifies you as an individual
          (collectively, "<b>Personal Information</b>"). The Personal
          Information we collect may include the following categories of
          information:
        </p>
        <p className="BulletsCxSpFirst">
          <span className="DocID">
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </span>
          <span className="DocID">
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              Your name, email address, and password;
            </span>
          </span>
        </p>
        <p className="BulletsCxSpMiddle">
          <span className="DocID">
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </span>
          <span className="DocID">
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              Your other contact information, including a mailing address, phone
              number, and fax number;
            </span>
          </span>
        </p>
        <p className="BulletsCxSpMiddle">
          <span className="DocID">
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </span>
          <span className="DocID">
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              Payment information, including credit or debit card, bank account,
              or other financial account information;
            </span>
          </span>
        </p>
        <p className="BulletsCxSpMiddle">
          <span className="DocID">
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </span>
          <span className="DocID">
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              Geolocation information;
            </span>
          </span>
        </p>
        <p className="BulletsCxSpMiddle">
          <span className="DocID">
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </span>
          <span className="DocID">
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              Any information or feedback you provide in communications with us
              through our App or via social media, email, or otherwise; and
            </span>
          </span>
        </p>
        <p className="BulletsCxSpLast">
          <span className="DocID">
            <span style={{ fontFamily: "Symbol" }}>
              ·
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </span>
          <span className="DocID">
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              The company, agency, or other organization that you represent.
            </span>
          </span>
        </p>
        <h2 style={{ marginTop: "6.0pt" }}>
          <span name="_Ref506313842">
            <b>
              <span>
                2.2
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>Online Activity Information</b>.{" "}
          </span>
        </h2>
        <p className="BodyTextJ">
          We also collect technical and device-related information through the
          Platform (collectively, the "<b>Online Activity Information</b>").
          Online Activity Information is typically collected automatically by
          technical means and, subject to Section 2.4 (Treatment of Combined
          Information), for purposes of our Platform, may consist of the
          following:{" "}
        </p>
        <p className="BulletsCxSpFirst">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Device identifiers, such as cookies;
        </p>
        <p className="BulletsCxSpMiddle">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Device information, such as hardware and software settings;{" "}
        </p>
        <p className="BulletsCxSpMiddle">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          IP addresses and log information, such as your device's name, the type
          and version of your web browser, and referrer addresses that can
          function to identify a user device; and
        </p>
        <p className="BulletsCxSpLast">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Tracking information that we, or a third party, may collect.
        </p>
        <p className="BodyTextJ">
          To the extent that statutes, regulations, and any other laws that
          apply to the Platform (collectively, "<b>Applicable</b> <b>Law</b>")
          establish that Online Activity Information constitutes Personal
          Information, and such Applicable Law applies to information we collect
          from you, then we will treat the relevant Online Activity Information
          as Personal Information. In addition, sometimes Online Activity
          Information can be associated with your Personal Information, in which
          case we will treat it as Personal Information. Otherwise we consider,
          and treat, Online Activity Information as Anonymous Information.
        </p>
        <h2>
          <span name="_Ref506313808">
            <b>
              <span>
                2.3
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span style={{ letterSpacing: ".05pt" }}>Anonymous</span>
              <span style={{ letterSpacing: "1.15pt" }}> </span>Information
            </b>
            .{" "}
          </span>
        </h2>
        <p className="BodyTextJ">
          Our Platform also collects, processes, and/or uses information that
          does not identify you or your devices, including Personal Information
          that has been made anonymous by: (i) removing identifying fields and
          aggregating the information with other information so that individual
          subjects of the information cannot be re-identified, or (ii)
          anonymizing the information with techniques that remove or modify the
          identifying data so as to prevent re-identification of the anonymized
          information (collectively, "<b>Anonymous Information</b>").
          Information that meets these criteria might include, for example,
          demographic information, statistical information (e.g., page views and
          hit counts), and general tracking information. We use Anonymous
          Information for our business operations. Since Anonymous Information
          does not include Personal Information, Anonymous Information is
          outside the scope of this Privacy Policy.
        </p>
        <h2>
          <span name="_Ref69989857">
            <b>
              <span>
                2.4
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span style={{ letterSpacing: ".05pt" }}>
                Treatment of Combined Information
              </span>
            </b>
          </span>
          .{" "}
        </h2>
        <p className="BodyTextJ">
          To enable us to better understand the characteristics of our users
          and/or to provide Services tailored to your needs, we may link your
          Personal Information with Online Activity Information associated with
          you or other Anonymous Information.{" "}
          <span name="_Ref517791076">
            If we combine Online Activity Information or Anonymous Information
            with your Personal Information, then we will treat the resulting
            combination as Personal Information.
          </span>{" "}
        </p>
        <h1>
          <span name="_Toc517799125">
            <span>
              3.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            How We Collect Information
          </span>
          <span style={{ fontWeight: "normal" }}>.</span>
        </h1>
        <p className="BodyTextJ">
          We collect the above information through the following means and
          technologies:
        </p>
        <h2>
          <b>
            <span>
              3.1
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Registration</b>.{" "}
        </h2>
        <p className="BodyTextJ">
          To register an account with us, you will need to provide us with
          certain information, including Personal Information. For example, when
          you register an account with us, you must provide a variety of
          Personal Information to us in connection with creating a user account.
        </p>
        <h2>
          <b>
            <span>
              3.2
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Customer Data</b>.{" "}
        </h2>
        <p className="BodyTextJ">
          As further set out in our Terms of Service, our Platform gives you the
          ability to upload, or otherwise make available on the Platform,
          certain data, information, and other materials that you wish to
          provide in connection with the Services (“<b>Customer</b> <b>Data</b>
          ”). To the extent that you provide us with Customer Data that includes
          Personal Information, then our collection, use, and disclosure of such
          Personal Information will be governed by this Privacy Policy.
        </p>
        <h2>
          <b>
            <span>
              3.3
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Payments</b>.{" "}
        </h2>
        <p className="BodyTextJ">
          When you make payments or perform other transactions on our Platform,
          you must use the payment processing methods that we have facilitated
          via our Platform. We will collect your transaction information from
          the third party payment processor(s) that we have authorized to
          collect such information from you on our behalf. For more information
          on our privacy practices with respect to our service providers, please
          refer to Section 6.1 (Our Service Providers). The payment information
          that our payment processor will collect from you during this process
          may include your bank account or other financial account information,
          credit card number, the expiration date of your payment card,
          transaction amount, date of transaction, and payment method. The
          payment processor may also collect certain Personal Information from
          you, such as your name and billing address. Please note that different
          payment processing methods may require you to provide different
          categories of information.
        </p>
        <h2>
          <b>
            <span>
              3.4
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Contacting Data Intelligence</b>.{" "}
        </h2>
        <p className="BodyTextJ">
          You can contact us with questions or comments using the methods
          available on our Platform or the information provided in Section 19
          (Contact Us). In order to communicate with us, you must provide
          certain Personal Information.
        </p>
        <h2>
          <span name="_Ref516210372">
            <b>
              <span>
                3.5
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>Submitted Content</b>
          </span>
          .{" "}
        </h2>
        <p className="BodyTextJ">
          We may give you the ability through the Platform to engage with us and
          others, and this may include opportunities for you to provide
          comments, reviews, recommendations, and other input via the Platform
          (collectively, "<b>Submitted Content</b>"). For example, any
          information you post on your public user profile constitutes Submitted
          Content. Please understand that, if you include Personal Information
          in Submitted Content, others will be able to read, collect, republish,
          and otherwise freely use such information. We are not responsible for
          Personal Information you decide to include in Submitted Content, and
          we will not take down, remove, or edit Submitted Content, except as
          required by Applicable Law. If you include in your Submitted Content
          any Personal Information relating to others, you represent that you
          have full permission and authority to do so.
        </p>
        <h2>
          <b>
            <span>
              3.6
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Information Provided to Third Parties</b>.{" "}
        </h2>
        <p className="BodyTextJ">
          As explained further in Section 6.1 (Our Service Providers), we may
          engage third parties to perform certain services for us or on our
          behalf. The forms on our Platform may be managed by such third
          parties, and we may obtain from the applicable service provider the
          information that you provide using such forms.
        </p>
        <h2>
          <b>
            <span>
              3.7
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Newsletters</b>.{" "}
        </h2>
        <p className="BodyTextJ">
          You can provide us with Personal Information so that we can send you
          newsletters about Data Intelligence, our Services, and information
          about issues and special offers that we believe may be of interest to
          you. We will use your Personal Information to provide you with this
          content. You can opt-out of receiving this content by following the
          instructions set out in Section 12 (Your Choices Regarding Your
          Personal Information; Opting Out).
        </p>
        <h2>
          <span name="_Ref516205065">
            <b>
              <span>
                3.8
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>Questionnaires and Surveys</b>
          </span>
          .{" "}
        </h2>
        <p className="BodyTextJ">
          Our Platform may allow you to participate in surveys and
          questionnaires, which we may post on the Platform from time to time or
          otherwise send to you via the contact information that you provide to
          us. You are free to choose whether you participate in a survey or
          questionnaire. We may ask that, in addition to providing your
          responses, you also provide Personal Information. In these instances,
          we use any information that you provide to us for the purposes for
          which you submit the information. Survey and questionnaire information
          may be used for the purposes of monitoring or improving the use and
          appeal of our Platform or for other business purposes.
        </p>
        <h2>
          <b>
            <span>
              3.9
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Geolocation Information</b>.
        </h2>
        <p className="BodyTextJ">
          Our Platform may determine your location (your "
          <b>Geolocation Information</b>") using your device or browser features
          and/or through the information provided by a third party, such as
          Google or Facebook. You may prevent providing your Geolocation
          Information by disabling the GPS and other applicable features in your
          device or browser settings. To the extent your Geolocation Information
          is combined with any Personal Information (to the extent that
          Geolocation Information itself does not constitute Personal
          Information under Applicable Law), we will treat it as Personal
          Information. We may use your Geolocation Information to send
          communications to you, and for other purposes set out in this Privacy
          Policy.
        </p>
        <h2>
          <b>
            <span>
              3.10
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Beacons and Tags</b>.
        </h2>
        <p className="BodyTextJ">
          The Platform may use certain data collection technologies that rely on
          (i) beacons; (ii) pixel tags and object hyperlinking tags, and (iii)
          other means to link an object to an Internet address, a remote
          software application, a remote database, or other remote means of
          receiving or processing information. We may use these technologies to
          tell us what parts of our Platform have been visited or to measure the
          effectiveness of searches that users perform on our Platform. These
          technologies also enable us to send email messages in a format users
          can efficiently read, to learn whether these email messages have been
          opened, and to help ensure, for example, that our messages are of
          interest to our users. These technologies provide us with Anonymous
          Information, including Online Activity Information and, in certain
          instances, Personal Information.
        </p>
        <h2>
          <b>
            <span>
              3.11
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Device Identifiers; Logs; IP Addresses</b>.{" "}
        </h2>
        <p className="BodyTextJ">
          To determine whether your device is supported by our Platform, we may
          collect certain information about your device and network, including
          your IP address, your operating system and browser, your device model,
          information about your use of the Platform, as well as the presence of
          any software that our Platform may require to operate with your
          device, or other third party software or mobile apps on your device.
          We automatically receive and record this information in log files, and
          this is generally Online Activity Information.{" "}
        </p>
        <h2>
          <b>
            <span>
              3.12
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Click-Throughs</b>.{" "}
        </h2>
        <p className="BodyTextJ">
          We may send email messages or display links that use a "click-through
          URL" linked to our Platform or to another resource. When you click one
          of these URLs, you pass through our web server before arriving at the
          destination website page or other resource. Click-throughs may use and
          collect Anonymous Information, including Online Activity Information.
          We may track this click-through data to help determine interest in
          particular topics and measure the effectiveness of our user
          communications.{" "}
        </p>
        <h2>
          <span name="_Ref516218102">
            <b>
              <span>
                3.13
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
            </b>
            <b>Cookies</b>
          </span>
          .
        </h2>
        <p className="BodyTextJ">
          A cookie is a small amount of data that is sent to your browser from a
          website's computers and stored on your computer's hard drive. Cookies
          can be used to provide you with a tailored user experience and to make
          it easier for you to use a website upon a future visit. We may include
          cookies on our Platform and use them to recognize you when you return
          to our Platform. You may choose not to accept cookies; however, you
          may need to enable cookies if you wish to access certain personalized
          features of our Services.{" "}
        </p>
        <h2>
          <b>
            <span>
              3.14
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Other Technologies and Data Sources</b>.{" "}
        </h2>
        <p className="BodyTextJ">
          We may augment the Personal Information that you provide to us with
          data from other third-party sources, such as privately held marketing
          or analytics databases. If we use these data sources we will treat any
          information we collect or generate in such manner in full accordance
          with this Privacy Policy.{" "}
        </p>
        <h2>
          <b>
            <span>
              3.15
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Collection of Personal Information Offline</b>.{" "}
        </h2>
        <p className="BodyTextJ">
          We may ask you to provide us with Personal Information via offline
          interactions. Personal Information we collect in this manner may
          include, for example, your name, organization, address, email address,
          phone number, and other identifying information. If you provide
          information to us at our request, then we will strive to provide you
          with either verbal and/or written notice of this Privacy Policy upon
          your request for a copy of the Privacy Policy. We treat any
          information that you provide to us as Personal Information under this
          Privacy Policy.{" "}
        </p>
        <h1>
          <span name="_Toc517799126">
            <span>
              4.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            How We Use this Information
          </span>
          <span style={{ fontWeight: "normal" }}>.</span>
        </h1>
        <p className="BodyTextJ" style={{ marginBottom: "12.0pt" }}>
          We use the information we collect or process, including Personal
          Information, Online Activity Information, and Anonymous Information,
          as permitted under Applicable Law and consistent with the terms of
          this Privacy Policy. More specifically, we use the information we
          collect for the following purposes:
        </p>
        <p className="BulletsCxSpFirst">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          To provide you with the Services you request, including to access or
          use our Platform, complete payments, create and manage Registered User
          accounts, and communicate with you regarding our Services;
        </p>
        <p className="BulletsCxSpMiddle">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          To allow you to post Submitted Content;
        </p>
        <p className="BulletsCxSpMiddle">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          To respond to your inquiries and provide customer support;
        </p>
        <p className="BulletsCxSpMiddle">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          To customize and enhance your use of our Platform, such as by
          personalizing your experience with us;
        </p>
        <p className="BulletsCxSpMiddle">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          To provide communications that might interest you, including marketing
          messages and to inform you of products and services that we think
          would be appealing to you, whether the products or services are
          provided by us or by others, and whether the products or services are
          available via our Platform or via a third party;
        </p>
        <p className="BulletsCxSpMiddle">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          To verify your identity and to securely process your payments or other
          requests for Services;
        </p>
        <p className="BulletsCxSpMiddle">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          To perform analytics, quality control, market research, and to
          determine the effectiveness of our Platform, promotional campaigns, to
          improve our Platform and develop new products and services;
        </p>
        <p className="BulletsCxSpMiddle">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          To perform internal administration, auditing, operation, and
          troubleshooting for our Platform; and
        </p>
        <p className="BulletsCxSpLast">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          To share the information with third parties as permitted in this
          Privacy Policy.
        </p>
        <p className="BodyTextJ">
          In addition to the uses specifically identified above, there may be
          instances where you request information and Services from us that are
          not described in this Privacy Policy. In our discretion, we can use
          information that you submit in order to provide such information and
          Services.
        </p>
        <h1>
          <span>
            5.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Other Essential or Internal Uses of Information
          <span style={{ fontWeight: "normal" }}>.</span>
        </h1>
        <p className="BodyTextJ" style={{ marginBottom: "12.0pt" }}>
          We may also use the information that we collect as we believe to be
          necessary or appropriate for certain essential purposes, including:{" "}
        </p>
        <p className="BulletsCxSpFirst">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          To comply with applicable law and legal process;
        </p>
        <p className="BulletsCxSpMiddle">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          To respond to requests from public and government authorities,
          including public and government authorities;
        </p>
        <p className="BulletsCxSpMiddle">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          To detect, prevent, or investigate potential security incidents or
          fraud;
        </p>
        <p className="BulletsCxSpMiddle">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          To facilitate the functionality of our Platform;
        </p>
        <p className="BulletsCxSpMiddle">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          To enforce our terms and conditions;
        </p>
        <p className="BulletsCxSpMiddle">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          To protect our operations or those of our affiliates;
        </p>
        <p className="BulletsCxSpMiddle">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          To protect our rights, privacy, safety or property, security and/or
          that of our affiliates, you or others; and
        </p>
        <p className="BulletsCxSpLast">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          To allow us to pursue available remedies or limit the damages that we
          may sustain.
        </p>
        <h1>
          <span name="_Toc517799127">
            <span>
              6.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            How We Share this Information
          </span>
          <span style={{ fontWeight: "normal" }}>.</span>
        </h1>
        <p className="BodyTextJ">
          We value your privacy, and we share the information we collect only in
          the manner set out below.
        </p>
        <h2>
          <span name="_Ref69990619">
            <b>
              <span>
                6.1
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>Our Service Providers</b>
          </span>
          .{" "}
        </h2>
        <p className="BodyTextJ">
          We engage third parties to perform functions on our behalf, and these
          may include maintaining the Platform, processing payments, collecting
          information, responding to and sending email or other messages, and
          other functions useful to our business. In this capacity, we may
          provide service providers with Personal Information, Online Activity
          Information, and Anonymous Information (as applicable). The following
          are examples:
        </p>
        <p className="BulletsCxSpFirst">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Our Platform may include links to third party websites offering
          services that augment those Services offered on our Platform.{" "}
        </p>
        <p className="BulletsCxSpMiddle">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          We may use service providers to: (i) process payment transactions;
          (ii) provide customer service; (iii) process and distribute email and
          newsletters; (iv) manage marketing and similar activities; and (v)
          provide you with access to various portals and platforms. These
          service providers generally require access to your Personal
          Information in order to perform these services.
        </p>
        <p className="BulletsCxSpMiddle">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          We may use analytics service providers to assist us in understanding
          and using Online Activity Information and other information that we
          collect via the Platform.{" "}
        </p>
        <p className="BulletsCxSpMiddle">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          We may use service providers to anonymize and aggregate Personal
          Information in order to generate Anonymous Information.{" "}
        </p>
        <p className="BulletsCxSpLast">
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          We may engage service providers to analyze the interests and
          attributes of our users and, using techniques based on Anonymous
          Information and Online Activity Information, identify others who might
          share those interests and attributes. We then use this information to
          reach out to relevant market segments to provide them information
          concerning the Platform.{" "}
        </p>
        <p className="BodyTextJ">
          We require our service providers to contractually commit to protect
          the privacy and security of the Personal Information they process on
          our behalf.{" "}
        </p>
        <h2>
          <b>
            <span>
              6.2
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Third Party Services</b>.
        </h2>
        <p className="BodyTextJ">
          Under certain circumstances, Data Intelligence may provide Customer
          Data to Third Party Service Providers (as such term is defined in the
          Terms of Service) or others for purposes set out in our Terms of
          Services. To the extent the Customer Data we share for such purposes
          includes Personal Information or other confidential information, we
          require that the Third Party Service Providers and others safeguard
          such information with protections that are compatible with those set
          out in this Privacy Policy and the Terms of Service.
        </p>
        <h2>
          <b>
            <span>
              6.3
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Questions of Harm; Legal Process</b>.{" "}
        </h2>
        <p className="BodyTextJ">
          We may disclose your Personal Information and Online Activity
          Information to third parties, including law enforcement agencies,
          attorneys, and private investigators, where it is necessary, or where
          we have a good faith belief that it is necessary: (i) to comply with
          legal process; (ii) to protect and defend our rights and property,
          including the Platform and associated content; (iii) to protect
          against misuse or unauthorized use of our Platform and associated
          content; (iv) to protect the personal safety or property of Platform
          users or the public, including your personal safety or property (it
          being understood that we assume no duty to provide, or monitor the
          need for, such protections); and (v) to cooperate with public and
          government authorities including, where required, authorities outside
          your jurisdiction.
        </p>
        <p className="BodyTextJ">
          While you are not able to opt out of this use of information, we will
          take reasonable steps to limit such use, and disclose only the
          information we reasonably believe is necessary for the above purposes.
          If we receive legal process calling for the disclosure of your
          Personal Information, then we will attempt to notify you within a
          reasonable amount of time, unless such notification is not permitted.{" "}
        </p>
        <h2>
          <b>
            <span>
              6.4
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Corporate Transactions</b>.{" "}
        </h2>
        <p className="BodyTextJ">
          We shall be entitled to transfer information that we collect
          (including Personal Information) to a third party in connection with
          or in contemplation of a reorganization, merger, sale, joint venture,
          assignment, transfer, or other disposition (including a disposition in
          connection with a bankruptcy or similar proceedings) of all or
          substantially all assets or stock of the business unit or division
          responsible for the information under this Privacy Policy; provided
          the acquiring third party has agreed to safeguard your Personal
          Information with protections that are compatible with those set out in
          this Privacy Policy.{" "}
        </p>
        <h2>
          <b>
            <span>
              6.5
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Our Affiliates</b>.{" "}
        </h2>
        <p className="BodyTextJ">
          We<span style={{ letterSpacing: ".95pt" }}> </span>may
          <span style={{ letterSpacing: ".85pt" }}> </span>choose
          <span style={{ letterSpacing: "1.0pt" }}> </span>to
          <span style={{ letterSpacing: ".95pt" }}> </span>rely
          <span style={{ letterSpacing: ".9pt" }}> </span>on
          <span style={{ letterSpacing: ".95pt" }}> </span>and
          <span style={{ letterSpacing: ".95pt" }}> </span>share the information
          we collect<span style={{ letterSpacing: ".95pt" }}> </span>with
          <span style={{ letterSpacing: ".95pt" }}> </span>our
          <span style={{ letterSpacing: ".9pt" }}> </span>affiliates. By
          "affiliate" we mean an entity that is closely related to us, such as
          an entity that controls, is controlled by, or is under common control
          with, Data Intelligence Technologies, Inc. Our affiliates will be
          bound by the terms of this Privacy Policy.
        </p>
        <h1 style={{ marginBottom: "12.0pt" }}>
          <span>
            7.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Advertising<span style={{ fontWeight: "normal" }}>.</span>{" "}
        </h1>
        <p className="BodyTextJ">
          Our Platform may display online advertising to keep our users up to
          speed on various offerings, promotions, and associated content. Our
          Platform may include the use of ad banner partners for the serving
          and/or targeting of ads, promotions, and other marketing messages.
          Like many companies, we may also display targeted ads or banners about
          us to you when you are visiting other sites and apps. The banners and
          ads that you may see displayed may be based on information we hold
          about you, such as your previous use of our Platform. This may include
          your search history on our Platform, the content you read on our
          Platform, or on banners or ads you have previously clicked on. These
          ads may be provided, in some cases, by a third party ad service
          provider or advertiser (collectively, "<b>Advertisers</b>").
          Additionally, Advertisers may place or utilize their own cookies on
          your browser and may use Anonymous Information about your visit to our
          Platform. If an Advertiser requests that you provide Personal
          Information to it or otherwise accept its cookie on your browser,
          please be aware that the Advertiser's use and collection of this
          information and use of any cookies will be governed by its privacy
          policy or other relevant agreement, and not by our Privacy Policy. We
          recommend that you review the Advertiser's privacy policy before
          providing Personal Information. In certain circumstances, you may be
          able opt out of receiving targeted advertising from certain ad
          networks, data exchanges, marketing analytics, and other service
          providers as indicated at:{" "}
          <a href="http://www.aboutads.info/choices/">
            http://www.aboutads.info/choices/
          </a>
          .
        </p>
        <h1>
          <span name="_Toc517799128">
            <span>
              8.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            How We Safeguard the Information We Collect
          </span>
          <span style={{ fontWeight: "normal" }}>.</span>
        </h1>
        <p className="BodyTextJ">
          We recognize the sensitivity of our users' Personal Information and we
          have put in place security systems designed to prevent unauthorized
          access to or disclosure of this information. Our security systems
          include physical, technical, and administrative information security
          controls, and we take commercially reasonable steps to secure and
          safeguard such Personal Information in accordance with Applicable Law.
        </p>
        <h1>
          <span name="_Toc517799129">
            <span>
              9.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Our Retention of Data
          </span>
          <span style={{ fontWeight: "normal" }}>.</span>
        </h1>
        <p className="BodyTextJ">
          We retain Personal Information for the period of time necessary to
          fulfill the purposes for which we obtained the Personal Information
          and consistent with Applicable Law. We use the following criteria to
          set our retention periods: (i) the duration of our relationship with
          you; (ii) the existence of a legal obligation as to the retention
          period; and (iii) the advisability of retaining the information in
          light of our legal position (for example, in light of applicable
          statutes of limitations, litigation, or regulatory investigations).{" "}
        </p>
        <h1>
          <span name="_Toc517799130">
            <span>
              10.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Accuracy and Minimization of Data
          </span>
          <span style={{ fontWeight: "normal" }}>.</span>
        </h1>
        <p className="BodyTextJ">
          We take reasonable steps to (i) maintain the accuracy of the Personal
          Information we process, and (ii) limit the Personal Information that
          we process to that which is reasonably necessary for the purposes for
          which we obtained the information.{" "}
        </p>
        <h1>
          <span name="_Ref28607807">
            <span>
              11.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Your Choices Regarding Your Personal Information; Opting Out
          </span>
          <span style={{ fontWeight: "normal" }}>.</span>
        </h1>
        <h2>
          <span name="_Ref28602934">
            <b>
              <span>
                11.1
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
            </b>
            <b>Unsubscribing to </b>
          </span>
          <b>Marketing Communications</b>.{" "}
        </h2>
        <p className="BodyTextJ">
          If we choose to send to you, or you have elected to receive,
          newsletters, bulletins, updates, or other marketing-related materials,
          we will provide you with the ability to decline – or "opt out" – of
          receiving such communications. Instructions for opting-out will be
          provided if and when we determine to send you such a communication.
          For example, if you no longer wish to receive email messages from us,
          you can opt out of this Service by either: (i) following the
          "unsubscribe" instructions located near the bottom of each email
          message, or (ii) contacting us as provided in 19 (Contact Us) below.
          Please understand that we may still communicate with you in connection
          with administrative notices concerning any transactions, operation of
          the Platform and legal notices.
        </p>
        <h2>
          <b>
            <span>
              11.2
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Discretionary Account Information</b>.{" "}
        </h2>
        <p className="BodyTextJ">
          To allow appropriate control over Personal Information, you can view,
          change, or update discretionary information that you have previously
          submitted either by contacting us as provided in Section 19 (Contact
          Us) below.
        </p>
        <h2>
          <span name="_Ref28597264">
            <b>
              <span>
                11.3
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>Access, Deletion, and Do Not Sell Requests</b>
          </span>
          .{" "}
        </h2>
        <p className="BodyTextJ">
          DATA INTELLIGENCE IS CURRENTLY IMPLEMENTING INTERNAL CHANGES TO
          FULFILL REQUESTS SUBMITTED UNDER THIS SECTION 12.3 (ACCESS, DELETION,
          AND DO NOT SELL REQUESTS). UNTIL SUCH CHANGES ARE COMPLETED, DATA
          INTELLIGENCE MAY NOT BE ABLE TO FULFILL CERTAIN REQUESTS. DATA
          INTELLIGENCE WILL NOTIFY THOSE MAKING A REQUEST IF SUCH REQUEST IS
          IMPACTED BY ONGOING INTERNAL CHANGES. PLEASE NOTE THAT THESE REQUESTS
          MAY ONLY BE AVAILABLE TO CALIFORNIA RESIDENTS AT THIS TIME.
        </p>
        <h2 style={{ marginLeft: "0in", textIndent: "0in" }}>
          We provide the following additional rights with respect to our
          collection, use, disclosure, or sale (if applicable) of your Personal
          Information where required by Applicable Law (such as for California
          residents):
        </h2>
        <h3>
          <b>
            <span>
              11.3.1
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Copy of Personal Information</b>.{" "}
        </h3>
        <p className="BodyTextJ">
          To the extent you are entitled to such rights under Applicable Law,
          you may request a copy of the following: (i) the categories of
          Personal Information we collected about you in (at least) the last
          twelve months; (ii) the categories of sources from which the Personal
          Information is collected; (iii) the business or commercial purpose for
          collecting or selling (if applicable) the Personal Information; (iv)
          the categories of third parties with whom we share Personal
          Information in (at least) the last twelve months; and (v) the specific
          pieces of your Personal Information we have collected, used,
          disclosed, or sold.{" "}
        </p>
        <h3>
          <b>
            <span>
              11.3.2
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Deletion of Personal Information</b>.{" "}
        </h3>
        <p className="BodyTextJ">
          To the extent you are entitled to such rights under Applicable Law,
          you may request that we (and our service providers) delete your
          Personal Information. Note that deletion requests are subject to a
          number of limitations, for example, we may keep Personal Information
          as permitted by law, such as for tax or other record keeping purposes,
          to maintain an active account, to process transactions and facilitate
          customer requests, and for other internal business purposes described
          in this Privacy Policy.
        </p>
        <h3>
          <b>
            <span>
              11.3.3
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Do Not Sell Requests</b>.{" "}
        </h3>
        <p className="BodyTextJ">
          To the extent you are entitled to such rights under Applicable Law,
          you may request that we do not share your Personal Information with
          third parties to be used for purposes not described in this Privacy
          Policy. The California Consumer Privacy Act refers to this as a "Do
          Not Sell" request. Data Intelligence only shares Personal Information
          with others described above for purposes identified in this Privacy
          Policy, but Online Activity Information or Anonymous Information
          collected by our service providers providing pixels or other third
          party technologies on our App could potentially be used by such
          parties for their own purposes, some of whom may be able to associate
          such information with you based on other information they have
          collected independently (not from Data Intelligence). We will endeavor
          to block such sharing upon request.
        </p>
        <h3>
          <b>
            <span>
              11.3.4
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Submission of Requests</b>.{" "}
        </h3>
        <p className="BodyTextJ">
          You can submit any of these requests by clicking here:{" "}
          <a href="https://a3.cyberdataintelligence.com/contact">
            https://a3.cyberdataintelligence.com/contact
          </a>
          or by contacting us as provided in Section 19 (Contact Us) below. You
          may authorize another person (your "<b>Agent</b>") to submit a request
          on your behalf the same way. Shortly after you (or your Agent) submit
          a request, we will contact you (usually via email) with instructions
          on how to verify the submitted request before we fulfill it. Please be
          advised that these requests are unrelated to an opt-out of marketing
          communications, which can be done as provided in Section 12.1
          (Unsubscribing to Marketing Communications) above. Please also note
          that in addition to submitting privacy requests as described here, you
          can opt-out of third party advertising technology on your device by
          configuring settings on your browser and/or device.<b> </b>We will
          fulfill these requests in its reasonable discretion and in a manner
          consistent with any Applicable Law.{" "}
        </p>
        <h1>
          <span name="_Toc517799133">
            <span>
              12.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Advisory Regarding Participation by Children and Teens
          </span>
          <span style={{ fontWeight: "normal" }}>.</span>
        </h1>
        <p className="BodyTextJ">
          Under U.S. Federal Law (as reflected in the Children's Online Privacy
          Protection Act), WE DO NOT COLLECT OR STORE ANY PERSONAL INFORMATION
          FROM INDIVIDUALS THAT WE KNOW ARE UNDER THE AGE OF 13. If you wish
          further information concerning privacy policies in general, and
          concerning online social networking and safety, you should visit the
          following website:{" "}
          <a href="http://www.ftc.gov/privacy/index.html">
            http://www.ftc.gov/privacy/index.html
          </a>
          .
        </p>
        <h1>
          <span name="_Toc517799134">
            <span>
              13.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Relationship to Other Contracts and{" "}
          </span>
          Terms of Service<span style={{ fontWeight: "normal" }}>.</span>
        </h1>
        <p className="BodyTextJ">
          This Privacy Policy must be read in conjunction with any other
          agreement entered into by you and us that references this Privacy
          Policy or that otherwise relates to Personal Information
          (collectively, the "<b>Other Contracts</b>"), as well as our Terms of
          Service, which is available at{" "}
          <a href="https://a3.cyberdataintelligence.com/terms">
            https://a3.cyberdataintelligence.com/terms
          </a>
          . The provisions of such Other Contracts are incorporated herein. To
          the extent that the Terms of Service conflict with this Privacy
          Policy, this Privacy Policy shall control. To the extent that the
          Other Contracts conflict with this Privacy Policy or the Terms of
          Service, the Privacy Policy shall control with respect to our
          treatment of Personal Information, unless expressly stated otherwise.
        </p>
        <h1>
          <span name="_Ref28599883">
            <span>
              14.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Notice of Privacy Rights to California Residents
          </span>
          <span style={{ fontWeight: "normal" }}>.</span>
        </h1>
        <p className="BodyTextJ">
          The following provisions of this Section 15 (Notice of Privacy Rights
          to California Residents) apply if you are a California resident.
        </p>
        <h2>
          <b>
            <span>
              14.1
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Shine the Light Law</b>.{" "}
        </h2>
        <p className="BodyTextJ">
          California law requires certain businesses to respond to requests from
          California users who ask about business practices related to
          disclosing Personal Information to third parties for their own direct
          marketing purposes. The California "Shine the Light" law further
          requires us to allow California residents to opt out of certain
          disclosures of Personal Information to third parties for their own
          direct marketing purposes.
        </p>
        <h2>
          <b>
            <span>
              14.2
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>California Consumer Privacy Act</b>.{" "}
        </h2>
        <p className="BodyTextJ">
          Data Intelligence makes the following disclosures regarding the
          California Consumer Privacy Act:
        </p>
        <h3>
          <b>
            <span>
              14.2.1
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>No Financial Incentives</b>.{" "}
        </h3>
        <p className="BodyTextJ">
          Data Intelligence does not provide a financial incentive or a price or
          service difference to customers in exchange for the retention or sale
          of their Personal Information. We may send promotions and other offers
          to those individuals subscribing to our marketing communications and,
          unless an individual has opted out of such communications, the
          individual will continue to receive such communications irrespective
          of whether a disclosure, deletion, or "Do Not Sell" request has been
          submitted. Data Intelligence does not offer financial incentives to
          deter customers from making such requests. Data Intelligence may not
          discriminate against any consumer for exercising their privacy rights.
        </p>
        <h3>
          <b>
            <span>
              14.2.2
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Job Applicants</b>.{" "}
        </h3>
        <p className="BodyTextJ">
          If you are a job applicant submitting Personal Information to Data
          Intelligence in connection with an application for employment, you
          will be provided with a privacy notice regarding how we handle such
          information as part of the application process.
        </p>
        <h3>
          <b>
            <span>
              14.2.3
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Questions</b>.{" "}
        </h3>
        <p className="BodyTextJ">
          If you have any questions about our privacy practices, including in
          connection with the California Consumer Privacy Act, please contact us
          as directed in Section 19 (Contact Us) below.
        </p>
        <h2>
          <b>
            <span>
              14.3
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>California Do No Track Notice</b>.
        </h2>
        <p className="BodyTextJ">
          Our App does not change its behavior when receiving "Do Not Track"
          signals from browser software, and handles all Personal Information
          consistent with this Privacy Policy.
        </p>
        <h1 style={{ marginBottom: "12.0pt" }}>
          <span>
            15.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Third Party Sites<span style={{ fontWeight: "normal" }}>. </span>
        </h1>
        <p className="BodyTextJ">
          Our Site may link to (or be linked from) third party websites or third
          party features ("<b>Linked Sites</b>"), as further explained in our
          Terms of Service. We have no control over third parties that operate
          such Linked Site, and we are not responsible for the content,
          availability, or security of such Linked Sites. Third parties may use
          your information for their own purposes, including for behavioral or
          advertising purposes. The inclusion of a link on our Platform to a
          Linked Site is provided for your convenience only and does not imply
          endorsement of the Linked Site, the third party, or its products or
          services. If you decide to access any of the Linked Sites, you do so
          at your own risk and subject to the terms and conditions of use for
          such Linked Sites. Any Personal Information that you provide through a
          Linked Site will be subject to the privacy policy of its operator and
          not this Privacy Policy.
        </p>
        <h1>
          <span>
            16.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Complaints<span style={{ fontWeight: "normal" }}>.</span>
        </h1>
        <p className="BodyTextJ">
          We want your feedback. If you have a suggestions on how we can improve
          or complaints you would like us to address, please contact us at the
          address set out in Section 19 (Contact Us).{" "}
          <span name="_Ref506313645">
            If you are a California resident, you may report complaints to the
            Complaint Assistance Unit of the Division of Consumer Services of
            the California Department of Consumer Affairs. Other states may
            provide similar avenues for lodging complaints. Please check with
            your state's consumer protection authority.
          </span>
        </p>
        <h1>
          <span name="_Toc517799137">
            <span>
              17.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;{" "}
              </span>
            </span>
            Changes to this Privacy Policy
          </span>
          <span style={{ fontWeight: "normal" }}>.</span>
        </h1>
        <p className="BodyTextJ">
          From time to time, we may change this Privacy Policy for our business
          purposes and to comply with changes in Applicable Law. If we make any
          substantive or material changes, then we will communicate these
          changes to you by posting the updated Privacy Policy on the Platform
          and/or notifying you of the change via the Platform, email, or other
          methods. Your continued use of the Platform following such notice
          constitutes your agreement to follow and be bound by the updated
          Privacy Policy.
        </p>
        <h1>
          <span name="_Toc517799138">
            <span>
              18.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;
              </span>
            </span>
            Contact Us
          </span>
          <span style={{ fontWeight: "normal" }}>.</span>
        </h1>
        <p className="BodyTextJ">
          If you have any questions about this Privacy Policy or our Platform,
          then please contact us at{" "}
          <a href="https://www.cyberdataintelligence.com/contact">
            https://www.cyberdataintelligence.com/contact
          </a>
          .
        </p>
        <h2>
          <b>
            <span>
              18.1
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Accessing and Updating Your Information</b>.{" "}
        </h2>
        <p className="BodyTextJ">
          If you would like to review, correct, or update the Personal
          Information that you have provided to us, or if you would like to
          request an electronic copy of this Personal Information for purposes
          of transmitting it to another company (to the extent applicable law
          provides you with this right to data portability) you may make such
          requests by us as provided in Section 19 (Contact Us).
        </p>
        <h2>
          <b>
            <span>
              18.2
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Deleting Information</b>.{" "}
        </h2>
        <p className="BodyTextJ">
          If you request, we will take reasonable steps to remove your Personal
          Information from our databases, within the time frames (if any) set
          out in applicable law. Please understand, however, that if you request
          the deletion of your information, you will no longer be able to
          receive our Services. In addition, it may be impractical (or
          essentially impossible) to remove the requested information
          completely, due to requirements promulgated by applicable law, and/or
          data backups and records of deletions. As such, certain Personal
          Information may remain in our databases following the deletion of your
          account; we will continue to treat the remaining information (if any)
          in accordance with this Privacy Policy and applicable law.<b> </b>
        </p>
        <h2>
          <b>
            <span>
              18.3
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>Objections</b>.{" "}
        </h2>
        <p className="BodyTextJ">
          If you object to our processing of your Personal Information, and a
          request for us to delete this Personal Information is not, in your
          view, sufficient, please contact us as provided in Section 19 (Contact
          Us).{" "}
        </p>
        <h1>
          <span>
            19.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Effective Date<span style={{ fontWeight: "normal" }}>.</span>
        </h1>
        <p className="BodyTextJ">
          The effective date of this Privacy Policy is June 14th, 2021.
        </p>
        <h1>
          <span>
            20.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Copyright and Legal Notice
          <span style={{ fontWeight: "normal" }}>.</span>
        </h1>
        <p className="MsoBodyText" style={{ textIndent: "0in" }}>
          Copyright © 2021 Data Intelligence Technologies, Inc. All Rights
          Reserved. No part of this content may be reproduced, stored, or
          transmitted in any form without written permission from Data
          Intelligence Technologies, Inc.
        </p>
      </div>
    </div>
  );
};
