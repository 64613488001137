import React from "react";
import Avatar from "@material-ui/core/Avatar";

import OrgAvatar from "../Organizations/Organization/OrgAvatar";

export default ({ user, organization, roles }) => {
  const { first_name, last_name } = user;

  return (
    <div>
      <div className="row" style={{ marginBottom: 10 }}>
        <div
          className="col-xs-3"
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          <Avatar alt={first_name}>
            {first_name.substring(0, 1).toUpperCase()}
          </Avatar>
          <div style={{ marginLeft: 10 }}>
            {first_name} {last_name}
          </div>
        </div>
        <div className="col-xs-9">
          {roles &&
            roles.map((role) => {
              return (
                <div
                  className="row"
                  key={role.title}
                  style={{ marginBottom: 10 }}
                >
                  <div className="col-xs-6">{role.title}</div>
                  <div className="col-xs-6">
                    {role.rate_type}
                    &nbsp;Rate: ${role.rate}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {organization && <OrgAvatar org={organization} />}
    </div>
  );
};
