import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

export default ({ org, my, assess }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {my && (
        <Link to={`/organization/${org.id}`}>
          <Button variant="contained" color="primary">
            Settings
          </Button>
        </Link>
      )}
      {assess && (
        <div>
          {org.level && (
            <div style={{ marginRight: 20, whiteSpace: "nowrap" }}>
              Level {org.level}
            </div>
          )}
          <Link to={`/assessment/${org.assessment_id}`}>
            <Button variant="contained" color="primary">
              Continue Assessment
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};
