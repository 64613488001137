import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import Activate from "./components/Activate";
import Assessment from "./components/Assessment";
import Assessments from "./pages/Assessments";
import Invite from "./pages/Invite";
import Login from "./components/Login";
import LoginSuccess from "./components/Login/LoginSuccess";
import Marketplace from "./pages/Marketplace";
import Proposal from "./pages/Proposal";
import MyOrganizations from "./components/Organizations/MyOrganizations";
import Organization from "./pages/Organization";
import Packages from "./pages/Packages";
import Policy from "./pages/Policy";
import Profile from "./pages/Profile";
import Reset from "./pages/Login/Reset";
import SignUp from "./components/Login/SignUp";
import Terms from "./pages/Terms";
import Checkout from "./pages/Checkout";

import "flexboxgrid/css/flexboxgrid.min.css";
import "./App.css";

export default function App() {
  return (
    <Router>
      <div className="app">
        <Navbar />
        <div
          style={{
            padding: 15,
            display: "flex",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <Switch>
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/invite/:activation_hash" component={Invite} />
            <Route exact path="/login/success" component={LoginSuccess} />
            <Route path="/activate/:activation_hash" component={Activate} />
            <Route path="/reset/:activation_hash" component={Reset} />
            <Route path="/assessment/:assessment" component={Assessment} />
            <Route path="/organizations" component={MyOrganizations} />
            <Route path="/organization/:id" component={Organization} />
            <Route path="/packages" component={Packages} />
            <Route path="/marketplace" component={Marketplace} />
            <Route path={`/proposal/:packageId`} component={Proposal} />
            <Route path="/terms" component={Terms} />
            <Route path="/policy" component={Policy} />
            <Route path="/checkout/:assessmentId" component={Checkout} />
            <Route path="/assessments" component={Assessments} />
            <Route path="/profile/:user" component={Profile} />
            <Route exact path={["/", "/login"]} component={Login} />
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}
