import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import http, { getUser } from "../../components/http";

import RoleSelector from "../../components/RoleSelector";

const requestPackage = ({ packageId, proposal, role }) => {
  return http.post("/api/assessments/request", {
    assessment: packageId,
    proposal,
    role,
  });
};

const Proposal = ({ match, history }) => {
  const user = getUser();
  const { params } = match;

  return (
    <div className="container">
      <Formik
        initialValues={{ proposal: "", role: {} }}
        validate={(values) => {
          const errors = {};
          Object.keys(values).forEach((key) => {
            if (!values[key]) errors[key] = "Required";
          });
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          console.log(params, values);
          requestPackage({ packageId: params.packageId, ...values }).then(
            (res) => {
              setSubmitting(false);
              if (res.error) return alert(res.error);
              history.push("/assessments");
            }
          );
        }}
      >
        {({ isSubmitting, values, setFieldValue, errors }) => (
          <Form>
            <RoleSelector
              user={user.id}
              value={values.role}
              onChange={(value) => {
                setFieldValue("role", value);
              }}
            />
            <div className="input-row">
              <Field
                name="proposal"
                label="Description"
                as={TextField}
                multiline
                className="login-input"
              />
              <ErrorMessage name="proposal" component="div" className="error" />
            </div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Submit a proposal
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Proposal;
