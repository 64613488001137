import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import can from "../../rbac";
export default ({ objective, evaluate, submit, canEdit }) => {
  const { evidence } = objective;
  const [affirmation, setAffirmation] = useState(evidence.affirmation || "");
  const [affirmationNotes, setAffirmationNotes] = useState(
    evidence.affirmation_notes || ""
  );

  return (
    <div className="practice-controls">
      {can("EVIDENCE:CREATE") && (
        <div className="assessee-section">
          <div style={{ marginBottom: 10 }}>
            <TextField
              label="Contact"
              value={affirmation}
              onChange={(e) => {
                setAffirmation(e.target.value);
              }}
              disabled={!canEdit}
            />
          </div>
          <div>
            <TextField
              label="Notes"
              value={affirmationNotes}
              onChange={(e) => {
                setAffirmationNotes(e.target.value);
              }}
              disabled={!canEdit}
              fullWidth
              multiline
            />
          </div>
          <div style={{ marginTop: 10, textAlign: "right" }}>
            {canEdit && (
              <Button
                onClick={() => {
                  submit({ affirmation, affirmation_notes: affirmationNotes });
                }}
                variant="contained"
                color="primary"
                disabled={
                  (affirmation === evidence.affirmation &&
                    affirmationNotes === evidence.affirmationNotes) ||
                  !canEdit
                }
                style={{ marginRight: 10 }}
              >
                SAVE
              </Button>
            )}
          </div>
        </div>
      )}
      {can("EVALUATIONS:CREATE") && (
        <div className="assessor-section">
          <div>
            <h3>Contact</h3>
            <div>{evidence.affirmation}</div>
          </div>
        </div>
      )}
    </div>
  );
};
