import React from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
// import Switch from '@material-ui/core/Switch'
import Checkbox from "@material-ui/core/Checkbox";
import { green } from "@material-ui/core/colors";

import http from "../http";
import cyberLogo from "./cyber_logo.png";
import "./style.css";

export const validatePassword = (str) => {
  return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/.test(
    str
  );
};

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

class SignUp extends React.Component {
  render = () => {
    const { history } = this.props;
    return (
      <div className="login">
        <div className="login-logo">
          <div>
            <img
              src={cyberLogo}
              alt="logo"
              style={{ width: 200, marginRight: 50 }}
            />
          </div>
          <div className="login-container">
            <h1>Sign Up</h1>
            <Formik
              initialValues={{
                email: "",
                password: "",
                first_name: "",
                last_name: "",
                assessor: false,
                terms: false,
              }}
              validate={(values) => {
                const errors = {};
                Object.keys(values).forEach((key) => {
                  if (key !== "assessor" && key !== "terms" && !values[key])
                    errors[key] = "Required";
                });
                if (!validatePassword(values.password))
                  errors.password =
                    "Password must be at least 6 characters and contain at least 1 lowercase, uppercase, number and special character.";
                if (!values.terms)
                  errors.terms =
                    "Please accept the Terms of Service and Privacy Policy";
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                http.post(`/api/register`, values).then((res) => {
                  setSubmitting(false);
                  if (res.error) return alert(res.error);
                  // window.location = '/organizations'
                  history.push("/login/success");
                });
              }}
            >
              {({ isSubmitting, values, setFieldValue, errors }) => (
                <Form>
                  {/* <div className='input-row'>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        color: values.assessor ? '' : '#3f51b5',
                        fontWeight: values.assessor ? '' : 'bold',
                      }}
                    >
                      Organization
                    </div>
                    <Switch
                      checked={values.assessor}
                      onChange={(e) => {
                        setFieldValue('assessor', e.target.checked)
                      }}
                      color='default'
                    />
                    <div
                      style={{
                        color: values.assessor ? '#3f51b5' : '',
                        fontWeight: values.assessor ? 'bold' : '',
                      }}
                    >
                      Assessor
                    </div>
                  </div>
                </div> */}
                  <div className="input-row">
                    <Field
                      type="email"
                      name="email"
                      label="Email"
                      as={TextField}
                      className="login-input"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="input-row">
                    <Field
                      name="first_name"
                      label="First Name"
                      as={TextField}
                      className="login-input"
                    />
                    <ErrorMessage
                      name="first_name"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="input-row">
                    <Field
                      name="last_name"
                      label="Last Name"
                      as={TextField}
                      className="login-input"
                    />
                    <ErrorMessage
                      name="last_name"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="input-row">
                    <Field
                      type="password"
                      name="password"
                      label="Password"
                      as={TextField}
                      className="login-input"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="input-row">
                    <div style={{ display: "flex" }}>
                      <Field name="terms" color="primary" as={GreenCheckbox} />
                      <div>
                        I accept the{" "}
                        <Link to="/terms" style={{ color: "white" }}>
                          Terms of Service
                        </Link>{" "}
                        and{" "}
                        <Link to="/policy" style={{ color: "white" }}>
                          Privacy Policy
                        </Link>
                      </div>
                    </div>
                    <ErrorMessage
                      name="terms"
                      component="div"
                      className="error"
                    />
                  </div>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Sign Up
                  </Button>
                </Form>
              )}
            </Formik>
            <div style={{ marginTop: 15 }}>
              <Link to="/" style={{ color: "white" }}>
                Have an account? Log In
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default SignUp;
