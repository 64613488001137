import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import qs from "qs";

import http from "../../components/http";
import User from "../../components/User/index";
import AssessorFilter from "./AssessorFilter";

const Assessors = ({ location, history }) => {
  const [loading, setLoading] = useState(true);
  const [assessors, setAssessors] = useState([]);

  useEffect(() => {
    setLoading(true);
    const search = qs.parse(location.search.slice(1));
    http
      .get("/api/assessors", {
        roles: search.role,
        rateType: search.rate_type,
        minRate: search.min_rate,
        maxRate: search.max_rate,
      })
      .then((res) => {
        setLoading(false);
        if (res.error) return alert(res.error);
        setAssessors(res.assessors);
      });
  }, [location.search]);

  const updateSearch = (newSearch) => {
    const oldSearch = qs.parse(location.search.slice(1));
    history.push({ search: qs.stringify({ ...oldSearch, ...newSearch }) });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div className="container">
        <AssessorFilter
          location={location}
          type="user"
          updateSearch={updateSearch}
        ></AssessorFilter>
        {loading && <div>Loading...</div>}
        {!loading && (
          <div>
            {assessors.map((assessor) => {
              return (
                <Card key={assessor.id} style={{ marginBottom: 10 }}>
                  <CardContent>
                    <User
                      user={assessor}
                      roles={assessor.roles}
                      emptyState={{
                        title: "You have no assessments",
                        body: "Go to the marketplace to get started.",
                      }}
                    />
                  </CardContent>
                </Card>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Assessors;
