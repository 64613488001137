import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";

export default ({ vendors }) => {
  if (!vendors) return null;

  return (
    <div>
      <h3>Vendors</h3>
      {vendors.map((vendor) => {
        return (
          <Card key={vendor.name}>
            <CardContent>
              <div style={{ display: "flex" }}>
                <div style={{ width: 150, marginRight: 20 }}>
                  <img src={vendor.logo_url} alt={vendor.name} />
                </div>
                <div style={{ flex: 1 }}>
                  <div style={{ fontWeight: "bold", marginBottom: 10 }}>
                    {vendor.name}
                  </div>
                  <div style={{ marginBottom: 10, marginRight: 20 }}>
                    {vendor.description}
                  </div>
                </div>
                <div>
                  <a href={vendor.url} target="blank">
                    <Button color="primary" variant="contained">
                      Learn More
                    </Button>
                  </a>
                </div>
              </div>
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
};
