import React, { useState, useEffect } from "react";

import http from "../../components/http";
import Assessments from "../../components/Assessments/index";
import RoleSelector from "../../components/RoleSelector";

const AssessmentsPage = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [assessments, setAsssessments] = useState([]);
  const [role, setRole] = useState("");

  const getAssessments = () => {
    http
      .get("/api/assessments", {
        role: role || "Certified CMMC ASSESSORS (CCA)",
      })
      .then((res) => {
        setLoading(false);
        if (res.error) return alert(res.error);
        setAsssessments(res.assessments);
      });
  };

  useEffect(() => {
    getAssessments();
  }, [role]);

  if (loading) return null;

  return (
    <div className="container">
      <RoleSelector
        value={role}
        user
        onChange={(value) => {
          setRole(value);
        }}
        field="title"
      />

      <Assessments
        assessments={assessments}
        onUpdate={getAssessments}
        history={history}
        emptyState={{
          title: "You have no assessments",
          body: "Go to the marketplace to get started.",
        }}
        page="assessments"
      />
    </div>
  );
};

export default AssessmentsPage;
