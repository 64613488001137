import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import OrgActions from "./OrgActions";

class Organization extends React.Component {
  render = () => {
    const { org, my } = this.props;

    return (
      <Card key={org.name} style={{ marginBottom: 15 }}>
        <CardContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="row">
              <div className="col-xs-12  col-sm-3">
                {org.logo ? (
                  <img
                    src={org.logo}
                    alt={org.name}
                    style={{ maxWidth: 200 }}
                  />
                ) : (
                  <b>{org.name}</b>
                )}
              </div>
              <div className="col-xs-12 col-sm-8">{org.description}</div>
            </div>
            <OrgActions org={org} my={my} />
          </div>
        </CardContent>
      </Card>
    );
  };
}

export default Organization;
