import React, { useEffect } from "react";
import { Switch, Route, NavLink } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { FaChevronLeft } from "react-icons/fa";

import Pass from "./Pass";
import Objectives from "./Objectives";

export default ({
  match,
  location,
  history,
  practices,
  getPractices,
  assessment: qAssessment,
}) => {
  const { assessment, level, domain } = match.params;

  useEffect(() => {
    getPractices();
  }, [location.pathname]);

  return (
    <Switch>
      <Route
        path={`${match.path}/:practice`}
        render={(routeProps) => (
          <Objectives {...routeProps} assessment={qAssessment} />
        )}
      />
      <Route exact path={`${match.path}`}>
        <List component="nav" aria-label="main mailbox folders">
          <ListItem
            button
            onClick={() => {
              history.push(`/assessment/${assessment}/${level}`);
            }}
          >
            <div className="list-text menu-header">
              <FaChevronLeft />
              &nbsp;&nbsp;
              <div>{domain}</div>
            </div>
          </ListItem>
          {practices.map((practice) => {
            return (
              <NavLink
                key={practice.id}
                to={`${match.url}/${practice.id}`}
                className="practice-link"
                activeClassName="practice-active"
              >
                <ListItem button>
                  <ListItemIcon>
                    <Pass practice={practice} assessment={qAssessment} />
                  </ListItemIcon>
                  <div className="list-text">{practice.id}</div>
                </ListItem>
              </NavLink>
            );
          })}
        </List>
      </Route>
    </Switch>
  );
};
