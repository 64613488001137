import { getUser } from "./http";

const permissions = {
  admin: ["ADMIN:ADD_ROLE", "ADMIN:ASSIGN_ROLE", "ADMIN:DELETE_ROLE"],
  assessor: ["EVALUATIONS:CREATE"],
  assessee: ["EVIDENCE:CREATE"],
  "CMMC REGISTERED PRACTITIONERS (RP)": [],
  "Certified CMMC PROFESSIONALS (CCP)": [],
  "Certified CMMC ASSESSORS (CCA)": ["EVALUATIONS:CREATE"],
  "CMMC Licensed Instructor (LI)": [],
};

export default (permission, roles) => {
  const user = getUser();
  if (user && user.roles && !roles) roles = user.roles;
  let canDo = false;
  if (roles) {
    roles.forEach((role) => {
      if (
        permissions[role.title] &&
        permissions[role.title].includes(permission)
      )
        canDo = true;
    });
  }
  return canDo;
};
