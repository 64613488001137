import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FaEdit } from "react-icons/fa";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

import http, { setJWT } from "../../components/http";
import states from "../../services/states";

import Roles from "./Roles";

import "./style.css";

export default ({ match }) => {
  const [user, setUser] = useState({});
  const [edit, setEdit] = useState(false);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    http.get("/api/users", { id: match.params.user }).then((res) => {
      if (res.error) return alert(res.error);
      setUser(res.users[0]);
    });
    setUpdate(false);
  }, [match.params.user, update]);

  return (
    <div className="profile">
      <div className="container" style={{ padding: "20px 0" }}>
        {!edit && (
          <div>
            <Button
              variant="contained"
              color="primary"
              style={{ marginBottom: 10 }}
              onClick={() => {
                setEdit(true);
              }}
            >
              <FaEdit />
              &nbsp;Edit
            </Button>
            <div className="profile-info row">
              <div className="col-xs-2">Email</div>
              <div className="col-xs-10">{user.email}</div>
            </div>
            <div className="profile-info row">
              <div className="col-xs-2">First Name</div>
              <div className="col-xs-10">{user.first_name}</div>
            </div>
            <div className="profile-info row">
              <div className="col-xs-2">Last name</div>
              <div className="col-xs-10">{user.last_name}</div>
            </div>
            <div className="profile-info row">
              <div className="col-xs-2">Phone</div>
              <div className="col-xs-10">{user.phone}</div>
            </div>
            <div className="profile-info row">
              <div className="col-xs-2">Address</div>
              <div className="col-xs-10">
                {user.street_address} {user.street_address_2}, {user.city},{" "}
                {user.state}, {user.zip}
              </div>
            </div>
          </div>
        )}
        {edit && (
          <div>
            <Formik
              initialValues={{
                first_name: user.first_name,
                last_name: user.last_name,
                phone: user.phone || "",
                street_address: user.street_address || "",
                street_address_2: user.street_address_2 || "",
                city: user.city || "",
                state: user.state || "",
                zip: user.zip || "",
              }}
              validate={(values) => {
                const errors = {};
                Object.keys(values).forEach((key) => {
                  if (key !== "assessor" && !values[key])
                    errors[key] = "Required";
                });
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                console.log("Test");
                http.put(`/api/users`, values).then((res) => {
                  setSubmitting(false);
                  if (res.error) return alert(res.error);
                  if (res.jwt) setJWT(res.jwt);
                  window.location.reload(0);
                });
              }}
            >
              {({ isSubmitting, values, setFieldValue, errors }) => (
                <Form>
                  <div className="input-row">
                    <Field
                      name="first_name"
                      label="First Name"
                      as={TextField}
                      className="login-input"
                    />
                    <ErrorMessage
                      name="first_name"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="input-row">
                    <Field
                      name="last_name"
                      label="Last Name"
                      as={TextField}
                      className="login-input"
                    />
                    <ErrorMessage
                      name="last_name"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="input-row">
                    <Field
                      name="phone"
                      label="Phone Number"
                      as={TextField}
                      className="login-input"
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="row">
                    <div className="input-row col-xs-6">
                      <Field
                        name="street_address"
                        label="Street Address"
                        as={TextField}
                        className="login-input"
                      />
                      <ErrorMessage
                        name="street_address"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="input-row col-xs-6">
                      <Field
                        name="street_address_2"
                        label="Street Address 2"
                        as={TextField}
                        className="login-input"
                      />
                      <ErrorMessage
                        name="street_address_2"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="input-row col-xs-4">
                      <Field
                        name="city"
                        label="City"
                        as={TextField}
                        className="login-input"
                      />
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="input-row col-xs-4">
                      <FormControl style={{ minWidth: 200 }}>
                        <InputLabel>State</InputLabel>
                        <Select
                          value={values.state}
                          onChange={(e) => {
                            setFieldValue("state", e.target.value);
                          }}
                        >
                          {states.map((state) => (
                            <MenuItem value={state}>{state}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <ErrorMessage
                        name="state"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="input-row col-xs-4">
                      <Field
                        name="zip"
                        label="Zip Code"
                        as={TextField}
                        className="login-input"
                      />
                      <ErrorMessage
                        name="zip"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      onClick={() => {
                        setEdit(false);
                      }}
                      disabled={isSubmitting}
                      variant="contained"
                      color="secondary"
                      style={{ marginRight: 10 }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
        <Roles roles={user.roles} type="user" setUpdate={setUpdate} />
      </div>
    </div>
  );
};
