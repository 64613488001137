import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

import http from "../../components/http";
import states from "../../services/states";

export default ({ org, onEdit, onCancel }) => {
  return (
    <div style={{ paddingTop: 20 }}>
      <Formik
        initialValues={{
          name: org.name || "",
          description: org.description || "",
          phone: org.phone || "",
          street_address: org.street_address || "",
          street_address_2: org.street_address_2 || "",
          city: org.city || "",
          state: org.state || "",
          zip: org.zip || "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          http
            .put(`/api/organizations`, { ...values, id: org.id })
            .then((res) => {
              setSubmitting(false);
              if (res.error) return alert(res.error);
              onEdit({ ...org, ...values });
            });
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="input-row">
              <Field
                name="name"
                label="Organization Name"
                as={TextField}
                className="login-input"
              />
              <ErrorMessage name="name" component="div" className="error" />
            </div>
            <div className="input-row">
              <Field
                name="description"
                label="Description"
                as={TextField}
                className="login-input"
              />
              <ErrorMessage
                name="description"
                component="div"
                className="error"
              />
            </div>
            <div className="input-row">
              <Field
                name="phone"
                label="Phone Number"
                as={TextField}
                className="login-input"
              />
              <ErrorMessage name="phone" component="div" className="error" />
            </div>
            <div className="row">
              <div className="input-row col-xs-6">
                <Field
                  name="street_address"
                  label="Street Address"
                  as={TextField}
                  className="login-input"
                />
                <ErrorMessage
                  name="street_address"
                  component="div"
                  className="error"
                />
              </div>
              <div className="input-row col-xs-6">
                <Field
                  name="street_address_2"
                  label="Street Address 2"
                  as={TextField}
                  className="login-input"
                />
                <ErrorMessage
                  name="street_address_2"
                  component="div"
                  className="error"
                />
              </div>
              <div className="input-row col-xs-4">
                <Field
                  name="city"
                  label="City"
                  as={TextField}
                  className="login-input"
                />
                <ErrorMessage name="city" component="div" className="error" />
              </div>
              <div className="input-row col-xs-4">
                <FormControl style={{ minWidth: 200 }}>
                  <InputLabel>State</InputLabel>
                  <Select
                    value={values.state}
                    onChange={(e) => {
                      setFieldValue("state", e.target.value);
                    }}
                  >
                    {states.map((state) => (
                      <MenuItem value={state}>{state}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <ErrorMessage name="state" component="div" className="error" />
              </div>
              <div className="input-row col-xs-4">
                <Field
                  name="zip"
                  label="Zip Code"
                  as={TextField}
                  className="login-input"
                />
                <ErrorMessage name="zip" component="div" className="error" />
              </div>
            </div>

            <div style={{ textAlign: "right" }}>
              <Button
                onClick={onCancel}
                disabled={isSubmitting}
                variant="contained"
                color="secondary"
                style={{ marginRight: 10 }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
