import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { FaTrash } from "react-icons/fa";
import http from "../../components/http";

const Role = ({ role, setUpdate }) => {
  const [rateType, setRateType] = useState(role.rate_type || "");
  const [rate, setRate] = useState(role.rate || 0);

  return (
    <Card key={role.id} style={{ marginBottom: 10 }}>
      <CardContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>{role.title}</div>
          <FormControl style={{ minWidth: 200 }}>
            <InputLabel>Payment Schedule</InputLabel>
            <Select
              value={rateType}
              onChange={(e) => {
                setRateType(e.target.value);
              }}
            >
              {["Hourly", "Fixed"].map((rType) => {
                return (
                  <MenuItem value={rType} key={rType}>
                    {rType}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            value={rate}
            onChange={(e) => {
              setRate(e.target.value);
            }}
            label="Rate"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              http.post("/api/roles/rate", {
                role: role.id,
                rate: rate,
                rate_type: rateType,
              });
            }}
          >
            SAVE
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              http.del("/api/roles/assign", { id: role.id });
              setUpdate(true);
            }}
          >
            <FaTrash />
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default Role;
