import React, { useState, useRef } from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { DateTime } from "luxon";
import Modal from "react-modal";
import qs from "qs";

import can from "../../rbac";
import http from "../../http";

import OrgAvatar from "../../Organizations/Organization/OrgAvatar";
import User from "../../User";
import BulkArtifacts from "./BulkArtifacts";

import "./style.css";

/*Modal.defaultStyles.overlay.backgroundColor = '';*/

const submitPackage = (assessment) => {
  if (
    window.confirm(
      "Are you sure you want to submit your package? Once you submit your package for evaluation you can no longer edit it."
    )
  )
    return http.put("/api/packages/submit", { assessment });
};

const submitAssessment = (assessment) => {
  if (window.confirm("Are you sure you want to submit your assessment?"))
    return http.put("/api/assessments/submit", { assessment });
};

const requestPoamReport = (assessment) => {
  /*let qsParams = {};
  qsParams.assessment = assessment;
  qsParams.info = true;
  qsParams.poam = true;

  window.open(`/api/objectives/poam${qs.stringify(qsParams)}`);
  return null;*/

  http
    .getBlob("/api/objectives/poam", { assessment, info: true, poam: true })
    .then((res) => {
      let newBlob = new Blob([res], { type: "text/csv" });
      let url = window.URL.createObjectURL(newBlob);
      window.open(url);
    });
};

export default ({ assessment, receiveAssessment, getAssessment }) => {
  let dashboardRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    console.log(dashboardRef.current);
  }

  const {
    id,
    status,
    completion,
    assessors,
    level,
    project_name,
    project_number,
    cage,
    created_at,
    updated_at,
    completed_at,
  } = assessment;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="row" ref={dashboardRef}>
        <div className="col-xs-12 col-sm-4 dash-info-container">
          <div className="dash-info-title">Assessment ID</div>
          <div className="dash-info-content">{id}</div>
        </div>
        <div className="col-xs-12 col-sm-4 dash-info-container">
          <div className="dash-info-title">Status</div>
          <div className="dash-info-content" style={{ display: "flex" }}>
            <span
              style={{
                color: status === "ready" ? "#26d797" : "gray",
                fontSize: 20,
              }}
            >
              •
            </span>
            &nbsp;{status}
          </div>
        </div>
        <div className="col-xs-12 col-sm-4 dash-info-container">
          <div className="dash-info-title">Evidence Completion %</div>
          <div className="dash-info-content" style={{ display: "flex" }}>
            {(completion * 100).toFixed(2)}%
          </div>
        </div>
        <div className="col-xs-12 col-sm-4 dash-info-container">
          <div className="dash-info-title">Target CMMC Maturity Level </div>
          <div className="dash-info-content">
            {can("EVIDENCE:CREATE") ? (
              <FormControl style={{ minWidth: 200 }}>
                <Select
                  defaultValue={level}
                  onChange={(e) => {
                    if (e.target.value !== 3) {
                      http
                        .put("/api/packages", {
                          package: id,
                          level: e.target.value,
                        })
                        .then((res) => {
                          if (res.error) return;
                          setOpen(true);
                          getAssessment();
                        });
                    }
                  }}
                  /* using 'ready' status probably needs to be changed but there's no boolean 'submitted' variable that I could track from what I saw */
                  disabled={completion === 1 && assessment.status === "ready"}
                >
                  <MenuItem value={1}>Level 1</MenuItem>
                  <MenuItem value={2}>Level 2</MenuItem>
                  <MenuItem disabled value={3}>
                    Level 3 - Coming Soon
                  </MenuItem>
                  {/* <MenuItem value={4}>Level 4</MenuItem> */}
                  {/* <MenuItem value={5}>Level 5</MenuItem> */}
                </Select>
              </FormControl>
            ) : (
              level
            )}
          </div>
        </div>
        <div className="col-xs-12 col-sm-4 dash-info-container">
          <div className="dash-info-title">CMMC Model Reference </div>
          <div className="dash-info-content">{"Coming Soon"}</div>
        </div>
        <div className="col-xs-12 col-sm-4 dash-info-container">
          <div className="dash-info-title">Project Name</div>
          <div className="dash-info-content">{project_name || "N/A"}</div>
        </div>
        <div className="col-xs-12 col-sm-4 dash-info-container">
          <div className="dash-info-title">Project Number</div>
          <div className="dash-info-content">{project_number || "N/A"}</div>
        </div>
        <div className="col-xs-12 col-sm-4 dash-info-container">
          <div className="dash-info-title">Start Date (Onsite)</div>
          <div className="dash-info-content">
            {DateTime.fromISO(created_at).toLocaleString(
              DateTime.DATETIME_FULL
            )}
          </div>
        </div>
        <div className="col-xs-12 col-sm-4 dash-info-container">
          <div className="dash-info-title">Updated Date</div>
          <div className="dash-info-content">
            {DateTime.fromISO(updated_at).toLocaleString(
              DateTime.DATETIME_FULL
            )}
          </div>
        </div>
        <div className="col-xs-12 col-sm-4 dash-info-container">
          <div className="dash-info-title">Completion Date (Onsite)</div>
          <div className="dash-info-content">
            {completed_at
              ? DateTime.fromISO(completed_at).toLocaleString(
                  DateTime.DATETIME_FULL
                )
              : "N/A"}
          </div>
        </div>
        <div className="col-xs-12 col-sm-4 dash-info-container">
          <div className="dash-info-title">C3PAO</div>
          <div className="dash-info-content">
            {assessors && assessors[0].approved ? (
              <OrgAvatar org={assessors[0].organizations[0]} hideName />
            ) : (
              "No C3PAO approval"
            )}
          </div>
        </div>
        <div className="col-xs-12 col-sm-4 dash-info-container">
          <div className="dash-info-title">CMMC-AB Certified Assessor </div>
          <div className="dash-info-content">
            {assessors
              ? assessors
                  .filter((a) => a.approved)
                  .map((assessor) => {
                    return <User user={assessor} />;
                  })
              : "No Certified Assessor"}
          </div>
        </div>
        {cage && (
          <div className="col-xs-12 col-sm-6 dash-info-container">
            <div className="dash-info-title">CAGE Code (if applicable) </div>
            <div className="dash-info-content">{cage}</div>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-4 dash-info-container">
          <div className="dash-info-title">Assesment Result</div>
          <div className="dash-info-content">
            {assessment.pass || "Unfinished"}
          </div>
        </div>
        <div className="col-xs-12 col-sm-4 dash-info-container">
          <div className="dash-info-title">Bulk Evidence Upload</div>
          <div className="dash-info-content">
            <Button color="primary" variant="contained" onClick={openModal}>
              Upload Evidence
            </Button>
            <Modal
              parentSelector={() => dashboardRef.current}
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              className="upload-modal"
            >
              <BulkArtifacts
                assessment={assessment}
                uploadModalRef={dashboardRef}
              />
            </Modal>
          </div>
        </div>
        {assessment.status === "evaluating" && (
          <div className="col-xs-12 col-sm-4 dash-info-container">
            <div className="dash-info-title">Compiled POAM Report</div>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                requestPoamReport(assessment.id);
              }}
            >
              Download POAM report
            </Button>
          </div>
        )}
      </div>
      {can("EVIDENCE:CREATE", assessment.roles) &&
        assessment.status === "in progress" && (
          <div style={{ textAlign: "right" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                submitPackage(id).then((res) => {
                  receiveAssessment({ update: { status: res.status } });
                });
              }}
              disabled={completion !== 1}
            >
              Submit Package
            </Button>
          </div>
        )}
      {can("EVALUATIONS:CREATE", assessment.roles) &&
        assessment.status === "evaluating" && (
          <div style={{ textAlign: "right" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                submitAssessment(id).then((res) => {
                  receiveAssessment({ update: { status: res.status } });
                });
              }}
            >
              Submit Assessment
            </Button>
          </div>
        )}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Package saved"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
};
