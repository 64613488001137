import React from "react";
import { Link } from "react-router-dom";
import { getColor } from "./GapTable";

export default ({ assessment, practices }) => {
  let capabilitiesMap = {};
  practices.forEach((practice) => {
    // if (!capabilitiesMap[practice.capability])
    //   capabilitiesMap[practice.capability] = {
    //     pass: 0,
    //     fail: 0,
    //     unev: 0,
    //   }
    // if (practice.evaluation.pass === true) {
    //   capabilitiesMap[practice.capability].pass++
    // } else if (practice.evaluation.pass === false) {
    //   capabilitiesMap[practice.capability].fail++
    // } else {
    //   capabilitiesMap[practice.capability].unev++
    // }

    if (!capabilitiesMap[practice.capability])
      capabilitiesMap[practice.capability] = [];

    capabilitiesMap[practice.capability].push(practice);
  });

  return (
    <div>
      <h3>Capabilities</h3>
      <div className="row">
        {Object.keys(capabilitiesMap)
          .sort()
          .map((capability) => {
            return (
              <div
                className="col-xs-12"
                style={{ marginBottom: 25 }}
                key={capability}
              >
                <div style={{ marginBottom: 15 }}>
                  <b>{capability}</b>
                </div>
                <div style={{ padding: "0 15px" }}>
                  <div className="row">
                    {capabilitiesMap[capability].map((practice) => {
                      let { id, level, domain } = practice;
                      return (
                        <Link
                          key={id}
                          className="col-xs-2 col-lg-1"
                          style={{
                            padding: 7,
                            background: getColor(practice),
                            textAlign: "center",
                            textDecoration: "none",
                            color: "black",
                          }}
                          to={`/assessment/${assessment.id}/${level}/${domain}/${id}`}
                        >
                          {id}
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
