import React from "react";
// import { Link } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from "formik";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
// import Switch from '@material-ui/core/Switch'

import http, { setJWT } from "../../components/http";

import { validatePassword } from "../../components/Login/SignUp";

import cyberLogo from "../../components/Login/cyber_logo.png";

class SignUp extends React.Component {
  render = () => {
    const { match } = this.props;
    return (
      <div className="login">
        <div className="login-logo">
          <div>
            <img
              src={cyberLogo}
              alt="logo"
              style={{ width: 200, marginRight: 50 }}
            />
          </div>
          <div className="login-container">
            <h1>Accept Invite</h1>
            <Formik
              initialValues={{ first_name: "", last_name: "", password: "" }}
              validate={(values) => {
                const errors = {};
                Object.keys(values).forEach((key) => {
                  if (key !== "assessor" && !values[key])
                    errors[key] = "Required";
                });
                if (!validatePassword(values.password))
                  errors.password =
                    "Password must be at least 6 characters and contain at least 1 lowercase, uppercase, number and special character.";
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                http
                  .post(`/api/invite`, {
                    ...values,
                    activation_hash: match.params.activation_hash,
                  })
                  .then((res) => {
                    setSubmitting(false);
                    if (res.error) return alert(res.error);
                    setJWT(res.jwt);
                    window.location = "/organizations";
                  });
              }}
            >
              {({ isSubmitting, values, setFieldValue, errors }) => (
                <Form>
                  <div className="input-row">
                    <Field
                      name="first_name"
                      label="First Name"
                      as={TextField}
                      className="login-input"
                    />
                    <ErrorMessage
                      name="first_name"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="input-row">
                    <Field
                      name="last_name"
                      label="Last Name"
                      as={TextField}
                      className="login-input"
                    />
                    <ErrorMessage
                      name="last_name"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="input-row">
                    <Field
                      type="password"
                      name="password"
                      label="Password"
                      as={TextField}
                      className="login-input"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error"
                    />
                  </div>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Log in
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  };
}

export default SignUp;
