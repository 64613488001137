import React, { useState, useEffect } from "react";
import { Switch, Route, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

import http from "../../components/http";
import Assessments from "../../components/Assessments/index";
import StartPackage from "../../components/Packages/StartPackage";

const Packages = ({ history, match }) => {
  const [loading, setLoading] = useState(true);
  const [packages, setPackages] = useState([]);

  const getPackages = () => {
    http.get("/api/assessments", { role: "assessee" }).then((res) => {
      setLoading(false);
      if (res.error) return alert(res.error);
      setPackages(res.assessments);
    });
  };

  useEffect(() => {
    getPackages();
  }, []);

  if (loading) return null;

  return (
    <div className="container">
      <Switch>
        <Route
          path={`${match.path}/start`}
          render={() => {
            return (
              <StartPackage
                onStart={(assessmentId) => {
                  history.push(`/assessment/${assessmentId}/1`);
                }}
              />
            );
          }}
        />
        <Route
          exact
          path={`${match.path}`}
          render={() => {
            return (
              <div>
                <div style={{ marginBottom: 20 }}>
                  <Link to={`${match.url}/start`}>
                    <Button variant="contained" color="primary">
                      Start new package
                    </Button>
                  </Link>
                </div>
                <Assessments
                  assessments={packages}
                  history={history}
                  onUpdate={getPackages}
                  emptyState={{
                    title: "Getting Started",
                    body: "Now that your organization has been created, you can start a new package by clicking the button above.",
                  }}
                  isPackages
                  page="packages"
                />
              </div>
            );
          }}
        />
      </Switch>
    </div>
  );
};

export default Packages;
