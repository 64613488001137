import React from "react";
import { Switch, Route, NavLink, Redirect } from "react-router-dom";

import OpenPackages from "./OpenPackages";
import Assessors from "./Assessors";

const Marketplace = ({ match }) => {
  return (
    <div className="container">
      <div style={{ marginBottom: 10 }}>
        <NavLink
          to={`${match.url}/packages`}
          className="nav-link"
          activeClassName="nav-link-active"
        >
          Packages
        </NavLink>
        <NavLink
          to={`${match.url}/assessors`}
          className="nav-link"
          activeClassName="nav-link-active"
        >
          Assessors
        </NavLink>
      </div>
      <Switch>
        <Route
          exact
          path={`${match.path}`}
          render={() => <Redirect to={`${match.path}/packages`} />}
        />
        <Route path={`${match.path}/assessors`} component={Assessors} />
        <Route path={`${match.path}/packages`} component={OpenPackages} />
      </Switch>
    </div>
  );
};

export default Marketplace;
