import React from "react";
import { PieChart, Pie, Label } from "recharts";

import can from "../../rbac";

import { practicePass } from "../Pass";

export default ({ practices, assessment }) => {
  let domainMap = {};
  practices.forEach((practice) => {
    if (!domainMap[practice.domain])
      domainMap[practice.domain] = {
        pass: 0,
        fail: 0,
        unev: 0,
      };
    if (can("EVIDENCE:CREATE", assessment.roles)) {
      const pass = practicePass(practice, assessment.roles);
      if (pass === true) {
        domainMap[practice.domain].pass++;
      } else {
        domainMap[practice.domain].unev++;
      }
    }

    if (can("EVALUATIONS:CREATE", assessment.roles)) {
      const pass = practicePass(practice, assessment.roles);
      if (pass === true) {
        domainMap[practice.domain].pass++;
      } else if (pass === false) {
        domainMap[practice.domain].fail++;
      } else {
        domainMap[practice.domain].unev++;
      }
    }
  });

  return (
    <div>
      <h3>Domain Completion</h3>
      <div className="row">
        {Object.keys(domainMap).map((domain) => {
          let { pass, fail, unev } = domainMap[domain];

          return (
            <div
              className="col-xs-6 col-sm-4 col-md-3"
              style={{ marginBottom: 25 }}
              key={domain}
            >
              <div style={{ textAlign: "center", marginBottom: 15 }}>
                <b>{domain}</b>
              </div>
              <div>
                <PieChart width={100} height={100} style={{ margin: "auto" }}>
                  <Pie
                    data={[
                      {
                        name: "Pass",
                        value: pass,
                        fill: "#26d797",
                      },
                      {
                        name: "Fail",
                        value: fail,
                        fill: "#fb224d",
                      },
                      {
                        name: "Unevaluated",
                        value: unev,
                        fill: "#efefef",
                      },
                    ]}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={50}
                    innerRadius={30}
                  >
                    <Label
                      value={
                        (Math.floor((pass * 100) / (fail + pass + unev)) || 0) +
                        "%"
                      }
                      offset={0}
                      position="center"
                    />
                    {/* <Label
                value={'Total ' + (pass + fail + unev)}
                offset={0}
                position='bottom'
              /> */}
                  </Pie>
                </PieChart>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
