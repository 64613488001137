import React from "react";
import qs from "qs";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Button from "@material-ui/core/Button";
import Alert from "@mui/material/Alert";
import TextField from "@material-ui/core/TextField";

import http, { setJWT } from "../http";
import cyberLogo from "./cyber_logo.png";
import "./style.css";

class LoginForm extends React.Component {
  state = { current_email: "", validation_error: null };

  resendValidation = () => {
    let { current_email } = this.state;
    http.post(`/api/email/resend`, { email: current_email }).then((res) => {
      if (res.error) return alert(res.error);
      setJWT(res.jwt);
    });
  };

  render = () => {
    let { current_email, validation_error } = this.state;
    const { location } = this.props;
    const { email } = qs.parse(location.search.substring(1));
    return (
      <div className="login">
        <div className="login-logo">
          <div>
            <img
              src={cyberLogo}
              alt="logo"
              style={{ width: 200, marginRight: 50 }}
            />
          </div>
          <div className="login-container">
            {validation_error && (
              <div className="error-box">
                <Alert severity="error">
                  <p>
                    Your email has not been validated. Please validate your
                    email to login.
                  </p>
                  <div>
                    <Button onClick={this.resendValidation}>
                      {" "}
                      Resend validation email{" "}
                    </Button>
                  </div>
                </Alert>
              </div>
            )}
            <br />
            <Formik
              initialValues={{ email: email || "", password: "" }}
              validate={(values) => {
                const errors = {};
                Object.keys(values).forEach((key) => {
                  if (!values[key]) errors[key] = "Required";
                });
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                http.post(`/api/login`, values).then((res) => {
                  setSubmitting(false);
                  if (res.error && res.errorType === "email_validation") {
                    this.setState({
                      current_email: values.email,
                      validation_error: true,
                    });
                  } else {
                    setJWT(res.jwt);
                    window.location = "/organizations";
                  }
                });
              }}
            >
              {({ isSubmitting, values, errors }) => (
                <Form>
                  <div className="input-row">
                    <Field
                      type="email"
                      name="email"
                      label="Email"
                      as={TextField}
                      className="login-input"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="input-row">
                    <Field
                      type="password"
                      name="password"
                      label="Password"
                      as={TextField}
                      className="login-input"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error"
                    />
                  </div>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Log In
                  </Button>

                  <div style={{ marginTop: 15 }}>
                    <Link to="/signup" style={{ color: "white" }}>
                      Don't have an account? Sign Up
                    </Link>
                    <div
                      style={{
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        if (!values.email)
                          return alert("Please enter an email");
                        http
                          .post(`/api/reset`, { email: values.email })
                          .then((res) => {
                            if (res.error) return alert(res.error);
                            alert("An reset email password has been sent");
                          });
                      }}
                    >
                      Forgot Password
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  };
}

export default LoginForm;
