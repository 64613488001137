import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import { FaTrash } from "react-icons/fa";

import http from "../../http";

export default ({ artifact, updateArtifact, complete }) => {
  const [editing, setEditing] = useState(false);
  const [notes, setNotes] = useState("");

  useEffect(() => {
    setNotes(artifact.notes);
  }, [artifact.notes]);

  return (
    <div className="assessee-artifact" key={artifact.url}>
      <div
        className="artifact-link"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 5,
        }}
      >
        <div>
          <div
            onClick={async () => {
              const res = await http.get("/api/evidence/artifact/url", {
                id: artifact.id,
              });
              window.open(res.url);
            }}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            {artifact.file_name}
          </div>
        </div>
        {!complete && (
          <div className="artifact-remove">
            <IconButton
              style={{ color: "white", fontSize: 20 }}
              onClick={async () => {
                if (
                  window.confirm(
                    "Are you sure you want to remove this artifact?"
                  )
                )
                  await updateArtifact({ remove: artifact.id });
              }}
            >
              <FaTrash />
            </IconButton>
          </div>
        )}
      </div>
      {editing && !complete ? (
        <div className="artifact-notes">
          <div style={{ marginBottom: 10 }}>
            <TextField
              fullWidth
              variant="outlined"
              multiline
              value={notes}
              placeholder="Add artifact notes"
              onChange={(e) => {
                setNotes(e.target.value);
              }}
            />
          </div>
          <div style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              component="label"
              color="secondary"
              style={{ marginRight: 10 }}
              onClick={() => {
                setEditing(false);
              }}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              component="label"
              color="primary"
              onClick={async () => {
                await updateArtifact({ id: artifact.id, notes });
                setEditing(false);
              }}
            >
              SAVE
            </Button>
          </div>
        </div>
      ) : (
        ((complete && artifact.notes) || !complete) && (
          <div
            className="artifact-edit"
            onClick={() => {
              if (!complete) setEditing(true);
            }}
          >
            {artifact.notes ? artifact.notes : "Edit artifact notes"}
          </div>
        )
      )}
    </div>
  );
};
