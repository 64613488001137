import React from "react";
import { Switch, Route, NavLink } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Domains from "./Domains";
import Practice from "./Practice";
import { FaChevronLeft } from "react-icons/fa";

import http from "../http";
import "./style.css";
import Dashboard from "./Dashboard";
import DashInfo from "./Dashboard/DashInfo";

class Assessment extends React.Component {
  state = {
    assessment: {},
  };

  componentDidMount = () => {
    this.getAssessment();
  };

  getAssessment = () => {
    const { history } = this.props;
    const { assessment } = this.props.match.params;
    if (assessment) {
      http
        .get("/api/assessments", { id: assessment, completion: true })
        .then((res) => {
          if (res.error) {
            alert(res.error);
            return history.push("/marketplace");
          }
          if (res.assessments.length > 0) {
            const newAssessment = res.assessments[0];
            if (newAssessment.status === "pending payment") {
              history.push(`/checkout/${newAssessment.id}`);
            }
            this.setState({
              assessment: {
                ...newAssessment,
                roles: [{ title: newAssessment.assessor.role }],
              },
            });
          } else {
            alert("There was an error finding your assessment");
            return history.push("/marketplace");
          }
        });
    }
  };

  renderLevels = () => {
    const { assessment } = this.state;
    const { match, history } = this.props;

    let levelComps = [];
    for (let i = 1; i <= assessment.level; i++) {
      levelComps.push(
        <ListItem
          key={i}
          button
          onClick={() => {
            history.push(`${match.url}/${i}`);
          }}
        >
          <ListItemText primary={"Level " + i} />
        </ListItem>
      );
    }
    return levelComps;
  };

  receiveAssessment = ({ update }) => {
    let { assessment } = this.state;
    if (update) {
      assessment = { ...assessment, ...update };
    }

    this.setState({ assessment });
  };

  render = () => {
    const { assessment } = this.state;
    const { match, history } = this.props;

    if (!assessment.id) return null;

    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          overflow: "auto",
        }}
      >
        <div style={{ width: 300, overflow: "auto" }}>
          <div style={{ fontWeight: "bold", fontSize: 20 }}>
            {assessment.logo ? (
              <img src={assessment.logo} alt={assessment.name} />
            ) : (
              assessment.name
            )}
          </div>
          <Switch>
            <Route exact path={`${match.path}`}>
              <List component="nav">
                <ListItem
                  button
                  onClick={() => {
                    history.push(`${match.url}/dashboard/domains`);
                  }}
                >
                  <ListItemText
                    primary={
                      <NavLink
                        to={`${match.url}/dashboard`}
                        className="dashboard-nav"
                        activeClassName="dashboard-active"
                      >
                        Dashboard
                      </NavLink>
                    }
                  />
                </ListItem>
                {this.renderLevels()}
              </List>
            </Route>
            <Route path={`${match.path}/dashboard`}>
              <List component="nav">
                <ListItem
                  button
                  onClick={() => {
                    history.push(`${match.url}`);
                  }}
                >
                  <div className="list-text menu-header">
                    <FaChevronLeft />
                    &nbsp;&nbsp;
                    <span>Levels</span>
                  </div>
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    history.push(`${match.url}/dashboard/domains`);
                  }}
                >
                  <ListItemText
                    primary={
                      <NavLink
                        to={`${match.url}/dashboard/domains`}
                        className="dashboard-nav"
                        activeClassName="dashboard-active"
                      >
                        Domain Completion
                      </NavLink>
                    }
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    history.push(`${match.url}/dashboard/practices`);
                  }}
                >
                  <ListItemText
                    primary={
                      <NavLink
                        to={`${match.url}/dashboard/practices`}
                        className="dashboard-nav"
                        activeClassName="dashboard-active"
                      >
                        Practice Completion
                      </NavLink>
                    }
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    history.push(`${match.url}/dashboard/capabilities`);
                  }}
                >
                  <ListItemText
                    primary={
                      <NavLink
                        to={`${match.url}/dashboard/capabilities`}
                        className="dashboard-nav"
                        activeClassName="dashboard-active"
                      >
                        Capabilities
                      </NavLink>
                    }
                  />
                </ListItem>
              </List>
            </Route>
            <Route
              path={`${match.path}/:level`}
              render={(routeProps) => (
                <Domains {...routeProps} assessment={assessment} />
              )}
            />
          </Switch>
        </div>
        <Switch>
          <Route
            path={`${match.path}/:level/:domain/:practice`}
            render={(routeProps) => (
              <Practice
                {...routeProps}
                url={match.url}
                assessment={assessment}
              />
            )}
          />
          {assessment.id && (
            <Route
              path={`${match.path}/dashboard`}
              render={(routeProps) => (
                <Dashboard
                  {...routeProps}
                  url={match.url}
                  assessment={assessment}
                />
              )}
            />
          )}
          {assessment.id && (
            <Route
              path={`${match.path}/:level?/:domain?`}
              render={(routeProps) => (
                <div style={{ flex: 1, overflow: "auto" }}>
                  <div
                    style={{
                      borderBottom: "1px solid lightgray",
                      marginBottom: 15,
                    }}
                  >
                    <h2>Package Information</h2>
                  </div>
                  <Card>
                    <CardContent>
                      <DashInfo
                        {...routeProps}
                        assessment={assessment}
                        receiveAssessment={this.receiveAssessment}
                        getAssessment={this.getAssessment}
                      />
                    </CardContent>
                  </Card>
                </div>
              )}
            />
          )}
        </Switch>
      </div>
    );
  };
}

export default Assessment;
