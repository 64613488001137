import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";

import http from "../http";
import can from "../rbac";

import AssessmentRequests from "./AssessmentRequests";

const claimAssessment = (assessmentId) => {
  return http.post("/api/assessments/request", { assessment: assessmentId });
};

export default ({
  assessments,
  open,
  history,
  onUpdate,
  emptyState,
  isPackages,
  page,
}) => {
  if (!assessments) return null;
  const [hasOrg, setHasOrg] = useState(false);

  const canContinue = (assessment) => {
    const { assessor } = assessment;

    if (!assessor) return false;
    return (
      (can("EVIDENCE:CREATE", [{ title: assessor.role }]) ||
        can("EVALUATIONS:CREATE", [{ title: assessor.role }])) &&
      assessor.approved
    );
  };

  useEffect(() => {
    http.get("/api/organization/user", {}).then((res) => {
      if (res.error) return alert(res.error);
      if (res.user.length > 0) {
        setHasOrg(true);
      }
    });
  }, []);

  return (
    <div>
      {assessments.length === 0 && emptyState && (
        <div>
          <Card>
            <CardContent>
              <div style={{ paddingBottom: 20 }}>
                <h1>{emptyState.title}</h1>
                <div>{emptyState.body}</div>
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      {assessments.map((assessment) => {
        const { id, organization, level, status } = assessment;
        const { name, logo } = organization;

        return (
          <Card key={id} style={{ marginBottom: 10 }}>
            <CardContent>
              <div>
                <div style={{ fontSize: 14 }}>#{id}</div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    {logo && (
                      <div style={{ marginRight: 30 }}>
                        <img src={logo} alt={name} style={{ maxWidth: 200 }} />
                      </div>
                    )}
                    <div>
                      <h3 style={{ marginTop: 5 }}>
                        <b>{name}</b>
                      </h3>
                      <div style={{ marginBottom: 10 }}>Level {level}</div>
                      <div>{status || "In Progress"}</div>
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {!open &&
                      (canContinue(assessment) ? (
                        <Link to={`/assessment/${id}`}>
                          <Button variant="contained" color="primary">
                            {status === "ready" ? "View" : "Continue"}{" "}
                            {page === "packages" ? "Package" : "Assessment"}
                          </Button>
                        </Link>
                      ) : (
                        <div>Request pending</div>
                      ))}
                    {open && (
                      <Link to={`/proposal/${id}`}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={!hasOrg}
                        >
                          Submit a proposal
                        </Button>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              {isPackages && (
                <AssessmentRequests
                  assessment={assessment}
                  onUpdate={onUpdate}
                />
              )}
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
};
