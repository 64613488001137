import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";

import http from "../http";
import User from "../User";

const AssessmentRequests = ({ assessment }) => {
  const [loading, setLoading] = useState(true);
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    http
      .get("/api/assessments/requests", { assessment: assessment.id })
      .then((res) => {
        setLoading(false);
        if (res.error) return alert(res.error);
        setRequests(res.requests);
      });
  }, [assessment.id]);

  const updateReq = (assessorId, approve) => {
    http
      .post(`/api/assessments/${approve ? "approve" : "deny"}`, {
        assessor: assessorId,
      })
      .then((res) => {
        if (res.error) return alert(res.error);
        setRequests([...requests.filter((req) => req.id !== assessorId)]);
      });
  };

  if (loading) return null;
  if (requests.length === 0) return null;
  return (
    <div>
      <hr />
      <div style={{ marginBottom: 10, fontWeight: "bold" }}>Proposals</div>
      <div>
        {requests.map((assessor) => {
          const { id, organizations, agreed_rate, proposal } = assessor;
          if (!organizations) return null;
          return (
            <div>
              <div
                key={id}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 10,
                }}
              >
                <div>
                  <User user={assessor} organization={organizations[0]} />
                </div>
                <div style={{ textAlign: "right" }}>
                  <div style={{ marginBottom: 15 }}>
                    <div style={{ fontSize: 25 }}>
                      ${agreed_rate.toLocaleString()}
                    </div>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginRight: 10 }}
                      onClick={() => {
                        updateReq(id, true);
                      }}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        updateReq(id, false);
                      }}
                    >
                      Deny
                    </Button>
                  </div>
                </div>
              </div>
              <div>
                <div style={{ marginTop: 10, marginBottom: 5 }}>
                  <b>Description</b>
                </div>
                <div
                  style={{
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {proposal}
                </div>
              </div>
              <hr />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AssessmentRequests;
