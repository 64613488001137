import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";

import http from "../../components/http";
import User from "../../components/User";

const Checkout = ({ match, history }) => {
  const { assessmentId } = match.params;

  const [loading, setLoading] = useState(true);
  const [assessment, setAssessment] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    http.get("/api/assessments", { id: assessmentId }).then((res) => {
      if (res.error) {
        alert(res.error);
        return history.push("/marketplace");
      }
      if (res.assessments.length > 0) {
        http
          .get("/api/assessments/requests", {
            assessment: assessmentId,
            approved: true,
            checkout: true,
          })
          .then((res2) => {
            setLoading(false);
            if (res2.error) return alert(res2.error);
            setAssessment({
              ...res.assessments[0],
              assessors: res2.requests[0],
            });
          });
      } else {
        alert("There was an error finding your assessment");
        return history.push("/marketplace");
      }
    });

    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      http.put("/api/assessments/finalize", { assessment: assessmentId });
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  const { assessors } = assessment;

  if (loading || !assessors) return null;

  return (
    <div>
      {message ? (
        <div>
          <p>{message}</p>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(`/assessment/${assessmentId}`);
              }}
            >
              Return to Assessment
            </Button>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="product">
            <div className="description">
              <h3>
                In order to view your assessment results you will have to first
                pay
              </h3>
              <User user={assessors} />
              <div className="row" style={{ marginBottom: 10 }}>
                <div className="col-xs-6">Assessor Fee</div>
                <div className="col-xs-6" style={{ textAlign: "right" }}>
                  ${assessors.agreed_rate.toLocaleString()}
                </div>
              </div>
              <div className="row" style={{ marginBottom: 10 }}>
                <div className="col-xs-6">Service Fee</div>
                <div className="col-xs-6" style={{ textAlign: "right" }}>
                  ${(assessors.agreed_rate * 0.1).toLocaleString()}
                </div>
              </div>
            </div>
          </div>
          <div style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                http.post("/create-checkout-session", {
                  assessment: assessmentId,
                });
              }}
            >
              Checkout
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Checkout;
