import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import http, { getUser } from "./http";

const RoleSelector = ({ value, onChange, setAdding, type, user, field }) => {
  const [roles, setRoles] = useState([]);
  const currUser = getUser();

  useEffect(() => {
    const query = {};
    let url = "/api/roles";
    if (type) query.type = type;
    if (user) {
      query.user = currUser.id;
      url = "/api/user/roles";
    }

    http.get(url, query).then((res) => {
      if (res.error) return alert(res.error);
      setRoles(res.roles);
    });
  }, [type]);

  return (
    <div className="input-row">
      <FormControl style={{ minWidth: 200 }}>
        <InputLabel>Select Role</InputLabel>
        <Select
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        >
          {roles.map((cRole) => {
            return (
              <MenuItem value={cRole[field || "id"]}>{cRole.title}</MenuItem>
            );
          })}
        </Select>
      </FormControl>

      {setAdding && (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setAdding(false);
          }}
        >
          Cancel
        </Button>
      )}
    </div>
  );
};

export default RoleSelector;
