import React from "react";
import { Link } from "react-router-dom";

import can from "../../rbac";

import { practicePass } from "../Pass";

export const getColor = (practice) => {
  const { evaluation } = practice;
  if (can("EVIDENCE:CREATE")) {
    if (practicePass(practice)) return "#26d797";
  }

  if (can("EVALUATIONS:CREATE")) {
    if (evaluation.pass === true) return "#26d797";
    if (evaluation.pass === false) return "#fb224d";
  }

  return "#efefef";
};

export default ({ assessment, practices }) => {
  return (
    <div>
      <h3>Practice Completion</h3>
      <div style={{ padding: "0 15px" }}>
        <div className="row">
          {practices.map((practice) => {
            const { id, level, domain } = practice;
            return (
              <Link
                key={id}
                className="col-xs-2 col-lg-1"
                style={{
                  padding: 7,
                  background: getColor(practice),
                  textAlign: "center",
                  textDecoration: "none",
                  color: "black",
                }}
                to={`/assessment/${assessment.id}/${level}/${domain}/${id}`}
              >
                {practice.id}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};
