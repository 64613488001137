import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import can from "../rbac";
import http from "../http";
import FileUpload from "../FileUpload";

class CreateOrg extends React.Component {
  state = {
    slide: 0,
    logo: "",
  };

  render = () => {
    const { slide, logo } = this.state;

    return (
      <Card style={{ minHeight: 400, width: 600 }}>
        <CardContent style={{ height: "100%" }}>
          {slide === 0 && (
            <div className="create-org-slide">
              <div>
                <h1>Welcome to A3</h1>
                <div>
                  To get started{" "}
                  {can("EVALUATIONS:CREATE")
                    ? "assessing organizations"
                    : "with your assessment"}{" "}
                  , please add details about your organization
                </div>
              </div>
              <div className="btn-bar" style={{ marginTop: 20 }}>
                <Button
                  onClick={() => {
                    this.setState({ slide: 1 });
                  }}
                  variant="contained"
                  color="primary"
                >
                  Next
                </Button>
              </div>
            </div>
          )}
          {slide === 1 && (
            <div
              style={{
                height: "100%",
                flexDirection: "column",
                display: "flex",
              }}
            >
              <h1>Organization Details</h1>
              <Formik
                initialValues={{ name: "", description: "" }}
                validate={(values) => {
                  const errors = {};
                  Object.keys(values).forEach((key) => {
                    if (!values[key]) errors[key] = "Required";
                  });
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  const fd = new FormData();
                  if (logo) fd.append("files", logo, logo.name);
                  fd.append("body", JSON.stringify(values));
                  http.blob(`/api/organizations`, "POST", fd).then((res) => {
                    setSubmitting(false);
                    if (res.error) return alert(res.error);
                    this.props.onCreate();
                  });
                }}
              >
                {({ isSubmitting, errors }) => (
                  <Form style={{ height: "100%" }}>
                    <div className="create-org-slide">
                      <div className="input-row">
                        <Field
                          name="name"
                          label="Name*"
                          as={TextField}
                          className="login-input"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="input-row">
                        <FileUpload
                          onChange={(file) => {
                            this.setState({ logo: file });
                          }}
                        />
                      </div>
                      <div className="input-row">
                        <Field
                          name="description"
                          label="Description*"
                          as={TextField}
                          className="login-input"
                        />
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="btn-bar">
                        <Button
                          type="submit"
                          disabled={isSubmitting}
                          variant="contained"
                          color="primary"
                        >
                          Create Organization
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </CardContent>
      </Card>
    );
  };
}

export default CreateOrg;
