import React from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { withStyles } from "@material-ui/core/styles";

const PassButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "#26d797",
    "&:hover": {
      backgroundColor: "#2fe8a5",
    },
  },
}))(Button);

const FailButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "#fb224d",
    "&:hover": {
      backgroundColor: "#f94f71",
    },
  },
}))(Button);

const InheritButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "#26d75c",
    "&:hover": {
      backgroundColor: "#2ee867",
    },
  },
}))(Button);

export default ({ pass, onSubmit }) => {
  return (
    <ButtonGroup>
      <PassButton
        variant="contained"
        style={{
          background: pass !== "pass" && pass !== null ? "#9c9c9c" : "",
        }}
        onClick={() => onSubmit("pass")}
      >
        PASS
      </PassButton>
      <FailButton
        variant="contained"
        style={{
          background: pass !== "fail" && pass !== null ? "#9c9c9c" : "",
        }}
        onClick={() => onSubmit("fail")}
      >
        FAIL
      </FailButton>
      <InheritButton
        variant="contained"
        style={{
          background: pass !== "inherited" && pass !== null ? "#9c9c9c" : "",
        }}
        onClick={() => onSubmit("inherited")}
      >
        INHERITED
      </InheritButton>
    </ButtonGroup>
  );
};
