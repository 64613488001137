import React, { useState, useRef } from "react";
import { useDeepCompareEffect } from "react-use";
import http from "../../http";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "react-bootstrap/Alert";
/*import Button from "react-bootstrap/Button";*/

import "./style.css";

export default ({ artifact, assessment }) => {
  let notesModalRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState("");
  const [saved, setSaved] = useState(false);

  useDeepCompareEffect(() => {
    setLoading(true);
    setNotes("");
    setNotes(artifact.notes ? artifact.notes : "");
  }, [artifact, assessment]);

  const submitNotes = ({ id, notes }) => {
    const res = http.put(`/api/evidence/artifacts`, { id, notes });
    if (res.error) return alert(res.error);
    setSaved(true);
  };

  return (
    <div
      className="notes-content"
      ref={notesModalRef}
      style={{ width: "45vw" }}
    >
      <div className="container-fluid max-viewport">
        <div className="row">
          <div className="col-xs-12">
            <div className="notes-header">
              <h5>File Name: {artifact.file_name}</h5>
              <h6 className="sub-title">
                Enter notes below for your artifact.
              </h6>
            </div>
          </div>
        </div>
        <div className="row center-md">
          <div className="practice-content">
            <TextField
              fullWidth
              variant="filled"
              multiline
              value={notes}
              onChange={(e) => {
                setNotes(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="row end-md">
          {saved && (
            <div className="col-xs-2 reduced-height">
              <Alert variant="success" dismissible>
                <p className="saved-message">Notes saved.</p>
              </Alert>
            </div>
          )}
          <div className="col-xs-2">
            <Button
              onClick={() => {
                submitNotes({ id: artifact.id, notes });
              }}
              variant="contained"
              color="primary"
              disabled={notes === artifact.notes}
            >
              SAVE
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
