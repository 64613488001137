import React from "react";

export default ({ org, hideName }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {org.logo && (
        <img
          src={org.logo}
          alt={org.name}
          style={{ width: 120, marginRight: 15 }}
        />
      )}
      {!hideName && org.name}
    </div>
  );
};
