import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { FaChevronLeft } from "react-icons/fa";

import http from "../http";
import can from "../rbac";

import Practices from "./Practices";
import Pass from "./Pass";

export default ({ match, history, assessment: qAssessment }) => {
  const [practices, setPractices] = useState([]);
  const { assessment, level, practice } = match.params;

  const getPractices = () => {
    http
      .get("/api/practices", {
        assessment,
        level,
        info: true,
        evidence: can("EVIDENCE:CREATE", qAssessment.roles),
        evaluations: can("EVALUATIONS:CREATE", qAssessment.roles),
      })
      .then((res) => {
        if (res.error) return alert(res.error);
        setPractices(res.practices);
      });
  };

  useEffect(() => {
    http
      .get("/api/practices", {
        assessment,
        level,
        info: true,
        evidence: can("EVIDENCE:CREATE", qAssessment.roles),
        evaluations: can("EVALUATIONS:CREATE", qAssessment.roles),
      })
      .then((res) => {
        if (res.error) return alert(res.error);
        setPractices(res.practices);
      });
  }, [assessment, level, practice]);

  const domains = {};
  practices.forEach((practice) => {
    if (!domains[practice.domain]) domains[practice.domain] = [];
    domains[practice.domain].push(practice);
  });

  return (
    <Switch>
      <Route
        path={`${match.path}/:domain`}
        render={(routeProps) => {
          return (
            <Practices
              {...routeProps}
              practices={domains[routeProps.match.params.domain] || []}
              getPractices={getPractices}
              assessment={qAssessment}
            />
          );
        }}
      />
      <Route exact path={`${match.path}`}>
        <div>
          <List
            component="nav"
            aria-label="main mailbox folders"
            style={{
              overflow: "scroll",
              height: "100%",
            }}
          >
            <ListItem
              button
              onClick={() => {
                history.push(`/assessment/${assessment}`);
              }}
            >
              <div className="list-text menu-header">
                <FaChevronLeft />
                &nbsp;&nbsp;
                <span>Level {level}</span>
              </div>
            </ListItem>
            {Object.keys(domains)
              .sort()
              .map((domain) => {
                return (
                  <ListItem
                    key={domain}
                    button
                    onClick={() => {
                      history.push(`${match.url}/${domain}`);
                    }}
                  >
                    <ListItemIcon>
                      <Pass
                        domain={domains[domain]}
                        roles={qAssessment.roles}
                      />
                    </ListItemIcon>
                    <ListItemText primary={domain} />
                  </ListItem>
                );
              })}
          </List>
        </div>
      </Route>
    </Switch>
  );
};
