import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import NotesIcon from "@material-ui/icons/Notes";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssessmentIcon from "@material-ui/icons/Assessment";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import PageviewIcon from "@material-ui/icons/Pageview";

import http from "../http";
import can from "../rbac";

import Affirmation from "./Affirmation";
import Artifacts from "./Artifacts";
import maturities from "./Maturity";
import Observation from "./Observation";
import PassInput from "./PassInput";
import Vendors from "./Vendors";

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export default ({ match, history, practice, assessment: qAssessment }) => {
  const [loading, setLoading] = useState(false);
  const [objective, setObjective] = useState({});
  const [tab, setTab] = useState(0);
  const [notes, setNotes] = useState("");
  const [poam, setPoam] = useState("");
  const { assessment } = match.params;

  useEffect(() => {
    setTab(0);
    setNotes("");
    setPoam("");
    setLoading(true);
    http
      .get("/api/objectives", {
        id: match.params.objective,
        assessment,
        info: true,
      })
      .then((res) => {
        setLoading(false);
        if (res.error) return alert(res.error);
        setObjective(res.objectives[0]);
        setNotes(
          (can("EVALUATIONS:CREATE", qAssessment.roles)
            ? res.objectives[0].evaluation.notes
            : res.objectives[0].evidence.notes) || ""
        );
        console.log(res.objectives);
        setPoam(
          (can("EVALUATIONS:CREATE", qAssessment.roles)
            ? res.objectives[0].evaluations.poam
            : res.objectives[0].evidence.poam) || ""
        );
      });
  }, [match.params.objective, assessment]);

  if (loading) return null;

  const { description } = objective;
  const { id, evaluation, evidence } = objective;
  const { docs, where, who, test_on, next } = practice;

  if (!id) return null;

  const evaluate = (params) => {
    const fields = ["pass", "maturity"];

    let payload = { ...evaluation };
    fields.forEach((field) => {
      if (params[field] !== undefined && params[field] !== evaluation[field])
        payload[field] = params[field];
    });
    if (notes !== evaluation.notes) payload.notes = notes;

    const postUpdate = (res) => {
      if (res.error) return alert(res.error);
      if (res.evaluation_id) evaluation.id = res.evaluation_id;
      setObjective({
        ...objective,
        evaluation: { ...evaluation, ...payload },
      });
    };

    if (evaluation.id) {
      if (Object.keys(payload).length > 0) {
        http
          .put("/api/evaluations", {
            ...payload,
            id: evaluation.id,
            assessment,
          })
          .then(postUpdate);
      }
    } else {
      http
        .post("/api/evaluations", {
          ...payload,
          assessment,
          objective: objective.id,
        })
        .then(postUpdate);
    }
  };

  const submitEvidence = ({
    artifact,
    affirmation,
    affirmation_notes,
    notes,
    poam,
    observation,
    tabStay,
    complete,
  }) => {
    const fd = new FormData();

    let payload = {};
    if (artifact !== undefined) {
      fd.append("files", artifact, artifact.name);
      payload.artifact = true;
    }
    if (affirmation !== undefined && affirmation !== evidence.affirmation)
      payload.affirmation = affirmation;
    if (
      affirmation_notes !== undefined &&
      affirmation_notes !== evidence.affirmation_notes
    )
      payload.affirmation_notes = affirmation_notes;

    if (notes !== undefined && notes !== evidence.notes) payload.notes = notes;

    if (poam !== undefined && poam !== evidence.poam) payload.poam = poam;

    if (observation !== undefined && observation !== evidence.observation)
      payload.observation = observation;

    if (complete !== undefined) payload.complete = complete;

    if (assessment !== undefined) payload.assessment = assessment;

    const postUpdate = (res) => {
      if (res.error) return alert(res.error);
      if (res.evidence_id) evidence.id = res.evidence_id;

      setObjective({
        ...objective,
        evidence: {
          ...evidence,
          ...payload,
          artifacts: res.artifact
            ? [...(evidence.artifacts || []), res.artifact]
            : evidence.artifacts,
        },
      });
      setTab(tab < 2 && !tabStay ? tab + 1 : tab);
    };

    if (evidence.id) {
      if (Object.keys(payload).length > 0) {
        fd.append(
          "body",
          JSON.stringify({
            ...payload,
            assessment,
            id: evidence.id,
          })
        );
        http.blob("/api/evidence", "PUT", fd).then(postUpdate);
      }
    } else {
      fd.append(
        "body",
        JSON.stringify({
          ...payload,
          assessment,
          objective: objective.id,
        })
      );
      http.blob("/api/evidence", "POST", fd).then(postUpdate);
    }
  };

  const { maturity } = evaluation || {};

  const { complete } = evidence || {};

  /*const maturityLabel = maturities.findIndex((m) => m.label === maturity);*/

  const canEdit = !complete && qAssessment.status === "in progress";

  const goNext = () => {
    if (next) {
      const { assessment, level, domain } = match.params;
      if (objective.next) {
        history.push(
          `/assessment/${assessment}/${level}/${domain}/${practice.id}/${objective.next}`
        );
      } else {
        history.push(
          `/assessment/${assessment}/${level}/${domain}/${practice.next}`
        );
      }
    }
  };

  return (
    <div>
      <Card style={{ marginBottom: 15 }}>
        <CardContent>
          <div>
            <div style={{ fontSize: 14, color: "lightgray", marginBottom: 7 }}>
              OBJECTIVE{" "}
              {objective.id
                .substring(objective.id.length - 1, objective.length)
                .toUpperCase()}
            </div>
            <div style={{ fontSize: 20, fontWeight: "bold", marginBottom: 15 }}>
              {description}
            </div>
          </div>
          <Tabs
            value={tab}
            onChange={(e, newValue) => {
              setTab(newValue);
            }}
            aria-label="simple tabs example"
          >
            <Tab
              label={
                <span className="tab-label">
                  <AssignmentIcon />
                  &nbsp;ARTIFACTS
                </span>
              }
            />
            <Tab
              label={
                <span className="tab-label">
                  <AssessmentIcon />
                  &nbsp;AFFIRMATION
                </span>
              }
            />
            <Tab
              label={
                <span className="tab-label">
                  <PageviewIcon />
                  &nbsp;OBSERVATION
                </span>
              }
            />
            <Tab
              label={
                <span className="tab-label">
                  <NotesIcon />
                  &nbsp;NOTES
                </span>
              }
            />
            {complete && (
              <Tab
                label={
                  <span className="POAM">
                    <QuestionAnswerIcon />
                    &nbsp; POAM
                  </span>
                }
              />
            )}
            {/*{can("EVALUATIONS:CREATE", qAssessment.roles) && (
              <Tab
                label={
                  <span className="tab-label">
                    <b style={{ fontSize: 20 }}>
                      {maturityLabel >= 0 ? maturityLabel : 0}
                    </b>
                    &nbsp;&nbsp;MATURITY
                  </span>
                }
              />*/}
            )}
          </Tabs>
          <TabPanel value={tab} index={0}>
            <div className="practice-content">
              <Artifacts
                assessment={qAssessment}
                objective={objective}
                evaluate={evaluate}
                submit={submitEvidence}
                setObjective={setObjective}
                canEdit={canEdit}
              />
              <div className="practice-info-section">
                <div className="practice-info-header">Where to Look</div>
                <div>{where}</div>
              </div>
              <div className="practice-info-section">
                <div className="practice-info-header">Reference Documents</div>
                <div>
                  {docs
                    .sort((a, b) => {
                      if (a.title < b.title) return -1;
                      if (a.title > b.title) return 1;
                      return 0;
                    })
                    .map((doc) => {
                      return (
                        <div key={doc.title} style={{ marginBottom: 5 }}>
                          <a href={doc.link} target="blank">
                            {doc.title} {doc.section}
                          </a>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <div className="practice-content">
              <Affirmation
                assessment={assessment}
                objective={objective}
                evaluate={evaluate}
                submit={submitEvidence}
                canEdit={canEdit}
              />
              <div className="practice-info-section">
                <div className="practice-info-header">Who to Ask</div>
                <div>{who}</div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <div className="practice-content">
              <Observation
                assessment={assessment}
                objective={objective}
                evaluate={evaluate}
                submit={submitEvidence}
                canEdit={canEdit}
              />
              <div className="practice-info-section">
                <div className="practice-info-header">Perform Test On</div>
                <div>{test_on}</div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tab} index={3}>
            <div className="practice-content">
              <TextField
                fullWidth
                variant="outlined"
                multiline
                value={notes}
                disabled={!canEdit && can("EVIDENCE:CREATE", qAssessment.roles)}
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
              />
              <div style={{ marginTop: 10, textAlign: "right" }}>
                <Button
                  onClick={() => {
                    if (can("EVALUATIONS:CREATE", qAssessment.roles)) {
                      evaluate({ notes });
                    }
                    if (can("EVIDENCE:CREATE", qAssessment.roles)) {
                      submitEvidence({ notes });
                    }
                  }}
                  variant="contained"
                  color="primary"
                  disabled={notes === practice.notes}
                >
                  SAVE
                </Button>
              </div>
            </div>
          </TabPanel>
          {complete && can("EVIDENCE:CREATE", qAssessment.roles) && (
            <TabPanel value={tab} index={4}>
              <div className="practice-content">
                <TextField
                  fullWidth
                  variant="outlined"
                  multiline
                  value={poam}
                  disabled={!can("EVIDENCE:CREATE", qAssessment.roles)}
                  onChange={(e) => {
                    setPoam(e.target.value);
                  }}
                />
                <div style={{ marginTop: 10, textAlign: "right" }}>
                  <Button
                    onClick={() => {
                      if (can("EVIDENCE:CREATE", qAssessment.roles)) {
                        submitEvidence({ poam });
                      }
                    }}
                    variant="contained"
                    color="primary"
                    disabled={poam === practice.poam}
                  >
                    SAVE
                  </Button>
                </div>
              </div>
            </TabPanel>
          )}
          {/*{can("EVALUATIONS:CREATE", qAssessment.roles) && (
            <TabPanel value={tab} index={4}>
              <div className="practice-content">
                <Maturity
                  assessment={assessment}
                  evaluate={evaluate}
                  objective={objective}
                  practice={practice}
                />
              </div>
            </TabPanel>
          )}*/}
          <div style={{ width: "100%", textAlign: "right", marginTop: 15 }}>
            {complete && qAssessment.status === "in progress" && (
              <Button
                onClick={() => {
                  submitEvidence({ complete: null, tabStay: true });
                  setObjective({ ...objective, complete: false });
                }}
                variant="contained"
                color="primary"
                style={{ marginRight: 10 }}
              >
                EDIT
              </Button>
            )}
            {canEdit && can("EVIDENCE:CREATE", qAssessment.roles) && (
              <Button
                onClick={() => {
                  submitEvidence({ complete: true });
                  goNext();
                }}
                variant="contained"
                color="primary"
                disabled={complete}
                style={{ marginRight: 10 }}
              >
                COMPLETE
              </Button>
            )}
            {can("EVALUATIONS:CREATE", qAssessment.roles) && (
              <div className="assessor-section">
                <PassInput
                  pass={evaluation.pass}
                  onSubmit={(pass) => {
                    evaluate({ pass });
                    goNext();
                  }}
                />
              </div>
            )}
            {/* {next && (
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                const { assessment, level, domain } = match.params
                if (objective.next) {
                  history.push(
                    `/assessment/${assessment}/${level}/${domain}/${practice.id}/${objective.next}`
                  )
                } else {
                  history.push(
                    `/assessment/${assessment}/${level}/${domain}/${practice.next}`
                  )
                }
              }}
            >
              Next Objective
            </Button>
          )} */}
          </div>
        </CardContent>
      </Card>
      <Vendors vendors={objective.vendors} />
    </div>
  );
};
