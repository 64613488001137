import React, { useState, useEffect, useLayoutEffect, forwardRef } from "react";
import { useDeepCompareEffect } from "react-use";
import http from "../../http";
import BTable from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import ProgressBar from "react-bootstrap/ProgressBar";
import Button from "react-bootstrap/Button";
import { useTable, usePagination, useRowSelect } from "react-table";
import Modal from "react-modal";
import { FileUploader } from "react-drag-drop-files";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import ArtifactNotes from "./ArtifactNotes";

import "./style.css";

let currentCell = null;

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return (
          <>
            <input type="checkbox" ref={resolvedRef} {...rest} />
          </>
      )
    }
)

function FileTable({ columns, data, objective }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      objective
    },
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
              </div>
          ),
          Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
          ),
        },
        ...columns,
      ])
    }
  );
/*
  const { pageIndex, pageSize } = state;*/

  function addBulkArtifactsToObjective() {
    let mappedFlatRows = selectedFlatRows.map(row => row.original);
    mappedFlatRows.forEach((artifact) => {
      http.put("/api/evidence/associate-evidence", {
            id: artifact.id,
            objective: objective.evidence.id,
          })
          .then((res) => {
            if (res.error) return alert(res.error);

          });
    })
  }

  const getCellVal = (cell) => {
    currentCell = cell.row.values;
  };

  let pageArray = [];
  let active = pageIndex;

  function paginationNums() {
    for (let i = 0; i < pageOptions.length; i++) {
      pageArray.push(
        <Pagination.Item
          key={i}
          active={i === active}
          onClick={() => {
            gotoPage(i);
          }}
        >
          {i + 1}
        </Pagination.Item>
      );
    }
  }
  return (
    <div>
      <dlv className="row">
        <div className="col-sm-12">
          {paginationNums()}
          <div className="existing-files-table">
            <BTable hover size="sm" {...getTableProps()}>
              <thead className="light-background-header">
                <tr {...headerGroups[1].getHeaderGroupProps()}>
                  {headerGroups[1].headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <th
                            onClick={() => getCellVal(cell)}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </BTable>
          </div>
        </div>
      </dlv>
      <div className="row g-0">
        <div className="col-auto pagination-align-left">
          <Pagination size="sm">{pageArray}</Pagination>
        </div>
        <div className="col-auto">
          <div>
            <span className="pagination-pad-left">
              {" "}
              Go to page:{" "}
              <input
                className="pagination-shrink"
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
              />
            </span>{" "}
            <select
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[1, 5, 10, 25, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button variant="info"
                    onClick={(e) => {
                      addBulkArtifactsToObjective(selectedFlatRows);
                      e.preventDefault();
                    }}
            >
              Add selected to current objective
            </Button>
          </div>

        </div>
      </div>
    </div>
  );
}

export default ({ assessment, objective, uploadModalRef }) => {
  console.log(uploadModalRef.current);
  /*let uploadModalRef = useRef(null);*/

  const [loading, setLoading] = useState(false);
  const [existingData, setExistingData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [uploadedFileProgress, setuploadedFileProgress] = useState([]);
  const [forcePageSize, setForcePageSize] = useState(5);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [artifact, setArtifact] = useState({});

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  /*  const observer = useRef((new ResizeObserver(entries => {

    const { width, height } = entries[0].contentRect;
    console.log(height);
    console.log(window.innerHeight);
    if (window.innerHeight < height) {
      setForcePageSize(Math.floor(forcePageSize / 2));
    }
    console.log(forcePageSize);
  })))*/

  const handleChange = (fileList) => {
    const fd = new FormData();
    fd.append(
      "body",
      JSON.stringify({
        assessment,
      })
    );

    if (fileList.length > 1) {
      for (let i = 0; i < fileList.length; i++) {
        fd.append("files", fileList[i], fileList[i].name);
        http
          .postProgress(`/api/evidence/`, fd, (event) => {
            setuploadedFileProgress({
              name: fileList[i].name,
              progress: Math.round((100 * event.loaded) / event.total),
            });
          })
          .then(() => {
            getCurrentUploads().then(() => {
              setNewData(Array.from(fileList));
            });
          });
      }
    }
    fd.append("files", fileList[0], fileList[0].name);
    http
      .postProgress(`/api/evidence/`, fd, (event) => {
        setuploadedFileProgress({
          name: fileList[0].name,
          progress: Math.round((100 * event.loaded) / event.total),
        });
      })
      .then(() => {
        getCurrentUploads().then(() => {
          setNewData(Array.from(fileList));
        });
      });
  };

  const getCurrentUploads = () => {
    http
      .get("/api/evidence/artifacts", {
        assessment,
      })
      .then((res) => {
        setLoading(false);
        if (res.error) return alert(res.error);

        let filterArray = res.artifacts.filter((existing_artifact) =>
          newData.every(
            (new_artifact) => existing_artifact.file_name !== new_artifact.name
          )
        );

        setExistingData(filterArray);
      });
  };

  useDeepCompareEffect(() => {
    setLoading(true);
    getCurrentUploads();
    console.log(uploadedFileProgress);

    /*if(ref.current){
      observer.current.observe(ref.current);
    }
    observer.current.unobserve(ref.current)*/
  }, [
    newData,
    existingData,
    assessment,
    uploadedFileProgress,
    modalIsOpen,
    objective,
    uploadModalRef,
  ]);

  const existingColumns = React.useMemo(
    () => [
      {
        Header: "Uploaded Artifact List",
        columns: [
          {
            Header: "Artifact Name",
            accessor: "file_name",
          },
          {
            Header: "Artifact Notes",
            accessor: "",
            Cell: ({ row }) => {
              return (
                <div>
                  <Button
                    variant="info"
                    onClick={(e) => {
                      openModal();
                      setArtifact(row.original);
                      e.preventDefault();
                    }}
                  >
                    Edit Notes
                  </Button>
                </div>
              );
            },
          },
          {
            Header: "Artifact Added",
            accessor: "created_at",
          },
          {
            Header: "Associated Objectives",
            accessor: "objective_id",
          },
          /*{
            Header: "Select to add to current objective",
            accessor: "",
            Cell: ({ row }) => {
              return (

              );
            }
          }*/
        ],
      },
    ],
    []
  );

  const newColumns = React.useMemo(
    () => [
      {
        Header: "New Artifact Uploads",
        columns: [
          {
            Header: "Artifact Name",
            accessor: "name",
          },
          {
            Header: "File Type",
            accessor: "type",
          },
          {
            Header: "File Size",
            accessor: "size",
          },
        ],
      },
    ],
    []
  );

  return (
    <div className="modal-content" ref={uploadModalRef}>
      <div className="container-fluid max-viewport">
        {existingData.length > 0 && (
          <div className="row border-box">
            <div className="border border-dark rounded">
              <div className="table-header">
                <h5 className="table-header-title">
                  Assessment Attached Artifacts
                </h5>
                <h6 className="table-header-subtitle">
                  Files and assets that have been attached to your assessment
                </h6>
              </div>
              <FileTable columns={existingColumns} data={existingData} objective={objective} />
            </div>
          </div>
        )}
        {existingData.length < 0 && <div></div>}
        <Modal
          /*parentSelector={() =>
                uploadModalRef.current
            }*/
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="notes-modal"
        >
          <ArtifactNotes artifact={artifact} assessment={assessment} objective={objective} />
        </Modal>
        <div className="row center-md">
          <div className="col-md-12">
            <div>
              <h1 className="">Upload your artifacts</h1>
              <h2 className="sub-title">Files up to 100MB are allowed</h2>
            </div>
          </div>
        </div>
        <div className="row center-md upload-box">
          <div className="col-md-12">
            <FileUploader
              handleChange={handleChange}
              name="file"
              multiple={true}
              children=<div>
                <div className="row center-md">
                  <div className="col-md-6">
                    <FileUploadIcon sx={{ fontSize: 120 }} />
                  </div>
                </div>
                <div className="row center-md">
                  <div className="col-md-6o">
                    <div>
                      <p>
                        Drag and drop or click here to browse files to upload
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            />
          </div>
        </div>
        {newData.length > 0 && (
          <div className="row border-box">
            <div className="border border-dark rounded">
              <div className="table-header">
                <h5 className="table-header-title">New Attached Artifacts</h5>
                <h6 className="table-header-subtitle">
                  Files and assets added recently (cleared on exit)
                </h6>
              </div>
              <FileTable columns={newColumns} data={newData} objective={objective} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
