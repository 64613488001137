import React, {useRef, useState} from "react";
import Button from "@material-ui/core/Button";

import http from "../../http";
import can from "../../rbac";
import Artifact from "./Artifact";

import "./style.css";
import { AssessmentOutlined } from "@material-ui/icons";
import BulkArtifacts from "../Dashboard/BulkArtifacts";
import Modal from "react-modal";

export default ({
  assessment,
  canEdit,
  evaluate,
  objective,
  setObjective,
  submit,
}) => {
  const { evidence } = objective;
  let dashboardRef = useRef(null);

  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    window.location.reload();
  }

  const updateArtifact = async ({ assessment, id, notes, remove }) => {
    if (remove) {
      const res = await http.del("/api/evidence/artifacts", { id: remove });
      if (res.error) return alert(res.error);
      setObjective({
        ...objective,
        evidence: {
          ...evidence,
          artifacts: evidence.artifacts.filter((a) => a.id !== remove),
        },
      });
    } else {
      if (!notes) return;
      console.log(assessment);
      const res = await http.put("/api/evidence/artifacts", {
        id,
        notes,
        assessment,
      });
      if (res.error) return alert(res.error);

      evidence.artifacts.forEach((artifact) => {
        if (artifact.id === id) artifact.notes = notes;
      });

      setObjective({ ...objective, evidence: { ...evidence } });
    }
  };

  return (
    <div className="practice-controls" ref={dashboardRef}>
      {can("EVALUATIONS:CREATE", assessment.roles) && (
        <div className="assessor-section">
          <div style={{ marginBottom: 10 }}></div>
          {evidence.artifacts && (
            <div>
              {evidence.artifacts.map((artifact) => {
                return (
                  <div key={artifact.link}>
                    <a href={artifact.link} target="blank">
                      {artifact.link}
                    </a>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
      {can("EVIDENCE:CREATE") && (
        <div className="assessee-section">
          {canEdit && (
            <div style={{ marginBottom: 10 }}>
              <Button variant="contained" component="label">
                Add Artifact
                <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    submit({
                      artifact: e.target.files[0],
                      tabStay: true,
                    });
                  }}
                />
              </Button>
              &nbsp;
              &nbsp;
              <Button variant="contained" component="label" onClick={openModal}>
                Bulk Evidence Upload
              </Button>
              <Modal
                  /*parentSelector={() => dashboardRef.current}*/
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                  className="upload-modal"
              >
                <BulkArtifacts
                    assessment={assessment}
                    objective={objective}
                    uploadModalRef={dashboardRef}
                />
              </Modal>
            </div>
          )}
          {evidence.artifacts && (
            <div style={{ marginBottom: 10 }}>
              {evidence.artifacts.map((artifact) => {
                return (
                  <Artifact
                    artifact={artifact}
                    complete={evidence.complete}
                    updateArtifact={updateArtifact}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
